@import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=block');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Two+Tone|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

:root {
  --color-assets-primary: #4c7ff0;
  --color-assets-primary-tint: #7a89fd;
  --color-assets-primary-shade: #4e61ef;
  --color-assets-secondary: #2b2d42;
  --color-assets-secondary-tint: #404255;
  --color-assets-secondary-shade: #26283a;
  --color-assets-light: #f6fafd;
  --navigation-text: #515996;
  --navigation-text-hover: #515996;
  --navigation-icon: #4b91bf;
  --navigation-bg: #fff;
  --navigation-border: #d7e0e7;
  --navigation-submenu-text: #000000;
  --navigation-switch-color: #ffffff;
  --navigation-sidebar-shadow: #0000000a;
  --navigation-submenu-hover: #f8f9fa;
  --navigation-program-panel-bg: #5e71fe;
  --navigation-program-panel-shadow: #222f96;
  --navigation-program-panel-arrow: #222f96;
  --navigation-settings-user-name: #222f96;
  --navigation-border-color: #e9ecef;
  --color-body-txt-default: #2b2d42;
  --color-body-txt-secondary: #5f6d89;
  --color-border-primary: #e9ecef;
  --color-border-secondary: #ebeef3;
  --header-bg-color: #4c7ff0;
  --header-icon-color: #ffffff;
  --header-icon-hover-color: #3f72e3;
  --responsive-detail-header: #4c7ff0;
  --responsive-detail-header-color: #ffffff;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1>* {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4>* {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-sm-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-md-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-lg-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-xl-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.ripple {
  position: absolute;
  background: #f5deb3;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}

.rippleButton {
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ripple.show {
  -webkit-animation: ripple .75s ease-out;
  animation: ripple .75s ease-out;
}

@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  to {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  background: #ffffff;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  color: var(--color-body-txt-default);
  font-size: 14px;
  font-weight: 400;
}

body.filter-overflow {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: var(--color-assets-secondary);
  cursor: pointer;
}

icon {
  color: inherit;
}

i {
  color: inherit;
}

[hidden] {
  display: none !important;
  color: inherit;
}

.content-area {
  margin: 10px 0 0 20px;
}

.ui-content-wrapper {
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.sidebar-lg {
  max-width: 500px;
}

.sidebar-md {
  max-width: 650px;
}

.sidebar-sm {
  max-width: 750px;
}

h1 {
  font-weight: 400;
}

h2,
h3,
h4,
.page-title {
  font-weight: 400;
  text-transform: capitalize;
}

h1 {
  font-size: 32px;
  line-height: 48px;
}

h2 {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;
}

h3 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
}

h4 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

p {
  font-size: 14px;
  line-height: 24px;
}

p.lead {
  font-size: 16px;
}

p.small {
  font-size: 12px;
  line-height: 20px;
}

p.x-small {
  font-size: 11px;
  line-height: 24px;
}

.page-title {
  font-size: 18px;
  line-height: 24px;
}

.subtitle-1 {
  font-size: 16px;
  line-height: 24px;
}

.subtitle-2 {
  font-size: 14px;
  line-height: 24px;
}

th {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.p-0 {
  padding: 0px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-12 {
  padding: 12px;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-64 {
  padding: 64px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.px-64 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-64 {
  margin: 64px !important;
}

.my-auto {
  margin: auto 0 !important;
}

.mx-auto {
  margin: 0 auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mx-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mx-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mx-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mx-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.mx-64 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-inherit {
  overflow: inherit !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-justify-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.img-fluid {
  max-width: 100% !important;
  height: auto !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.border-0 {
  border: none !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.rounded-sm {
  border-radius: 4px !important;
}

.border {
  border: 1px solid var(--color-border-primary) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--color-border-primary) !important;
}

.border-top {
  border-top: 1px solid var(--color-border-primary) !important;
}

.border-right {
  border-right: 1px solid var(--color-border-primary) !important;
}

.border-left {
  border-left: 1px solid var(--color-border-primary) !important;
}

.text-primary {
  color: var(--color-assets-primary) !important;
}

.bg-light {
  background-color: #ffffff !important;
}

.bg-light-gray {
  background-color: #FAFBFC !important;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.text-underline {
  text-decoration: underline !important;
}

.no-radius {
  border-radius: 0px !important;
}

.no-border {
  border: 0px !important;
}

.no-border-top {
  border-top: 0px !important;
}

.no-border-left {
  border-left: 0px !important;
}

.no-border-right {
  border-right: 0px !important;
}

.no-border-bottom {
  border-bottom: 0px !important;
}

.text-dark {
  color: #222428;
}

.text-success {
  color: #2dd36f !important;
}

.text-red {
  color: #ce0b25 !important;
}

.text-grey {
  color: #d7e0e7;
}

.text-gray-50 {
  color: #adb5bd;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rounded-pill {
  border-radius: 17px !important;
}

.text-break {
  word-break: break-all !important;
}

.word-break {
  word-break: break-word;
}

.icon-primary-bg {
  font-size: 18px;
  background: #DFEBFE;
  padding: 4px 6px;
  border-radius: 6px;
  color: #1D6FF3;
}

.icon-success-bg {
  font-size: 18px;
  background: #D7FFC5;
  padding: 4px 6px;
  border-radius: 6px;
  color: #42C606;
}

.icon-info-bg {
  font-size: 18px;
  background: #CDF1F1;
  padding: 4px 6px;
  border-radius: 6px;
  color: #23A8A8;
}

.icon-qualification-bg {
  font-size: 18px;
  background: #FBE7E4;
  padding: 4px 6px;
  border-radius: 6px;
  color: #EC9284;
}

.icon-pre-candidate-bg {
  font-size: 18px;
  background: #E8E9EC;
  padding: 4px 6px;
  border-radius: 6px;
}

.icon-blue-bg {
  font-size: 18px;
  background: #EAE2FF;
  padding: 0px 6px 4px;
  border-radius: 6px;
  color: #4C7FF0;
}

.icon-blue-bg i {
  vertical-align: middle;
}

.h-100 {
  height: 100%;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-black {
  color: #000000 !important;
}

.text-lightgray {
  color: #5F6D89;
}

.bg-lightblue {
  background-color: #F7FAFC;
}

.white-space-pre {
  white-space: pre;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-nowrap {
  white-space: nowrap;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-24 {
  font-size: 24px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-link {
  color: #5E44C9;
}

.bg-transparent {
  background-color: transparent !important;
}

.w-150 {
  width: 150px !important;
}

.w-90 {
  width: 90px !important;
}

.w-100 {
  width: 100% !important;
}

.w-60 {
  width: 60% !important;
}

.w-85 {
  width: 85% !important;
}

.w-25 {
  width: 25% !important;
}

.height-vh {
  height: 100vh;
}

.text-muted {
  color: #7f92a3;
}

.text-primary {
  color: var(--color-assets-primary);
}

.align-middle {
  vertical-align: middle;
}

.custom-field-info {
  font-size: 16px;
  color: #A3B0C0;
  transition: 0.2s ease-in-out;
}

.custom-field-info:hover {
  color: #4C7FF0;
}

.ng-select.ng-select-opened>.ng-select-container {
  background: #ffffff;
  border-color: #d2e8ff;
}

.ng-select.ng-select-opened>.ng-select-container:hover {
  box-shadow: none;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: 0;
  border-color: transparent transparent transparent;
  border-width: 0;
  width: auto !important;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:after {
  content: "expand_less";
  font-family: Material Icons;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: var(--color-assets-secondary);
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
  border-color: transparent transparent var(--color-border-primary);
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 1px solid transparent;
}

.ng-select.ng-select-opened.ng-select-top>.ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 1px solid transparent;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: var(--color-border-primary);
  box-shadow: none;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #fafbfc;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select .ng-select-container {
  color: var(--color-body-txt-default);
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid var(--color-border-secondary);
  min-height: 40px;
  align-items: center;
}

.ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 transparent;
}

.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 13px;
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
  padding-right: 13px;
  padding-left: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #7f92a3;
  font-size: 13px;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input[disabled] {
  background-color: transparent !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px;
  left: 0;
  padding-left: 13px;
  padding-right: 50px;
}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 13px;
  padding-left: 50px;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  background-color: #e3eef9;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
  padding-left: 7px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 13px;
  margin-bottom: 5px;
  background-color: #ebeef3;
  border-radius: 2px;
  margin-right: 5px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #ffffff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 5px 0px 5px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #d1e8ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid transparent;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #b8dbff;
  border-right: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #b8dbff;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #b8dbff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 11px;
  padding-bottom: 5px;
  padding-left: 3px;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}

.ng-select .ng-clear-wrapper {
  color: #999999;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #D0021B;
}

.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}

[dir="rtl"] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
  padding-top: 5px;
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666666;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent transparent;
  border-style: none;
  border-width: 0px;
  width: auto !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow:after {
  content: "expand_more";
  font-family: Material Icons;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: var(--color-assets-secondary);
}

.ng-select.medium .ng-select-container {
  min-height: 48px;
}

.ng-select.medium .ng-select-container .ng-value-container {
  padding-left: 19px;
}

.ng-select.medium.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 15px;
}

.ng-dropdown-panel {
  background-color: #ffffff;
  border: 1px solid #d2e8ff;
  box-shadow: 0px 10px 14px -10px #0000002b;
  left: 0;
  font-size: 13px;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: transparent;
  margin-top: -1px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: transparent;
  margin-bottom: -1px;
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #ebf5ff;
  font-weight: 500;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 13px;
  white-space: pre-wrap !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: var(--color-body-txt-default);
  background-color: #f9fafd;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 500;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f9fafd;
  color: var(--color-body-txt-default);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #cccccc;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

[dir="rtl"] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

.btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  height: 40px;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.btn.rounded {
  width: 40px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.btn.icon {
  width: 40px;
  padding-left: 0;
  padding-right: 0;
}

.btn-icon-on-left {
  padding-left: 10px;
}

.btn-icon-on-right {
  padding-right: 10px;
}

.btn-primary {
  background-color: var(--color-assets-primary);
  border-color: var(--color-assets-primary);
  color: #ffffff;
}

.btn-primary:hover {
  background-color: var(--color-assets-primary-shade);
  border-color: var(--color-assets-primary-shade);
}

.btn-primary.active,
.btn-primary:active,
.btn-primary.focus,
.btn-primary:focus {
  background-color: var(--color-assets-primary-tint);
  border-color: var(--color-assets-primary-tint);
  outline-width: 0px;
}

.btn-secondary {
  background-color: var(--color-assets-secondary-shade);
  border-color: var(--color-assets-secondary-shade);
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: var(--color-assets-secondary);
  border-color: var(--color-assets-secondary);
}

.btn-secondary.active,
.btn-secondary:active,
.btn-secondary.focus,
.btn-secondary:focus {
  background-color: var(--color-assets-secondary-tint);
  border-color: var(--color-assets-secondary-tint);
  outline-width: 0px;
}

.btn-outine {
  background-color: transparent;
  border-color: var(--color-assets-primary);
  color: var(--color-assets-primary);
}

.btn-outine:hover {
  border-color: var(--color-assets-primary);
  color: var(--color-assets-primary);
}

.btn-outine.active,
.btn-outine:active,
.btn-outine.focus,
.btn-outine:focus {
  border-color: #ced4da;
  color: var(--color-assets-secondary-tint);
  outline-width: 0px;
}

.btn-outline-secondary {
  background-color: transparent;
  border-color: var(--color-assets-secondary-shade);
  color: var(--color-assets-secondary-shade);
}

.btn-outline-secondary:hover {
  background-color: var(--color-assets-secondary);
  border-color: var(--color-assets-secondary);
  color: #ffffff;
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  background-color: var(--color-assets-secondary-tint);
  border-color: var(--color-assets-secondary-tint);
  color: #ffffff;
  outline-width: 0px;
}

.btn-outline-primary {
  background-color: transparent;
  border-color: var(--color-assets-primary);
  color: var(--color-assets-primary);
}

.btn-outline-primary:hover {
  background-color: var(--color-assets-primary);
  border-color: var(--color-assets-primary);
  color: #ffffff;
}

.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  background-color: var(--color-assets-primary-tint);
  border-color: var(--color-assets-primary-tint);
  color: #ffffff;
  outline-width: 0px;
}

.btn-light {
  background-color: #f6fafd;
  border-color: #d7e0e7;
  color: var(--color-assets-secondary);
}

.btn-light:hover {
  background-color: #f0f2f4;
  border-color: #d7e0e7;
}

.btn-light.active,
.btn-light:active,
.btn-light.focus,
.btn-light:focus {
  background-color: #f0f2f4;
  border-color: #d7e0e7;
  outline-width: 0px;
}

.btn-white {
  background-color: #ffffff;
  border-color: var(--color-assets-secondary);
  color: var(--color-assets-secondary);
}

.btn-white:hover {
  background-color: #ffffff;
  border-color: var(--color-assets-secondary);
  color: var(--color-assets-secondary);
}

.btn-white.active,
.btn-white:active,
.btn-white.focus,
.btn-white:focus {
  background-color: #ffffff;
  border-color: var(--color-assets-secondary);
  outline-width: 0px;
}

.btn-success {
  background-color: #26cc39;
  border-color: #26cc39;
  color: #ffffff;
  border-radius: 4px;
}

.btn-success:hover {
  background-color: #2dd36f;
  border-color: #2dd36f;
  color: #ffffff;
}

.btn-success.active,
.btn-success:active,
.btn-success.focus,
.btn-success:focus {
  background-color: #2dd36f;
  border-color: #2dd36f;
  color: #ffffff;
  outline-width: 0px;
}

.btn-danger {
  background-color: #C72F43;
  border-color: #C72F43;
  color: #ffffff;
}

.btn-danger:hover {
  background-color: #C72F43;
  border-color: #C72F43;
  color: #ffffff;
}

.btn-danger.active,
.btn-danger:active,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #C72F43;
  border-color: #C72F43;
  color: #ffffff;
  outline-width: 0px;
}

.btn-outline-danger {
  background-color: transparent;
  border-color: #C72F43;
  color: #C72F43;
}

.btn-outline-danger:hover {
  background-color: #C72F43;
  border-color: #C72F43;
  color: #ffffff;
}

.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  background-color: #C72F43;
  border-color: #C72F43;
  color: #ffffff;
  outline-width: 0px;
}

.btn-withdrawn {
  background-color: #FD5367;
  border-color: #FD5367;
  color: #ffffff;
}

.btn-withdrawn:hover {
  background-color: #FD5367;
  border-color: #FD5367;
  color: #ffffff;
}

.btn-withdrawn.active,
.btn-withdrawn:active,
.btn-withdrawn.focus,
.btn-withdrawn:focus {
  background-color: #FD5367;
  border-color: #FD5367;
  color: #ffffff;
  outline-width: 0px;
}

.btn-orange {
  background-color: #fc7a10;
  border-color: #fc7a10;
  color: #ffffff;
}

.btn-orange:hover {
  background-color: #ffa55c;
  border-color: #FF9842;
  color: #ffffff;
}

.btn-orange.active,
.btn-orange:active,
.btn-orange.focus,
.btn-orange:focus {
  background-color: #FF9842;
  border-color: #FF9842;
  color: #ffffff;
  outline-width: 0px;
}

.btn-gray {
  background-color: #0C43A180;
  border-color: #0C43A180;
  color: #ffffff;
  border-radius: 4px;
}

.btn-gray:hover {
  background-color: #4877957c;
  border-color: #4877957c;
  color: #ffffff;
}

.btn-gray.active,
.btn-gray:active,
.btn-gray.focus,
.btn-gray:focus {
  background-color: #2dd36f;
  border-color: #2dd36f;
  color: #ffffff;
  outline-width: 0px;
}

.btn.small {
  font-size: 12px;
  line-height: 24px;
  height: 32px;
  padding: 4px 12px;
}

.btn.small.icon {
  width: 32px;
  padding-left: 0;
  padding-right: 0;
}

.btn.small.rounded {
  width: 32px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.btn.large {
  height: 48px;
  font-size: 16px;
  padding: 12px 24px;
}

.btn.large.icon {
  width: 48px;
  padding-left: 0;
  padding-right: 0;
}

.btn.large.rounded {
  width: 48px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.btn.xx-small {
  height: 20px;
  font-size: 12px;
}

.btn.xx-small.rounded {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
}

.btn.x-small {
  height: 16px;
}

.btn.x-small.rounded {
  width: 16px;
  height: 16px;
  padding: 0;
  border-radius: 50%;
}

.btn .icon {
  color: inherit;
}

.btn .icon--on-right {
  margin-left: 8px;
  display: flex;
}

.btn .icon--on-left {
  margin-right: 8px;
  display: flex;
}

.btn:disabled {
  opacity: .5;
  cursor: no-drop;
}

.checkbox-wrapper {
  display: flex;
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid var(--color-assets-primary);
}

.checkbox-container .checkmark:after {
  content: "done";
  font-family: 'Material Icons';
  position: absolute;
  display: none;
  font-size: 15px;
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  color: #ffffff;
}

.checkbox-container input:checked~.checkmark {
  background-color: var(--color-assets-primary);
}

.checkbox-container input:checked~.checkmark:after {
  display: block;
}

.theme .checkbox-container {
  padding-left: 0;
}

.theme .checkbox-container .checkmark {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border-color: #d7e0e7;
  overflow: hidden;
}

.theme .checkbox-container .checkmark:after {
  font-size: 11px;
  top: 1px;
  left: 1px;
  width: 5px;
  height: 5px;
  color: #ffffff;
}

.theme .checkbox-container input:checked~.checkmark {
  background-color: #776de0;
}

.theme thead .checkbox-container .checkmark:after {
  top: -5px;
}

.widget-checkbox .checkbox-container {
  padding-left: 0;
}

.widget-checkbox .checkbox-container .checkmark {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  overflow: hidden;
}

.widget-checkbox .checkbox-container .checkmark:after {
  content: "done";
  font-size: 13px;
  display: block;
  top: 0;
  left: 0;
  width: 6px;
  height: 6px;
  color: #ffffff;
}

.widget-checkbox .checkbox-container input~.checkmark {
  background-color: #d1d1d1;
}

.widget-checkbox .checkbox-container input:checked~.checkmark {
  background-color: #5e71fe;
}

.widget-checkbox thead .checkbox-container .checkmark:after {
  top: -5px;
}

.widget-link-checkbox .checkbox-container {
  padding-left: 0;
}

.widget-link-checkbox .checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  background-color: #ffffff;
  border: none;
}

.widget-link-checkbox .checkbox-container .checkmark:after {
  content: "done";
  font-family: 'Material Icons';
  position: absolute;
  display: none;
  font-size: 13px;
  top: 0px;
  left: 0px;
  width: 8px;
  height: 8px;
  color: #ffffff;
}

.widget-link-checkbox .checkbox-container input~.checkmark {
  background-color: #ffffff;
}

.widget-link-checkbox .checkbox-container input:checked~.checkmark {
  background-color: #151515;
}

.widget-link-checkbox .checkbox-container input:checked~.checkmark:after {
  display: block;
}

.radio-wrapper {
  display: flex;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: var(--color-assets-primary);
}

.radio-container .checkmark:after {
  content: "stop_circle";
  font-family: 'Material Icons';
  position: absolute;
  display: none;
  font-size: 16px;
  top: -1px;
  left: 3px;
  width: 8px;
  height: 8px;
  color: #ffffff;
}

.radio-container input:checked~.checkmark:after {
  display: block;
}

.radio-wrapper-circle {
  display: flex;
  align-items: center;
}

.radio-container-circle {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 19px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-container-circle .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  border: 2px solid var(--color-assets-primary);
}

.radio-container-circle .checkmark:after {
  content: "circle";
  font-family: 'Material Icons';
  position: absolute;
  display: none;
  font-size: 13px;
  top: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  color: var(--color-assets-primary);
}

.radio-container-circle input:checked~.checkmark:after {
  display: block;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: "Inter";
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

button,
input {
  overflow: visible;
}

.textarea-without-resize {
  resize: none;
}

.form-group {
  font-size: 14px;
  padding-bottom: 4px;
  margin-bottom: 16px;
}

.form-control {
  border-radius: 4px;
  color: #222428;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  padding-right: 24px;
  padding-bottom: 4px;
  font-weight: 500;
  display: block;
  width: 100%;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid var(--color-border-secondary);
  height: 40px;
  padding: 11px 13px;
  font-size: 14px;
  font-weight: 500;
}

.form-control.medium {
  height: 48px;
  padding: 15px 19px;
  font-size: 15px;
}

.form-control:hover {
  border-color: #c6e0f5;
}

.form-control:focus {
  border-color: #d0eaff;
  outline: 0px solid transparent;
  box-shadow: 0px 2px 6px #0000000D;
}

.form-control.error {
  border: 2px solid #eb445a;
}

textarea.form-control {
  height: auto !important;
}

.form-control[type=textarea] {
  height: auto !important;
}

label {
  font-size: 14px;
  color: #222428;
  margin-bottom: 8px;
  display: block;
}

.validation-msg {
  text-align: left;
  color: #ce0b25;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  margin-top: 4px;
}

.validation-msg icon {
  display: inline-block;
  vertical-align: middle;
  height: 14px;
}

.require {
  font-size: 14px;
  font-weight: 500;
  color: #C20000;
  padding-left: 0;
}

::-webkit-input-placeholder {
  color: #7f92a3;
  font-size: 14px;
  font-weight: 400;
}

:-ms-input-placeholder {
  color: #7f92a3;
  font-size: 14px;
  font-weight: 400;
}

::placeholder {
  color: #7f92a3;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
}

:-ms-input-placeholder {
  color: #7f92a3;
  font-size: 14px;
  font-weight: 400;
}

::-ms-input-placeholder {
  color: #7f92a3;
  font-size: 14px;
  font-weight: 400;
}

.ng-option-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.ng-value {
  font-size: 14px;
  font-weight: 500;
}

.ng-dropdown-panel {
  z-index: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="number"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group {
  width: 100%;
}

.input-group>.form-control {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0px;
}

.input-group>.form-control {
  margin-left: -1px;
}

.input-group>.form-control:focus {
  z-index: 3;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  line-height: 1.5;
  position: absolute;
  top: 16px;
  left: 18px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
  appearance: none;
}

input[disabled],
textarea[disabled] {
  background-color: #f8f9fa !important;
}

[readonly] {
  background-color: #fafbfc;
}

.success-msg {
  text-align: left;
  color: #2dd36f;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  margin-top: 4px;
}

.success-msg icon {
  display: inline-block;
  vertical-align: middle;
  height: 14px;
}

.file-field {
  border-radius: 4px;
  color: #222428;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  padding-right: 24px;
  padding-bottom: 4px;
  font-weight: 500;
  display: block;
  width: 100%;
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid var(--color-border-secondary);
  height: 40px;
  padding: 11px 13px;
  font-size: 14px;
  overflow: hidden;
}

.file-field input {
  width: 150%;
  position: absolute;
  left: -13%;
  top: 9px;
  height: 100%;
  border: 0;
  outline: 0;
}

.file-field span {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  line-height: 40px;
  background-color: #2b2d42;
  color: #ffffff;
  padding: 0 28px;
  border-top-right-radius: 4px;
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
}

.file-field:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 13px;
  background-color: #ffffff;
  z-index: 2;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.file-field:focus {
  border-color: #d0eaff;
  outline: 0px solid transparent;
  box-shadow: 0px 2px 6px #0000000D;
}

.breadcrumb-list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
}

.breadcrumb-listItem a {
  color: var(--color-body-txt-default);
  text-decoration: none;
}

.initials {
  color: #ffffff;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  margin-right: 5px;
  float: left;
  font-size: 10px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials__img {
  overflow: hidden;
}

.initials__purple {
  background-color: #4c7ff0 !important;
}

.initials__pink {
  background-color: #e83e8c !important;
}

.initials__green {
  background-color: #2dd36f !important;
}

.initials__gray {
  background-color: #7f92a3 !important;
}

.initials__lightBlue {
  background-color: #776DE033 !important;
}

.initial-bg {
  background-color: var(--color-body-txt-secondary);
  color: #ffffff;
  padding: 3px 10px;
  border-radius: 12px;
}

.panel {
  border-radius: 4px;
  box-shadow: 0px 3px 16px #0000000B;
  border: 1px solid #ebeef3;
}

.panel-light {
  background-color: #ffffff;
}

.panel-info {
  background-color: #f6fafd;
}

.panel-head {
  padding: 24px;
}

.panel-head h5 {
  color: var(--color-assets-secondary-shade);
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.panel-content {
  padding: 0 24px 24px;
}

.panel-footer {
  padding: 0 24px 24px;
  border-top: 1px solid;
  border-color: var(--color-border-primary);
  background-color: #f6fafd;
}

table {
  border-collapse: collapse;
  font-size: 14px;
}

table {
  width: 100%;
}

table th,
table td {
  padding: 8px 24px;
  vertical-align: middle;
}

table th {
  height: 56px;
}

table td {
  height: 60px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #f0f2f4;
  text-align: left;
}

.table-listing thead {
  background-color: #ffffff;
}

.table-listing thead th {
  vertical-align: middle;
  color: var(--color-body-txt-secondary);
  border-top: 0px;
  border-bottom: 1px solid #f0f2f4;
  text-align: left;
  font-size: 14px;
}

.table-listing thead th span {
  float: left;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: #565f67;
  font-weight: 500 !important;
}

.table-listing thead th span:first-child {
  line-height: 27px;
}

.table-listing thead th .icon {
  cursor: pointer;
  float: left;
}

.table-listing tbody {
  background: #fafbfc;
}

.table-listing tbody>tr:hover {
  background-color: #ffffff;
}

.table-listing tbody>tr:hover .actions {
  display: inline-flex;
}

.table-listing tbody>tr:hover .status {
  display: none;
}

.table-listing tbody>tr:hover .showActive {
  display: flex !important;
}

.table-listing tbody>tr>td {
  padding: 0px;
  border-top-color: transparent;
  border: 0px;
  color: var(--color-body-txt-default);
}

.table-listing tbody>tr>td>table {
  width: 100%;
}

.table-listing tbody>tr>td>table>tr {
  cursor: pointer;
  border-bottom: 1px solid #f5f6f9;
  transition: background-color 0.2s ease-in-out;
}

.table-listing tbody>tr>td>table>tr td {
  font-size: 13px;
  vertical-align: middle;
  color: #2D3748;
  position: relative;
  border-top: 0px;
}

.table-listing tbody>tr>td>table>tr td a {
  font-size: 13px;
  display: block;
  line-height: 23px;
  word-break: break-all;
}

.table-listing tbody>tr>td>table>tr td .on-navigate {
  color: #4e61ef;
}

.table-listing tbody>tr>td>table>tr td .on-navigate:hover {
  text-decoration: underline;
}

.table-listing tbody>tr>td>table>tr td button {
  word-break: normal;
}

.table-listing tbody>tr>td>table>tr.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .5;
}

.table-listing tbody .actions {
  display: none;
  position: absolute;
  top: 13px;
  right: 8px;
}

.table-listing .expanded-row {
  background-color: #ffffff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f2f4;
}

.table-listing .expanded-row td {
  border-top: 1px solid transparent;
}

.table-listing .expanded-row:hover {
  background-color: #ffffff;
}

.table-listing .expanded-row .expanded-col {
  display: flex;
}

.table-listing .ifExpand {
  background-color: #ffffff;
  border-color: #ffffff;
}

.table-listing .ifExpand:hover {
  background-color: #ffffff;
}

.table-listing .ifExpand .actions {
  display: flex !important;
}

.table-listing .ifExpand .status {
  display: none !important;
}

.table-listing .circle {
  color: inherit;
  display: flex;
  margin-right: 4px;
}

.table-listing .status {
  display: flex;
  align-items: center;
}

/*
.theme {
  .actions {
    width: auto;
    left: auto !important;
    right: 25px;

    button {
      white-space: nowrap;

      &.dropdown-trigger {
        background-color: $color-gray-20;
        border-color: $color-gray-20;
      }
    }
  }

  .table-outer {
    border: 1px solid $color-gray-30;

    thead {
      background-color: $color-gray-5;

      th {
        border-bottom: 1px solid $color-gray-30;
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    tbody {
      background-color: $light;

      >tr {
        >td {
          >table {
            >tr {
              border-bottom-color: $color-gray-30;
            }
          }
        }
      }
    }
  }
}
*/
.fullpage-table.table-bg {
  padding: 0px !important;
  background: #fff;
}

.fullpage-table .table-bg-inner {
  box-shadow: none !important;
}

.table-listing-wo-component .table tbody tr td .action-td {
  padding-right: 60px;
}

.table-listing-wo-component .table tbody tr td .action-td .actions {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: 1px solid transparent;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  display: none;
  border-radius: 4px;
}

.table-listing-wo-component .table tbody tr td .action-td .actions icon {
  line-height: 32px;
  text-align: center;
  height: 32px;
  display: block;
}

.table-listing-wo-component .table tbody tr td .action-td .actions icon i {
  line-height: 32px;
  vertical-align: middle;
}

.table-listing-wo-component .table tbody tr td .action-td .actions:hover {
  border-color: var(--color-assets-primary);
}

.table-listing-wo-component .table tbody tr td .action-td .actions:hover ::ng-deep i {
  color: var(--color-assets-primary);
}

.table-listing-wo-component .table tbody tr:hover .action-td .actions {
  display: block !important;
}

.table-listing-wo-component .action-dropdown-box {
  background-color: #ffffff;
  width: 250px;
  border-radius: 4px;
  box-shadow: 0 11px 16px rgba(0, 0, 0, 0.2);
  z-index: 8;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
}

.table-listing-wo-component .action-dropdown-box:before {
  background: #ffffff;
  border: 1px solid #ebeef3;
  width: 10px;
  height: 10px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: hidden;
  position: absolute;
  top: -7px;
  left: 30px;
  z-index: 1;
  transform: rotate(45deg);
}

.table-listing-wo-component .action-dropdown-box:after {
  background: #ffffff;
  width: 13px;
  height: 1px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: hideen;
  position: absolute;
  top: 0px;
  right: 30px;
  z-index: 2;
}

.table-listing-wo-component .action-dropdown-box ul {
  list-style-type: none;
  border: 1px solid #ebeef3;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background: #ffffff;
}

.table-listing-wo-component .action-dropdown-box ul li a {
  line-height: 46px;
  padding-left: 16px;
  padding-right: 16px;
  color: #000000;
  font-weight: 400;
  white-space: nowrap;
  display: block;
}

.table-listing-wo-component .action-dropdown-box ul li a:hover {
  text-decoration: none;
  background: #fafbfc;
  color: var(--color-assets-primary);
}

.table-listing-wo-component .action-dropdown-box ul li:first-child {
  border-top: 0;
}

.table-listing-wo-component .action-dropdown-box.active {
  visibility: visible;
}

.table-listing-wo-component .action-dropdown-box.active::before {
  visibility: visible;
}

.table-listing-wo-component .action-dropdown-box.active::after {
  visibility: visible;
}

.table-listing-wo-component .max-data-visibility {
  max-width: 300px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calculator-popup {
  width: 360px !important;
}

.calculator-popup .calculator-popup-head h5 {
  font-size: 16px;
}

.calculator-popup .calculator-popup-body p {
  font-size: 12px;
  color: #5B6874;
}

.calculator-popup .calculator-popup-body .calculation-content .calculation-name {
  flex: 1;
}

.calculator-popup .calculator-popup-body .calculation-content .calculation-name p {
  text-transform: uppercase;
}

.calculator-popup .calculator-popup-body .calculation-content .calculation-name .slider-wrapper input[type="range"] {
  -webkit-appearance: none;
  width: 200px;
  height: 4px;
  background-color: #DDEAFF;
  background-image: linear-gradient(#4C7FF0, #4C7FF0);
  background-size: 0;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin: 0px;
  cursor: pointer;
}

.calculator-popup .calculator-popup-body .calculation-content .calculation-name .slider-wrapper input[type="range"]::-webkit-slider-thumb,
.calculator-popup .calculator-popup-body .calculation-content .calculation-name .slider-wrapper input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #4C7FF0;
  border: none;
  cursor: pointer;
}

.calculator-popup .calculator-popup-body .calculation-content .calculation-name .slider-wrapper input[type=range]::-webkit-slider-runnable-track,
.calculator-popup .calculator-popup-body .calculation-content .calculation-name .slider-wrapper input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.calculator-popup .calculator-popup-body .calculation-content .calculation-Weighted {
  flex: 0.4;
  flex-direction: column;
}

.calculator-popup .calculator-popup-body .calculation-content .calculation-Weighted .display {
  border: 1px solid #CFD8E1;
  padding: 4px 12px;
  color: #5B6874;
}

.calculator-popup .calculator-popup-footer {
  background-color: #EDF2FE;
}

.linear-progress-bar .linear-rail {
  background-color: #e9ecef;
  min-width: 117px;
  display: block;
  height: 7px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.linear-progress-bar .linear-rail span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #84919F;
}

.linear-progress-bar .linear-rail span.moderate {
  background-color: #D8834B;
}

.linear-progress-bar .linear-rail span.good {
  background-color: #0089FF;
}

.linear-progress-bar .linear-rail span.excellent {
  background-color: #01CA17;
}

.linear-progress-bar .linear-comparision {
  background-color: #D3EDF08E;
  min-width: 200px;
}

.linear-progress-bar .linear-comparision span {
  background-color: #D3EDF08E;
  border-radius: 4px;
}

.linear-progress-bar .linear-comparision span.low {
  background-color: #FF1A23;
}

.linear-progress-bar .linear-comparision span.average {
  background-color: #FFB100;
}

.linear-progress-bar .linear-comparision span.excellent {
  background-color: #26DE4A;
}

.circular-progress-bar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-bar-comparision {
  width: 55px;
  height: 55px;
  transform: rotateY(180deg);
}

.switch-new-ui-modal {
  max-width: 552px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 1000;
  transition: 0.2s ease-in-out;
  opacity: 0;
  max-height: 90%;
  visibility: hidden;
  overflow: hidden;
  /* Handle */
  /* Handle on hover */
}

.switch-new-ui-modal .top-screen {
  background-color: rgba(237, 242, 254, 0.47);
}

.switch-new-ui-modal .top-screen .dummy-placeholder-gray {
  background-color: #E0E9FA;
  border-radius: 4px;
  height: 8px;
  width: 55px;
  margin-bottom: 4px;
}

.switch-new-ui-modal .top-screen .dummy-placeholder-gray.medium {
  height: 12px;
}

.switch-new-ui-modal .top-screen .dummy-placeholder-gray.big {
  height: 13px;
  width: 122px;
}

.switch-new-ui-modal .top-screen .dummy-placeholder-gray.large {
  height: 26px;
  width: 28px;
}

.switch-new-ui-modal .top-screen .dummy-placeholder-blue {
  background-color: #1C60D0;
  border-radius: 4px;
  height: 26px;
  width: 58px;
  margin-bottom: 4px;
}

.switch-new-ui-modal .top-screen .dummy-bordered {
  border: 1px solid #DFE5ED;
  border-radius: 4px;
  height: 21px;
  width: 72px;
}

.switch-new-ui-modal .top-screen table thead {
  border-top: 1px solid #CFD8E1;
  border-bottom: 1px solid #CFD8E1;
}

.switch-new-ui-modal .top-screen table thead tr th {
  padding-left: 12px;
  padding-right: 12px;
}

.switch-new-ui-modal .top-screen table thead tr th:first-child {
  border-right: 1px solid #CFD8E1;
  padding-left: 24px;
}

.switch-new-ui-modal .top-screen table tbody tr td {
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
}

.switch-new-ui-modal .top-screen table tbody tr td:first-child {
  border-right: 1px solid #CFD8E1;
  padding-left: 24px;
}

.switch-new-ui-modal h3 {
  color: #1C60D0;
}

.switch-new-ui-modal p {
  color: #5B6874;
}

.switch-new-ui-modal::-webkit-scrollbar {
  width: 8px;
}

.switch-new-ui-modal::-webkit-scrollbar-track {
  background: transparent;
}

.switch-new-ui-modal::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.switch-new-ui-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.switch-new-ui-modal.active {
  opacity: 1;
  visibility: visible;
  overflow: auto;
  transform: translate(-50%, -50%);
}

.switch-new-ui-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: #1F2031;
  opacity: 0;
  visibility: hidden;
}

.switch-new-ui-overlay.active {
  opacity: .85;
  visibility: visible;
}

.content:not(.config-active) .svms-modal {
  background-color: #ffffff;
  width: 95%;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  z-index: 1001;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

.content:not(.config-active) .svms-modal .svms-modal-header {
  padding: 24px 16px;
}

.content:not(.config-active) .svms-modal .svms-modal-header h4 {
  color: #0F2E4E;
}

.content:not(.config-active) .svms-modal .svms-modal-body {
  padding: 0 16px 24px 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.content:not(.config-active) .svms-modal .svms-modal-body .c-search-panel-wrapper {
  height: 53px;
}

.content:not(.config-active) .svms-modal .svms-modal-body .c-search-panel-wrapper .c-search-panel {
  border-top: 1px solid #EBF0F5;
  width: auto !important;
  top: 0;
  left: -16px !important;
  right: -16px;
}

.content:not(.config-active) .svms-modal .svms-modal-footer {
  border-top: 1px solid #E3E9F0;
  padding: 16px;
}

.content:not(.config-active) .svms-modal .close-modal {
  position: absolute;
  top: 24px;
  right: 16px;
  z-index: 10;
}

.content:not(.config-active) .svms-modal .close-modal i {
  font-size: 18px;
  color: #001A43;
}

.content:not(.config-active) .svms-modal.small {
  max-width: 400px;
}

.content:not(.config-active) .svms-modal.medium {
  max-width: 800px;
}

.content:not(.config-active) .svms-modal.large {
  max-width: 1000px;
}

.content:not(.config-active) .svms-modal.x-large {
  max-width: 1500px;
}

.content:not(.config-active) .svms-modal.active {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
  max-height: 4000px;
}

.content:not(.config-active) .svms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1F2031;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
}

.content:not(.config-active) .svms-modal-overlay.active {
  opacity: .85;
  visibility: visible;
}

.badge {
  display: inline-block;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  font-size: 11px;
  line-height: 22px;
  border-radius: 15px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-primary {
  background-color: var(--color-assets-primary);
  border-color: var(--color-assets-primary);
  color: #ffffff;
}

.badge-primary:hover {
  background-color: var(--color-assets-primary-shade);
  border-color: var(--color-assets-primary-shade);
}

.badge-primary.active {
  background-color: var(--color-assets-primary-tint);
  border-color: var(--color-assets-primary-tint);
  outline-width: 0px;
}

.badge-secondary {
  background-color: var(--color-assets-secondary-shade);
  border-color: var(--color-assets-secondary-shade);
  color: #ffffff;
}

.badge-secondary:hover {
  background-color: var(--color-assets-secondary);
  border-color: var(--color-assets-secondary);
  color: #ffffff;
}

.badge-secondary.active {
  background-color: var(--color-assets-secondary-tint);
  border-color: var(--color-assets-secondary-tint);
  color: #ffffff;
}

.badge-outine-primary {
  background-color: transparent;
  border-color: var(--color-assets-primary);
  color: var(--color-assets-primary);
}

.badge-outine-primary:hover {
  background-color: var(--color-assets-primary-shade);
  border-color: var(--color-assets-primary-shade);
  color: #ffffff;
}

.badge-outine-primary.active {
  background-color: var(--color-assets-primary-tint);
  border-color: var(--color-assets-primary-tint);
  color: #ffffff;
  outline-width: 0px;
}

.badge-outline-secondary {
  background-color: transparent;
  border-color: var(--color-assets-secondary-shade);
  color: var(--color-assets-secondary-shade);
}

.badge-outline-secondary:hover {
  background-color: var(--color-assets-secondary);
  border-color: var(--color-assets-secondary);
  color: #ffffff;
}

.badge-outline-secondary.active {
  background-color: var(--color-assets-secondary-tint);
  border-color: var(--color-assets-secondary-tint);
  color: #ffffff;
}

.badge-light {
  background-color: #f6fafd;
  border-color: #f6fafd;
  color: var(--color-body-txt-secondary);
}

.badge-light:hover {
  background-color: #f6fafd;
  border-color: #f6fafd;
  color: var(--color-body-txt-secondary);
}

.badge-light.active {
  background-color: #f6fafd;
  border-color: #f6fafd;
  color: var(--color-body-txt-secondary);
}

.badge-outline-light {
  background-color: #f8f9fa;
  border-color: var(--color-border-primary);
  color: var(--color-assets-secondary);
}

.badge-outline-light:hover {
  background-color: #f8f9fa;
  border-color: var(--color-border-primary);
  color: var(--color-assets-secondary);
}

.badge-outline-light.active {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: var(--color-assets-secondary);
}

.badge-blue {
  background-color: #776de0;
  border-color: #776de0;
  color: #ffffff;
}

.badge-blue:hover {
  background-color: #776de0;
  border-color: #776de0;
  color: #ffffff;
}

.badge-blue.active {
  background-color: #776de0;
  border-color: #776de0;
  color: #ffffff;
}

.badge-lightblue {
  background-color: #EBEEFF;
  border-color: #EBEEFF;
  color: #4C7FF0;
}

.badge-lightblue:hover {
  background-color: #EBEEFF;
  border-color: #EBEEFF;
  color: #4C7FF0;
}

.badge-lightblue.active {
  background-color: #EBEEFF;
  border-color: #EBEEFF;
  color: #4C7FF0;
}

.badge-green {
  background-color: #C7FAAC;
  border-color: #C7FAAC;
  color: #35AA20;
}

.badge-green:hover {
  background-color: #C7FAAC;
  border-color: #C7FAAC;
  color: #35AA20;
}

.badge-green.active {
  background-color: #C7FAAC;
  border-color: #C7FAAC;
  color: #35AA20;
}

.badge .icon {
  color: inherit;
}

.badge .icon--onRight {
  margin-left: 4px;
  display: flex;
}

.badge .icon--onLeft {
  margin-right: 4px;
  display: flex;
}

.tab-content-wrapper {
  width: 100%;
  display: flex;
  font-weight: 500;
}

.tab-content-wrapper .leftPanel {
  width: 200px;
  background-color: #ffffff;
  border-right: 1px solid var(--color-border-primary);
  position: absolute;
  top: 97px;
  left: 8px;
}

.tab-content-wrapper .rightPanel {
  width: calc(100% - 200px);
  background: #fff;
  margin-left: 24px;
  position: relative;
  left: 178px;
}

.tab-content-wrapper .rightPanel .sidebar-content-scroll {
  max-height: calc(100vh - 218px);
  overflow: auto;
}

.tab-content-wrapper .rightPanel .sidebar-content-scroll::-webkit-scrollbar {
  width: 3px;
}

.tab-content-wrapper .rightPanel .sidebar-content-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #A3B0C0;
  border-radius: 3px;
}

.tab-content-wrapper .rightPanel .sidebar-content-scroll::-webkit-scrollbar-thumb {
  background: #A3B0C0;
  border-radius: 3px;
}

.tab-content-wrapper .rightPanel .sidebar-content-scroll::-webkit-scrollbar-thumb:hover {
  background: #84919F;
}

.vertical-menu {
  text-align: left;
  list-style-type: none;
  padding: 0px;
  height: 100vh;
  height: calc(100vh - 171px);
  overflow: auto;
}

.vertical-menu li {
  color: var(--color-assets-secondary-shade);
  padding: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical-menu li:after {
  display: none;
}

.vertical-menu .active {
  cursor: pointer;
  color: var(--color-assets-primary);
}

/* custom image upload styles*/
.Upload_box_wrapper {
  padding-bottom: 32px;
  display: flex;
  align-items: center;
}

.upload-panel {
  display: flex;
  -ms-flex-align: start;
  flex-direction: column;
  width: 113px;
  font-size: 10px;
  word-break: break-word;
  height: 113px;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;
  padding: 5px;
  margin-right: 16px;
  cursor: pointer;
  border: 4px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0px 3px 12px #0000001F;
  background: #FAFBFC;
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.upload-panel-body {
  flex: 1;
  font-size: 12px;
}

.upload-panel-body h5 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.upload-panel icon {
  color: #BFBCC9;
  align-self: center;
  cursor: pointer;
  margin-bottom: 0px;
}

.upload-panel a {
  color: #776DE0;
  text-decoration: underline;
}

.upload-panel span {
  font-size: 12px;
  line-height: 15px;
  color: #7f92a3;
}

.rightPanel-sidebar {
  width: 90%;
  max-width: 750px;
  position: fixed;
  height: 100vh;
  right: -1000px;
  top: 0px;
  z-index: 100000;
  overflow-x: hidden;
  background-color: #ffffff;
  overflow-y: auto;
}

.rightPanel-sidebar.transition {
  transition: right 0.35s linear;
}

.rightPanel-sidebar.open {
  right: 0px !important;
}

.rightPanel-sidebar-title {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid var(--color-border-primary);
  position: fixed;
  background-color: white;
  width: calc(750px - 130px);
  z-index: 9999;
}

.rightPanel-sidebar-title .close {
  cursor: pointer;
}

.rightPanel-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightPanel-sidebar-content-inner {
  margin-top: 45px;
}

.rightPanel .tab-panel-heading {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 24px;
}

.rightPanel .tab-panel-heading:not(:first-child) {
  margin-top: 32px;
}

.status-dropdown .status {
  height: 24px;
  font-size: 14px;
}

.status-dropdown .status-inactive {
  color: #7f92a3 !important;
  background-color: #ebeef3;
}

.status-dropdown .status-inactive icon {
  color: inherit !important;
}

.status-dropdown .status-active {
  background-color: #2dd36f;
  color: #ffffff !important;
}

.status-dropdown .status-active icon {
  color: inherit !important;
}

.status-dropdown .dropdown {
  position: absolute;
  width: 161px;
  box-shadow: 0px 11px 16px #0000000f;
  border: 1px solid #e2e2e2;
  top: 40px;
  background-color: #ffffff;
  padding: 16px;
  z-index: 4;
}

.status-dropdown .dropdown:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  position: absolute;
  top: -6px;
  left: 24px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-color: #e2e2e2 transparent transparent #e2e2e2;
}

.status-dropdown .dropdown ul {
  list-style: none;
}

.status-ui {
  border-radius: 4px;
  line-height: 24px;
  display: inline-block;
  padding: 0 9px 0 22px;
  font-size: 13px;
  position: relative;
  font-weight: 400;
  text-transform: capitalize;
  white-space: pre;
}

.status-ui:before {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  transform: translateY(-50%);
}

.status-ui.pending-review {
  background-color: #F9C74F34;
  color: #E2A81D;
}

.status-ui.pending-review:before {
  background-color: #E2A81D;
}

.status-ui.pending-offer-review {
  background-color: #F9C74F34;
  color: #E2A81D;
}

.status-ui.pending-offer-review:before {
  background-color: #E2A81D;
}

.status-ui.open {
  background-color: #EBF3FF;
  color: #3B8AFF;
}

.status-ui.open:before {
  background-color: #3B8AFF;
}

.status-ui.sourcing {
  background-color: #E9F8ED;
  color: #2AB74D;
}

.status-ui.sourcing:before {
  background-color: #2AB74D;
}

.status-ui.rejected {
  background-color: #FFE9E9;
  color: #FF2929;
}

.status-ui.rejected:before {
  background-color: #FF2929;
}

.status-ui.re-hire-rejected {
  background-color: #FFE9E9;
  color: #FF2929;
}

.status-ui.re-hire-rejected:before {
  background-color: #FF2929;
}

.status-ui.offer-withdrawn {
  background-color: #F7F8F9;
  color: #7D8083;
}

.status-ui.offer-withdrawn:before {
  background-color: #7D8083;
}

.status-ui.deleted {
  background-color: #FFE9E9;
  color: #FF2929;
}

.status-ui.deleted:before {
  background-color: #FF2929;
}

.status-ui.filled {
  background-color: #E9EAFD;
  color: #2633E4;
}

.status-ui.filled:before {
  background-color: #2633E4;
}

.status-ui.closed {
  background-color: #F7F8F9;
  color: #7D8083;
}

.status-ui.closed:before {
  background-color: #7D8083;
}

.status-ui.pending-approval-sourcing {
  background-color: #FFF6E5;
  color: #FFAA00;
}

.status-ui.pending-approval-sourcing:before {
  background-color: #FFAA00;
}

.status-ui.pending-approval-halted {
  background-color: #FFF6E5;
  color: #FF9100;
}

.status-ui.pending-approval-halted:before {
  background-color: #FF9100;
}

.status-ui.pending-approval-hold {
  background-color: #FFF6E5;
  color: #FF7043;
}

.status-ui.pending-approval-hold:before {
  background-color: #FF7043;
}

.status-ui.halted {
  background-color: #E5F7FA;
  color: #00B2CA;
}

.status-ui.halted:before {
  background-color: #00B2CA;
}

.status-ui.hold {
  background-color: #F9F2EA;
  color: #BF7F35;
}

.status-ui.hold:before {
  background-color: #BF7F35;
}

.status-ui.pending-activation {
  background-color: #FFF1E9;
  color: #EF6C00;
}

.status-ui.pending-activation:before {
  background-color: #EF6C00;
}

.status-ui.submitted {
  background-color: #EBF3FF;
  color: #3B8AFF;
}

.status-ui.submitted:before {
  background-color: #3B8AFF;
}

.status-ui.re-hire-check-pending {
  background-color: #FFF1E9;
  color: #EF6C00;
}

.status-ui.re-hire-check-pending:before {
  background-color: #EF6C00;
}

.status-ui.shortlisted {
  background-color: #FFF1E9;
  color: #FF7A29;
}

.status-ui.shortlisted:before {
  background-color: #FF7A29;
}

.status-ui.interview-scheduled {
  background-color: #F2FDFE;
  color: #0094E0;
}

.status-ui.interview-scheduled:before {
  background-color: #0094E0;
}

.status-ui.interview-withdrawn {
  background-color: #F7F8F9;
  color: #7D8083;
}

.status-ui.interview-withdrawn:before {
  background-color: #7D8083;
}

.status-ui.interview-completed {
  background-color: #F2FDFE;
  color: #895DAE;
}

.status-ui.interview-completed:before {
  background-color: #895DAE;
}

.status-ui.offered {
  background-color: #FFF9C7;
  color: #5373CB;
}

.status-ui.offered:before {
  background-color: #5373CB;
}

.status-ui.offer-accepted {
  background-color: #FFF9C7;
  color: #9C6A67;
}

.status-ui.offer-accepted:before {
  background-color: #9C6A67;
}

.status-ui.withdrawn {
  background-color: #F7F8F9;
  color: #7D8083;
}

.status-ui.withdrawn:before {
  background-color: #7D8083;
}

.status-ui.hired {
  background-color: #E5F1D7;
  color: #764C04;
}

.status-ui.hired:before {
  background-color: #764C04;
}

.status-ui.released {
  background-color: #E5F1D7;
  color: #764C04;
}

.status-ui.released:before {
  background-color: #764C04;
}

.status-ui.pending-qualification-verification {
  background-color: #FFF6E5;
  color: #FFAA00;
}

.status-ui.pending-qualification-verification:before {
  background-color: #FFAA00;
}

.status-ui.pending-background-check {
  background-color: #FFF4E5;
  color: #FF9100;
}

.status-ui.pending-background-check:before {
  background-color: #FF9100;
}

.status-ui.pending-qualification-background-check {
  background-color: #FFF6E5;
  color: #FF7043;
}

.status-ui.pending-qualification-background-check:before {
  background-color: #FF7043;
}

.status-ui.draft {
  background-color: #EFEFEF;
  color: #616161;
}

.status-ui.draft:before {
  background-color: #616161;
}

.status-ui.not-scheduled {
  background-color: #EFEFEF;
  color: #616161;
}

.status-ui.not-scheduled:before {
  background-color: #616161;
}

.status-ui.pending-approval {
  background-color: #FFF5E5;
  color: #F57C00;
}

.status-ui.pending-approval:before {
  background-color: #F57C00;
}

.status-ui.pending-offer-approval {
  background-color: #FFF5E5;
  color: #F57C00;
}

.status-ui.pending-offer-approval:before {
  background-color: #F57C00;
}

.status-ui.modified {
  background-color: #EBF3FF;
  color: #3B8AFF;
}

.status-ui.modified:before {
  background-color: #3B8AFF;
}

.status-ui.active {
  background-color: #EBF3FF;
  color: #3B8AFF;
}

.status-ui.active:before {
  background-color: #3B8AFF;
}

.status-ui.inactive {
  background-color: #F7F8F9;
  color: #7D8083;
}

.status-ui.inactive:before {
  background-color: #7D8083;
}

.status-ui.pending {
  background-color: #FFF5E5;
  color: #F57C00;
}

.status-ui.pending:before {
  background-color: #F57C00;
}

.status-ui.in-progress {
  background-color: #FFF9C7;
  color: #955900;
}

.status-ui.in-progress:before {
  background-color: #955900;
}

.status-ui.overdue {
  background-color: #FFE9E9;
  color: #E36000;
}

.status-ui.overdue:before {
  background-color: #E36000;
}

.status-ui.completed {
  background-color: #E8F3EA;
  color: #1E8538;
}

.status-ui.completed:before {
  background-color: #1E8538;
}

.status-ui.default {
  background-color: #f2fdfe;
  color: #5C3B77;
}

.status-ui.default:before {
  background-color: #5C3B77;
}

.status-ui.disturbed {
  background-color: #FFF9C7;
  color: #3E63C9;
}

.status-ui.disturbed:before {
  background-color: #3E63C9;
}

.status-ui.disturbed {
  background-color: #FFF9C7;
  color: #3E63C9;
}

.status-ui.disturbed:before {
  background-color: #3E63C9;
}

.status-ui.interview-accepted {
  background-color: #F2FDFE;
  color: #895DAE;
}

.status-ui.interview-accepted:before {
  background-color: #895DAE;
}

.status-ui.approved {
  background-color: #F2FDFE;
  color: #1E8538;
}

.status-ui.approved:before {
  background-color: #1E8538;
}

.status-ui.awarded {
  background-color: #F2FDFE;
  color: #1E8538;
}

.status-ui.awarded:before {
  background-color: #1E8538;
}

.status-ui.released {
  background-color: #E5F1D7;
  color: #764C04;
}

.status-ui.released:before {
  background-color: #764C04;
}

.status-ui.countered {
  background-color: #7A90B51A;
  color: #7A90B5;
}

.status-ui.countered:before {
  background-color: #7A90B5;
}

.status-ui.countered-pending-review {
  background-color: #F9C74F34;
  color: #E2A81D;
}

.status-ui.countered-pending-review:before {
  background-color: #E2A81D;
}

.status-ui.deferred {
  background-color: #F2FDFE;
  color: #5C3B77;
}

.status-ui.deferred:before {
  background-color: #5C3B77;
}

.status-ui.missing {
  background-color: #FFF5E5;
  color: #F57C00;
}

.status-ui.missing:before {
  background-color: #F57C00;
}

.user-preference-wrap {
  max-width: 1300px;
  margin: auto;
}

.user-preference-wrap .preference-body {
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #0000000B;
}

.user-preference-wrap .preference-body .profile-sidebar {
  float: right;
  width: 300px;
  background-color: #FAF8FF;
  margin-left: 20px;
}

.user-preference-wrap .preference-body .profile-sidebar ul {
  list-style-type: none;
}

.user-preference-wrap .preference-body .profile-sidebar ul.contact-info li {
  margin-bottom: 28px;
  overflow: hidden;
}

.user-preference-wrap .preference-body .profile-sidebar ul.contact-info li icon {
  background-color: #E8ECFF;
  border-radius: 50%;
  width: 36px;
  text-align: center;
  line-height: 36px;
}

.user-preference-wrap .preference-body .profile-sidebar ul.contact-info li icon i {
  color: #aba4db;
  vertical-align: middle;
}

.user-preference-wrap .preference-body .profile-sidebar ul.contact-info li p {
  float: left;
  color: #3A4550;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 18px;
}

.user-preference-wrap .preference-body .profile-sidebar ul.contact-info li p span {
  font-size: 12px;
  display: block;
}

.user-preference-wrap .preference-body .profile-sidebar ul.social-info li {
  display: inline-block;
  margin-right: 4px;
}

.user-preference-wrap .preference-body .profile-left-section .edit-profile {
  height: 26px;
  font-size: 12px;
  font-weight: 400;
}

.user-preference-wrap .preference-body .profile-left-section h4 {
  line-height: 29px;
  color: #000000;
}

.user-preference-wrap .preference-body .profile-left-section h4 icon {
  width: 29px;
  height: 29px;
  background: #E7E9F8;
  border-radius: 4px;
  text-align: center;
}

.user-preference-wrap .preference-body .profile-left-section h4 icon i {
  vertical-align: middle;
  color: #5E71FE;
  margin-top: -2px;
}

.user-preference-wrap .preference-body .profile-left-section h4.location icon {
  background-color: #C5FFBA;
}

.user-preference-wrap .preference-body .profile-left-section h4.location icon i {
  color: #4AAC33;
}

.user-preference-wrap .preference-body .profile-left-section h4.hierarchy icon {
  background-color: #E2E3D2;
}

.user-preference-wrap .preference-body .profile-left-section h4.hierarchy icon i {
  color: #B25A75;
}

.user-preference-wrap .preference-body .profile-left-section .profile-info p {
  flex-basis: 30%;
  margin-right: 3%;
  color: #343a40;
  font-weight: 500;
  line-height: 20px;
}

.user-preference-wrap .preference-body .profile-left-section .profile-info p span {
  display: block;
  font-size: 12px;
  color: #ADB5BD;
}

.user-preference-wrap .preference-body .profile-left-section .profile-info p figure {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
}

.user-preference-wrap .preference-body .profile-left-section .profile-info p figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-preference-wrap .preference-body .profile-left-section .profile-info p a {
  color: var(--color-assets-primary);
}

.user-preference-wrap .preference-body .profile-left-section .profile-info p a icon i {
  vertical-align: middle;
  color: var(--color-assets-primary);
}

.user-preference-wrap .preference-body .profile-left-section .level-listing {
  margin: 0;
  border: 0;
  overflow: hidden;
}

.user-preference-wrap .preference-body .profile-left-section .level-listing li {
  float: left;
  margin-right: 16px;
}

.user-preference-wrap .preference-body .profile-left-section .level-listing .listing-item {
  padding-left: 20px !important;
  padding-right: 20px;
  background-color: #FAF8FF;
  border: 1px solid #F2EFFA;
}

.user-preference-wrap .preference-body .profile-left-section .level-listing .listing-item .level-title {
  text-decoration: none !important;
}

.user-preference-wrap .preference-body .profile-left-section .level-listing .listing-item .level-status,
.user-preference-wrap .preference-body .profile-left-section .level-listing .listing-item .initials {
  display: none;
}

.timesheet-page .create-wrapper {
  min-height: inherit;
}

.timesheet-page .date-range {
  font-size: 16px;
}

.timesheet-page .title-icon {
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
}

.timesheet-page .title-icon i {
  color: var(--color-assets-primary);
}

.timesheet-page .ts-data-wrap-head {
  line-height: 32px;
}

.timesheet-page .ts-data-wrap-head icon {
  line-height: 0px;
  margin-top: 5px;
  float: left;
}

.timesheet-page .ts-data-wrap-head icon i {
  font-size: 18px !important;
  margin-top: 3px;
  margin-right: 10px;
  color: var(--color-assets-primary);
}

.timesheet-page .ts-data-wrap-head label {
  float: left;
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 500;
}

.timesheet-page .ts-data-wrap-head span {
  float: left;
  font-size: 15px;
  margin-left: 8px;
  color: #6C757D;
}

.timesheet-page .sheet-wrapper {
  border: 1px solid #F5F4FA;
  border-left: 0px;
  border-right: 0px;
}

.timesheet-page .sheet-wrapper .bb-0 {
  border-bottom: 0px !important;
}

.timesheet-page .sheet-wrapper .headings {
  border-right: 1px solid #F5F4FA;
  flex-basis: 15%;
  color: #000000;
}

.timesheet-page .sheet-wrapper .headings p {
  color: #000000;
}

.timesheet-page .sheet-wrapper .headings .col-item {
  padding: 12px 20px;
  border-bottom: 1px solid #F5F4FA;
  min-height: 40px;
}

.timesheet-page .sheet-wrapper .headings .col-item:first-child {
  min-height: 48px;
}

.timesheet-page .sheet-wrapper .headings .col-item.read-only {
  line-height: 23px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 190px;
}

.timesheet-page .sheet-wrapper .headings .col-item.last {
  min-height: 86px;
  border-bottom: 0;
}

.timesheet-page .sheet-wrapper .headings .col-item.last icon {
  vertical-align: middle;
}

.timesheet-page .sheet-wrapper .headings .col-item a {
  color: #2680eb;
  margin-top: 8px;
  display: inline-block;
}

.timesheet-page .sheet-wrapper .headings .col-item a icon {
  vertical-align: middle;
}

.timesheet-page .sheet-wrapper .headings .col-item a icon i {
  color: #2680eb;
}

.timesheet-page .sheet-wrapper .headings .col-item .no-project-selectbox {
  flex: 2;
  max-width: 190px;
  min-height: 40px;
  padding-top: 10px;
}

.timesheet-page .sheet-wrapper .headings .col-item .project-selectbox {
  flex: 2;
}

.timesheet-page .sheet-wrapper .headings .col-item .project-selectbox .ng-select {
  max-width: 190px;
}

.timesheet-page .sheet-wrapper .headings .col-item .project-selectbox .ng-option-label {
  font-size: 11px;
  line-height: 18px;
}

.timesheet-page .sheet-wrapper .headings .col-item .delete-btn {
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
}

.timesheet-page .sheet-wrapper .headings .col-item .project-item {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timesheet-page .sheet-wrapper .headings .col-item.total-billable-hour a {
  white-space: nowrap;
}

.timesheet-page .sheet-wrapper .headings .col-item.time-worked-title {
  padding-top: 24px;
  padding-bottom: 24px;
}

.timesheet-page .sheet-wrapper .headings .col-item.separation-bg {
  background-color: #F7FAFC;
  padding-top: 8px;
  padding-bottom: 8px;
}

.timesheet-page .sheet-wrapper .day-col {
  flex-basis: 10.625%;
  transition: 0.2s ease-in-out;
  position: relative;
  background: #fff;
}

.timesheet-page .sheet-wrapper .day-col::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 3px;
  margin-top: -2px;
  background: none;
  z-index: 1;
}

.timesheet-page .sheet-wrapper .day-col:hover::before {
  background-color: var(--color-assets-primary);
}

.timesheet-page .sheet-wrapper .day-col .col-item {
  padding: 12px 20px;
  border-bottom: 1px solid #f5f4fa;
  border-right: 1px solid #f5f4fa;
  position: relative;
  text-align: center;
  min-height: 40px;
  display: table;
  width: 100%;
}

.timesheet-page .sheet-wrapper .day-col .col-item span {
  position: absolute;
  top: 16px;
  right: 15px;
  font-size: 12px;
  color: #b2b5b7;
  font-weight: 500;
}

.timesheet-page .sheet-wrapper .day-col .col-item span.ng-clear-wrapper {
  position: relative !important;
  top: auto;
  right: auto;
  color: #999;
}

.timesheet-page .sheet-wrapper .day-col .col-item span.ng-clear-wrapper span {
  position: static;
  font-size: 18px;
}

.timesheet-page .sheet-wrapper .day-col .col-item.first label {
  float: left;
  margin-bottom: 0px;
}

.timesheet-page .sheet-wrapper .day-col .col-item.first icon {
  float: left;
  line-height: 0px;
  margin-left: 6px;
}

.timesheet-page .sheet-wrapper .day-col .col-item.last {
  text-align: left;
  font-size: 12px;
  min-height: 86px;
  border-bottom: 0;
}

.timesheet-page .sheet-wrapper .day-col .col-item.custom-height {
  min-height: 95px;
}

.timesheet-page .sheet-wrapper .day-col .col-item.read-only {
  line-height: 23px;
}

.timesheet-page .sheet-wrapper .day-col .col-item .add-note {
  background-color: #F3F4FF;
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.timesheet-page .sheet-wrapper .day-col .col-item .add-note icon {
  display: block;
  line-height: 22px;
}

.timesheet-page .sheet-wrapper .day-col .col-item .add-note icon i {
  color: #2680EB;
  vertical-align: middle;
}

.timesheet-page .sheet-wrapper .day-col .col-item .add-note:hover {
  background-color: #5E71FE;
}

.timesheet-page .sheet-wrapper .day-col .col-item .add-note:hover icon i {
  color: #ffffff;
}

.timesheet-page .sheet-wrapper .day-col .col-item .add-note.read-only {
  opacity: 0;
  pointer-events: none;
}

.timesheet-page .sheet-wrapper .day-col .col-item.time-worked {
  border-top: 1px solid #F5F4FA;
}

.timesheet-page .sheet-wrapper .day-col .col-item.separation-bg {
  background-color: #F7FAFC;
}

.timesheet-page .sheet-wrapper .day-col.holiday {
  background-color: #fcfbff;
}

.timesheet-page .sheet-wrapper .day-col.holiday::before {
  background-color: #FF8B8B;
}

.timesheet-page .sheet-wrapper .day-col.disableClass {
  background-color: #fcfbff;
}

.timesheet-page .sheet-wrapper .day-col:hover {
  box-shadow: 0px 7px 20px #00000014;
  z-index: 1;
}

.timesheet-page .sheet-wrapper .day-col .error-message-box {
  position: absolute;
  top: 0;
  left: 100%;
  width: 230px;
  background-color: #eb445a;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
}

.timesheet-page .sheet-wrapper .day-col .error-message-box:before {
  position: absolute;
  top: 9px;
  left: -16px;
  border-right: 27px solid #eb445a;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  z-index: 12;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  width: 2px;
  height: 2px;
}

.timesheet-page .sheet-wrapper .day-col .error-message-box p {
  color: #ffffff;
}

.timesheet-page .sheet-wrapper .day-col .error-message-box ul {
  list-style-type: none;
  margin-left: 16px;
}

.timesheet-page .sheet-wrapper .day-col .error-message-box ul li {
  position: relative;
  margin-top: 12px;
}

.timesheet-page .sheet-wrapper .day-col .error-message-box ul li:before {
  position: absolute;
  top: 5px;
  left: -15px;
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  visibility: visible;
  content: ".";
  text-indent: -9999px;
}

.timesheet-page .sheet-wrapper .day-col.field-error:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #eb445a;
  border: 1px sold #eb445a;
  height: 2px;
  z-index: 1;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page .sheet-wrapper .day-col.field-error:hover .error-message-box {
  opacity: 1;
  visibility: visible;
}

.timesheet-page .sheet-wrapper .day-col.field-error input.has-error {
  border-color: #eb445a;
  color: #eb445a;
}

.timesheet-page .sheet-wrapper .day-col.field-error .col-item.first p {
  color: #eb445a;
}

.timesheet-page .sheet-wrapper .total-col {
  flex-basis: 10.625%;
  position: relative;
}

.timesheet-page .sheet-wrapper .total-col .error-message-box {
  position: absolute;
  top: 0;
  right: 100%;
  width: 230px;
  background-color: #eb445a;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 10;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
}

.timesheet-page .sheet-wrapper .total-col .error-message-box:before {
  position: absolute;
  top: 9px;
  right: -16px;
  border-left: 27px solid #eb445a;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  z-index: 12;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  width: 2px;
  height: 2px;
}

.timesheet-page .sheet-wrapper .total-col .error-message-box p {
  color: #ffffff;
}

.timesheet-page .sheet-wrapper .total-col .error-message-box ul {
  list-style-type: none;
  margin-left: 16px;
}

.timesheet-page .sheet-wrapper .total-col .error-message-box ul li {
  position: relative;
  margin-top: 12px;
}

.timesheet-page .sheet-wrapper .total-col .error-message-box ul li:before {
  position: absolute;
  top: 5px;
  left: -15px;
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  visibility: visible;
  content: ".";
  text-indent: -9999px;
}

.timesheet-page .sheet-wrapper .total-col .col-item {
  padding: 12px 15px;
  border-bottom: 1px solid #f5f4fa;
  text-align: center;
  min-height: 65px;
  color: #000000;
  font-size: 16px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.first-item {
  min-height: 48px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.read-only {
  padding-top: 12px !important;
  min-height: 48px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.read-only .mt-40 {
  display: none;
}

.timesheet-page .sheet-wrapper .total-col .col-item.custom-height {
  min-height: 95px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.small-height {
  min-height: 45px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.last {
  min-height: 86px;
  border-bottom: 0;
}

.timesheet-page .sheet-wrapper .total-col .col-item.billable-item {
  min-height: 39px;
  padding: 11px 20px 10px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.draft-total {
  line-height: 40px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.time-worked {
  border-top: 1px solid #F5F4FA;
  min-height: inherit;
  padding-top: 22px;
  padding-bottom: 22px;
}

.timesheet-page .sheet-wrapper .total-col .col-item.separation-bg {
  background-color: #F7FAFC;
  min-height: 40px;
}

.timesheet-page .sheet-wrapper .total-col.field-error:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #eb445a;
  border: 1px sold #eb445a;
  height: 2px;
  z-index: 1;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page .sheet-wrapper .total-col.field-error:hover .error-message-box {
  opacity: 1;
  visibility: visible;
}

.timesheet-page .sheet-wrapper .total-col.field-error input.has-error {
  border-color: #eb445a;
  color: #eb445a;
}

.timesheet-page .sheet-wrapper .total-col.field-error .col-item.first p {
  color: #eb445a;
}

.timesheet-page .sheet-wrapper .total-col.non-editable .col-item {
  padding: 7px 20px;
  min-height: 48px;
}

.timesheet-page .sheet-wrapper .total-col.non-editable .col-item.first-item {
  min-height: 48px;
}

.timesheet-page .sheet-wrapper .total-col.non-editable .col-item.has-decimal {
  padding: 2px 20px;
  min-height: 41px;
}

.timesheet-page .sheet-wrapper>div:nth-child(8) .error-message-box {
  left: auto;
  right: 100%;
}

.timesheet-page .sheet-wrapper>div:nth-child(8) .error-message-box:before {
  left: auto;
  right: -16px;
  border-left: 27px solid #eb445a;
  border-right: 0;
}

.timesheet-page .sheet-wrapper.automation .day-col .col-item.read-only {
  line-height: 16px;
}

.timesheet-page .sheet-wrapper.automation .headings .col-item.read-only {
  line-height: 16px;
}

.timesheet-page .sheet-wrapper.automation .headings .col-item.total-billable-hour {
  max-height: 42px;
}

.timesheet-page .sheet-wrapper.automation .headings .billable-breakup .col-item {
  min-height: 42px;
}

.timesheet-page .sheet-wrapper.automation .headings .billable-breakup .col-item:first-child {
  min-height: 42px;
}

.timesheet-page .footer-note h6 {
  color: #776de0;
  font-size: 14px;
  font-weight: 400;
}

.timesheet-page .footer-note h6 icon {
  vertical-align: middle;
  margin-right: 12px;
}

.timesheet-page .footer-note h6 icon i {
  color: #776de0;
}

.timesheet-page .mt-28 {
  margin-top: 29px;
}

.timesheet-page .mt-40 {
  margin-top: 40px;
}

.timesheet-page .mt-34 {
  margin-top: 34px;
}

.timesheet-page .pt-20 {
  padding-top: 20px !important;
}

.timesheet-page .mt-25 {
  margin-top: 25px;
}

.timesheet-page .btn-section {
  float: right;
  margin: -8px 24px 12px 0px;
}

.timesheet-page .error-required {
  color: #eb445a;
  float: right;
  margin: 5px;
}

.timesheet-page .error-border {
  border: 1px solid #eb445a;
  border-radius: 4px;
}

.timesheet-page .alert_message {
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  text-align: left;
}

.timesheet-page .alert_message icon {
  margin-top: 0px;
}

.timesheet-page .alert_message .medium-font {
  font-size: 13px;
}

.timesheet-page .alert_error {
  border-left: 5px solid #f72951;
  color: #fd556b;
  margin-top: -14px;
  margin-bottom: 7px;
  background: #ffc8cf;
}

.timesheet-page .alert_success {
  border-left: 5px solid #43a047;
  color: #43a047;
  margin-top: -14px;
  margin-bottom: 7px;
  background: #f1f8e9;
}

.timesheet-page .alert_pending {
  border-left: 5px solid #ed8936;
  color: #ed8936;
  margin-top: -14px;
  margin-bottom: 7px;
  background: #feebc8;
}

.timesheet-page .clear-btn {
  border: 0;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  color: #6c757d;
  height: 30px;
}

.timesheet-page .clear-btn icon {
  line-height: 30px;
}

.timesheet-page .clear-btn icon i {
  vertical-align: middle;
  color: #6c757d;
}

.timesheet-page .error-icon {
  margin-right: 18px;
}

.timesheet-page .add-note-box {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 150%;
  z-index: 1000;
  background-color: #ffffff;
  display: none;
}

.timesheet-page .add-note-box textarea {
  border: 1px solid #82c0fd;
  background-color: #ffffff;
  color: #3a4550;
  width: 100%;
  resize: none;
  padding: 4px;
  outline: none;
}

.timesheet-page .add-note-box.active {
  display: block;
}

.timesheet-page .inDecimals {
  color: #6c757d;
  font-size: 12px;
}

.timesheet-page .copy-btn {
  font-size: 12px;
}

.timesheet-page .copy-btn icon i {
  color: var(--color-assets-primary);
}

.timesheet-page .more-btn-icon {
  border-color: #ffffff;
}

.timesheet-page .more-btn-icon:hover {
  border-color: var(--color-assets-primary);
}

.timesheet-page .clear-data-dropdown {
  position: absolute;
  top: 43px;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000033;
  z-index: 10;
  width: 157px;
  padding: 6px 18px;
  opacity: 0;
  visibility: hidden;
}

.timesheet-page .clear-data-dropdown:before {
  position: absolute;
  top: -11px;
  right: 8px;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #ffffff;
  border-right: 5px solid #ffffff;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page .clear-data-dropdown.active {
  opacity: 1;
  visibility: visible;
}

.timesheet-page .copy-widget-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 623px;
  max-width: 90%;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 1000;
  max-height: 90%;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
}

.timesheet-page .copy-widget-modal .available-week-wrap .available-week {
  border: 1px solid #EFEAFF;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.timesheet-page .copy-widget-modal .available-week-wrap .available-week .radio-wrapper {
  margin-top: 4px;
  width: 18px;
  height: 18px;
  border: 2px solid #000000;
  border-radius: 50%;
  position: relative;
  transition: 0.2s ease-in-out;
  box-sizing: content-box;
  pointer-events: none;
}

.timesheet-page .copy-widget-modal .available-week-wrap .available-week .radio-wrapper .checkmark {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  transition: 0.2s ease-in-out;
  border-radius: 50%;
}

.timesheet-page .copy-widget-modal .available-week-wrap .available-week p {
  color: #3A4550;
}

.timesheet-page .copy-widget-modal .available-week-wrap .available-week p span {
  color: #6c757d;
}

.timesheet-page .copy-widget-modal .available-week-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.timesheet-page .copy-widget-modal .available-week-wrap input:checked~.available-week {
  border-color: #7283FE4B;
  background-color: #FBFAFF;
}

.timesheet-page .copy-widget-modal .available-week-wrap input:checked~.available-week .radio-wrapper {
  border-color: #7283FE;
}

.timesheet-page .copy-widget-modal .available-week-wrap input:checked~.available-week .radio-wrapper .checkmark {
  background-color: #7283FE;
}

.timesheet-page .copy-widget-modal .copy-widget-modal-footer {
  background-color: #FBFAFF;
  border-top: 1px solid #F0EFF7;
  padding: 12px;
  overflow: hidden;
}

.timesheet-page .copy-widget-modal.active {
  opacity: 1;
  visibility: visible;
}

.timesheet-page .billable {
  margin-top: 24px;
}

.timesheet-page .billable table {
  table-layout: fixed;
}

.timesheet-page .billable table thead tr th {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timesheet-page .billable table thead tr th span {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.timesheet-page .billable table thead tr th:first-child {
  background-color: #F9F7FF;
  width: 15%;
}

.timesheet-page .billable table tbody tr td {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.timesheet-page .billable table tbody tr td span {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}

.timesheet-page .billable table tbody tr td:first-child {
  background-color: #F9F7FF;
}

.timesheet-page .copy-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: #1F2031;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.timesheet-page .copy-widget-overlay.active {
  opacity: .85;
  visibility: visible;
}

.timesheet-page .min-height-div {
  min-height: 29px;
}

.timesheet-page .max-height-paragraph {
  height: 78px;
  max-height: 78px;
  overflow: hidden;
  word-break: break-all;
}

.timesheet-page .no-resize {
  resize: none;
}

.timesheet-page.tito-timesheet .negative {
  color: #ff2b2b;
  font-weight: 700;
}

.timesheet-page.tito-timesheet .date-range {
  font-size: 16px;
}

.timesheet-page.tito-timesheet .read-only {
  opacity: .5;
  pointer-events: none;
}

.timesheet-page.tito-timesheet .time-label {
  position: relative;
}

.timesheet-page.tito-timesheet .time-label p {
  font-size: 12px;
  color: #000000;
}

.timesheet-page.tito-timesheet .time-label p icon {
  display: inline-block;
  vertical-align: middle;
}

.timesheet-page.tito-timesheet .time-label p icon i {
  color: var(--color-assets-primary);
}

.timesheet-page.tito-timesheet .time-label p:first-child {
  margin-bottom: 44px;
}

.timesheet-page.tito-timesheet .time-label:before {
  position: absolute;
  top: 5px;
  left: 7px;
  bottom: 10px;
  border-left: 1px dashed var(--color-assets-primary);
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page.tito-timesheet .in-time>div p {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #ADB6FE;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #ffffff;
  font-size: 10px;
  transition: 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 8;
}

.timesheet-page.tito-timesheet .in-time>div p .prev-day {
  position: absolute;
  top: 3px;
  left: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .in-time>div p .prev-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .in-time>div p .next-day {
  position: absolute;
  top: 3px;
  right: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .in-time>div p .next-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .in-time>div:hover input {
  border-color: #ADB6FE;
}

.timesheet-page.tito-timesheet .in-time>div:hover p {
  visibility: visible;
  opacity: 1;
}

.timesheet-page.tito-timesheet .out-time>div p {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #79DD5B;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #ffffff;
  font-size: 10px;
  transition: 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 8;
}

.timesheet-page.tito-timesheet .out-time>div p .prev-day {
  position: absolute;
  top: 3px;
  left: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .out-time>div p .prev-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .out-time>div p .next-day {
  position: absolute;
  top: 3px;
  right: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .out-time>div p .next-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .out-time>div:hover input {
  border-color: #79DD5B;
}

.timesheet-page.tito-timesheet .out-time>div:hover p {
  visibility: visible;
  opacity: 1;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col {
  border-top: 2px solid transparent;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col:hover {
  border-top-color: #5261FF;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col.holiday {
  border-color: #FF8B8B;
  background-color: #FDFDFE;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item {
  border-bottom: 0;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item.first {
  text-align: left;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item.first .holiday-icon {
  float: none;
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
  line-height: 22px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item.both-border {
  border-top: 1px solid #f5f4fa;
  border-bottom: 1px solid #f5f4fa;
  min-height: 45px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item.note-col {
  border-top: 1px solid #f5f4fa;
  min-height: 86px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item.total-head {
  min-height: 45px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item.read-only {
  min-height: 65px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item.break {
  min-height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item span.ng-value-label {
  position: static;
  font-size: 14px;
  color: var(--color-body-txt-default);
  font-weight: 400;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item span.ng-arrow-wrapper {
  position: static;
  font-size: 14px;
  color: var(--color-body-txt-default);
  font-weight: 400;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item span.ng-arrow-wrapper span {
  position: static;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item span.ng-option-label {
  position: static;
  font-size: 14px;
  color: var(--color-body-txt-default);
  font-weight: 400;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col .col-item .add-note.read-only {
  opacity: .5;
  pointer-events: none;
}

.timesheet-page.tito-timesheet .sheet-wrapper .day-col:before {
  display: none;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item {
  border-bottom: 0;
  min-height: 64px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item:first-child {
  min-height: 48px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item.both-border {
  border-top: 1px solid #f5f4fa;
  border-bottom: 1px solid #f5f4fa;
  min-height: 38px;
  margin-top: 2px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item.both-border.read-only {
  margin-top: 4px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item.total-head {
  font-size: 14px;
  min-height: 45px;
  border-bottom: 1px solid #f5f4fa;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item.total-head.breakup-active {
  border-bottom-color: transparent;
  border-top: 1px solid #f5f4fa;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item.total-head.total-for-billable {
  border-top: 1px solid #f5f4fa;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item.break {
  min-height: 200px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item-wrapper .col-item {
  font-size: 14px;
  min-height: 41px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .total-col .col-item-wrapper.activewrap {
  border-bottom: 1px solid #f5f4fa;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item {
  border-bottom: 0;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item.both-border {
  border-top: 1px solid #f5f4fa;
  border-bottom: 1px solid #f5f4fa;
  margin-top: 11px;
  min-height: 45px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item.both-border.read-only {
  margin-top: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item.last {
  min-height: 86px;
  border-bottom: 0;
  border-top: 1px solid #f5f4fa;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item.last icon {
  vertical-align: middle;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item.break {
  min-height: 200px;
  border-top: 1px solid #f5f4fa;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item.total-billable-hour {
  border-top: 1px solid #f5f4fa;
  padding-bottom: 10px;
}

.timesheet-page.tito-timesheet .sheet-wrapper .headings .col-item-wrapper .col-item {
  white-space: nowrap;
  max-width: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 41px;
}

.timesheet-page.tito-timesheet .break-item {
  background: #FDF4FF;
  border-radius: 4px;
  padding: 8px 8px 8px 24px;
  margin-bottom: 8px;
}

.timesheet-page.tito-timesheet .break-item p {
  font-size: 12px;
  color: #6c757d;
  line-height: 16px;
  position: relative;
  text-align: left;
}

.timesheet-page.tito-timesheet .break-item p:before {
  position: absolute;
  top: 3px;
  left: -16px;
  width: 7px;
  height: 7px;
  border: 1px solid #ABB5FE;
  border-radius: 50%;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page.tito-timesheet .break-item:before {
  position: absolute;
  top: 17px;
  bottom: 19px;
  left: 12px;
  border-left: 1px dashed #ABB5FE;
  width: 1px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page.tito-timesheet .more-item-hover {
  position: absolute;
  top: 0px;
  left: 0;
  width: 130px;
  background-color: #ffffff;
  border: 4px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  z-index: 6;
  padding: 8px;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.timesheet-page.tito-timesheet .more-item-hover.active {
  opacity: 1;
  visibility: visible;
}

.timesheet-page.tito-timesheet .more-item-hover .close-more-item {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 5;
}

.timesheet-page.tito-timesheet .more-item {
  color: #ABB5FE;
  font-size: 11px;
}

.timesheet-page.tito-timesheet .break-trigger,
.timesheet-page.tito-timesheet .add-note {
  background-color: #F3F4FF;
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.timesheet-page.tito-timesheet .break-trigger icon,
.timesheet-page.tito-timesheet .add-note icon {
  display: block;
  line-height: 22px;
}

.timesheet-page.tito-timesheet .break-trigger icon i,
.timesheet-page.tito-timesheet .add-note icon i {
  color: #2680EB;
  vertical-align: middle;
}

.timesheet-page.tito-timesheet .break-trigger:hover,
.timesheet-page.tito-timesheet .add-note:hover {
  background-color: #5E71FE;
}

.timesheet-page.tito-timesheet .break-trigger:hover icon i,
.timesheet-page.tito-timesheet .add-note:hover icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .break-modal {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  width: 170px;
  z-index: 7;
  background-color: #ffffff;
  display: none;
  padding: 10px;
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.timesheet-page.tito-timesheet .break-modal.active {
  display: block;
}

.timesheet-page.tito-timesheet .break-modal .break-out-field p {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #ADB6FE;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #ffffff;
  font-size: 10px;
  transition: 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 8;
}

.timesheet-page.tito-timesheet .break-modal .break-out-field p .prev-day {
  position: absolute;
  top: 3px;
  left: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .break-modal .break-out-field p .prev-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .break-modal .break-out-field p .next-day {
  position: absolute;
  top: 3px;
  right: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .break-modal .break-out-field p .next-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .break-modal .break-out-field p:before {
  display: none;
}

.timesheet-page.tito-timesheet .break-modal .break-out-field:hover p {
  opacity: 1;
  visibility: visible;
}

.timesheet-page.tito-timesheet .break-modal .break-in-field p {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #79DD5B;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #ffffff;
  font-size: 10px;
  transition: 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  z-index: 8;
}

.timesheet-page.tito-timesheet .break-modal .break-in-field p .prev-day {
  position: absolute;
  top: 3px;
  left: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .break-modal .break-in-field p .prev-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .break-modal .break-in-field p .next-day {
  position: absolute;
  top: 3px;
  right: 1px;
  z-index: 2;
}

.timesheet-page.tito-timesheet .break-modal .break-in-field p .next-day icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet .break-modal .break-in-field p::before {
  display: none;
}

.timesheet-page.tito-timesheet .break-modal .break-in-field:hover p {
  opacity: 1;
  visibility: visible;
}

.timesheet-page.tito-timesheet .break-item .break-modal {
  top: 44px;
}

.timesheet-page.tito-timesheet .no-break {
  color: #ADB5BD;
}

.timesheet-page.tito-timesheet .no-break p {
  font-size: 12px;
}

.timesheet-page.tito-timesheet .no-break icon i {
  color: #ADB5BD;
}

.timesheet-page.manual-mode.note-mandatory .project-note {
  min-height: 79px;
  display: table;
  text-align: center;
  width: 100%;
  padding: 0;
}

.timesheet-page.manual-mode.note-mandatory .project-note .add-note-box {
  width: 100%;
  position: static;
  z-index: 0;
}

.timesheet-page.manual-mode.note-mandatory .project-note span {
  position: static !important;
}

.timesheet-page.manual-mode.note-mandatory .project-note span.max-height-paragraph {
  max-height: 62px;
}

.timesheet-page.manual-mode.note-mandatory .headings .col-item.read-only {
  min-height: 47px;
  max-width: 229px;
  width: 229px;
}

.timesheet-page.manual-mode.note-mandatory .headings .col-item.read-only .notes-title-first-col .project-code {
  white-space: normal;
  line-height: 20px;
}

.timesheet-page.manual-mode.note-mandatory .min-height-div {
  min-height: 28px;
}

.timesheet-page.manual-mode.note-mandatory .min-height-div.has-add-btn {
  min-height: 22px;
}

.timesheet-page.manual-mode.note-mandatory .notes-title-first-col {
  padding: 8px;
}

.timesheet-page.manual-mode.note-mandatory .notes-title-first-col .project-code {
  height: 57px;
  overflow: hidden;
  margin-bottom: 24px;
  word-break: break-all;
}

.timesheet-page.manual-mode.note-mandatory .notes-title-first-col .project-code.last-col {
  height: 53px;
  margin-bottom: 0;
}

.timesheet-page.manual-mode.note-mandatory .total-col .col-item.notes-enable {
  min-height: 179px;
}

.timesheet-page.manual-mode.note-mandatory .total-col .col-item.notes-enable.read-only {
  min-height: 126px;
}

.timesheet-page.manual-mode.note-optional .project-note {
  min-height: 79px;
  display: table;
  text-align: center;
  width: 100%;
  padding: 0;
}

.timesheet-page.manual-mode.note-optional .project-note .add-note-box {
  width: 100%;
  left: 0;
  z-index: 0;
}

.timesheet-page.manual-mode.note-optional .project-note span {
  position: static !important;
}

.timesheet-page.manual-mode.note-optional .project-note span.max-height-paragraph {
  max-height: 62px;
}

.timesheet-page.manual-mode.note-optional .headings .col-item.read-only {
  min-height: 47px;
  width: auto;
  max-width: 229px;
}

.timesheet-page.manual-mode.note-optional .headings .col-item.read-only .notes-title-first-col .project-code {
  white-space: normal;
  line-height: 19px;
}

.timesheet-page.manual-mode.note-optional .min-height-div {
  min-height: 28px;
}

.timesheet-page.manual-mode.note-optional .min-height-div.has-add-btn {
  min-height: 22px;
}

.timesheet-page.manual-mode.note-optional .notes-title-first-col {
  padding: 8px;
}

.timesheet-page.manual-mode.note-optional .notes-title-first-col .project-code {
  height: 57px;
  overflow: hidden;
  margin-bottom: 18px;
  word-break: break-all;
}

.timesheet-page.manual-mode.note-optional .notes-title-first-col .project-code.last-col {
  height: 40px;
  margin-bottom: 6px;
}

.timesheet-page.manual-mode.note-optional .total-col .col-item.notes-enable {
  min-height: 173px;
}

.timesheet-page.manual-mode.note-optional .total-col.non-editable .col-item.notes-enable {
  min-height: 126px !important;
}

.timesheet-page.manual-mode .mt-41 {
  margin-top: 41px;
}

.timesheet-page.automatic-mode.note-mandatory .project-note {
  min-height: 79px;
  display: table;
  text-align: center;
  width: 100%;
  padding: 0;
}

.timesheet-page.automatic-mode.note-mandatory .project-note .add-note-box {
  width: 100%;
  position: static;
  z-index: 0;
}

.timesheet-page.automatic-mode.note-mandatory .project-note span {
  position: static !important;
}

.timesheet-page.automatic-mode.note-mandatory .project-note span.max-height-paragraph {
  max-height: 62px;
}

.timesheet-page.automatic-mode.note-mandatory .headings .col-item.read-only {
  min-height: 47px;
  width: auto;
  max-width: 229px;
}

.timesheet-page.automatic-mode.note-mandatory .headings .col-item.read-only .notes-title-first-col .project-code {
  white-space: normal;
  line-height: 20px;
}

.timesheet-page.automatic-mode.note-mandatory .min-height-div {
  min-height: 35px;
}

.timesheet-page.automatic-mode.note-mandatory .min-height-div.has-add-btn {
  min-height: 22px;
}

.timesheet-page.automatic-mode.note-mandatory .notes-title-first-col {
  padding: 8px;
}

.timesheet-page.automatic-mode.note-mandatory .notes-title-first-col .project-code {
  height: 57px;
  overflow: hidden;
  margin-bottom: 23px;
  word-break: break-all;
}

.timesheet-page.automatic-mode.note-mandatory .notes-title-first-col .project-code.last-col {
  height: 53px;
  margin-bottom: 0;
}

.timesheet-page.automatic-mode.note-mandatory .total-col .col-item.notes-enable {
  min-height: 179px;
}

.timesheet-page.automatic-mode.note-mandatory .total-col.non-editable .col-item.notes-enable {
  min-height: 119px !important;
}

.timesheet-page.automatic-mode.note-optional .project-note {
  min-height: 79px;
  display: table;
  text-align: center;
  width: 100%;
  padding: 0;
}

.timesheet-page.automatic-mode.note-optional .project-note .add-note-box {
  width: 100%;
  left: 0;
  z-index: 0;
}

.timesheet-page.automatic-mode.note-optional .project-note span {
  position: static !important;
}

.timesheet-page.automatic-mode.note-optional .project-note span.max-height-paragraph {
  max-height: 62px;
}

.timesheet-page.automatic-mode.note-optional .headings .col-item.read-only {
  min-height: 47px;
  width: auto;
  max-width: 229px;
}

.timesheet-page.automatic-mode.note-optional .headings .col-item.read-only .notes-title-first-col .project-code {
  white-space: normal;
  line-height: 19px;
}

.timesheet-page.automatic-mode.note-optional .min-height-div {
  min-height: 28px;
}

.timesheet-page.automatic-mode.note-optional .min-height-div.has-add-btn {
  min-height: 28px;
}

.timesheet-page.automatic-mode.note-optional .notes-title-first-col {
  padding: 8px;
}

.timesheet-page.automatic-mode.note-optional .notes-title-first-col .project-code {
  height: 57px;
  overflow: hidden;
  margin-bottom: 18px;
  word-break: break-all;
}

.timesheet-page.automatic-mode.note-optional .notes-title-first-col .project-code.last-col {
  height: 40px;
  margin-bottom: 6px;
}

.timesheet-page.automatic-mode.note-optional .total-col .col-item.notes-enable {
  min-height: 173px;
}

.timesheet-page.automatic-mode.note-optional .total-col.non-editable .col-item.notes-enable {
  min-height: 119px !important;
}

.timesheet-page.automatic-mode .mt-41 {
  margin-top: 41px;
}

.timesheet-page.tito-timesheet-new .date-range {
  background-color: #EDF2FE;
}

.timesheet-page.tito-timesheet-new .date-range p {
  color: #0F2E4E;
  font-size: 13px;
  line-height: 32px;
}

.timesheet-page.tito-timesheet-new .bullet-point {
  position: relative;
  padding-left: 20px;
}

.timesheet-page.tito-timesheet-new .bullet-point:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #0089FF;
  border-radius: 4px;
  height: 6px;
  width: 12px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page.tito-timesheet-new .bullet-point.break:before {
  background-color: #FFBC49;
}

.timesheet-page.tito-timesheet-new .bullet-point.rest:before {
  background-color: #FFB6F6;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper thead th {
  border-top: 1px solid #EBF0F5;
  background-color: #FAFCFF;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  color: #5B6874;
  padding-left: 16px;
  padding-right: 16px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper thead th.time-in {
  width: 8%;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper thead th.time-out {
  width: 17%;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper thead th.arrow-header {
  padding-left: 0;
  padding-right: 0;
  width: 2%;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody {
  border-bottom: 1px solid #E3E9F0;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td {
  font-size: 13px;
  color: #0F2E4E;
  padding-left: 16px;
  padding-right: 16px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box {
  background-color: #FAFCFF;
  border-left: 5px solid #1C60D0;
  position: relative;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box p {
  color: #5B6874;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box .detail-view-day>div {
  padding-right: 60px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box .shadow {
  box-shadow: inset 0px 3px 3px #00000007;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box .shadow::before {
  position: absolute;
  top: -10px;
  left: 2%;
  z-index: 10;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  border-bottom: 17px solid #FAFCFF;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  width: 1px;
  height: 1px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box .box {
  padding-left: 16px;
  margin-right: 59px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box .box:before {
  position: absolute;
  top: 5px;
  left: 0;
  background-color: #01DF1A;
  width: 8px;
  height: 8px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  border-radius: 50%;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.detail-information-box .box.blue:before {
  background-color: #5B96FF;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td icon.disabled {
  opacity: .2;
  pointer-events: none;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr td.arrow-header {
  padding-left: 0;
  padding-right: 0;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr.holiday td {
  background-color: #FFC5C515;
  color: #D3270F;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tbody tr.off td {
  background-color: #FAFCFF;
  color: #5B6874;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart {
  position: relative;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .hour {
  border-left: 1px solid #dde3e9;
  height: 10px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background-color: #0089FF;
  z-index: 2;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator.break {
  z-index: 3;
  background-color: #FFBC49;
  border-radius: 0;
  pointer-events: none;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator.rest {
  z-index: 3;
  background-color: #FFB6F6;
  border-radius: 0;
  pointer-events: none;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip {
  position: absolute;
  left: 104%;
  top: 0;
  background-color: #ffffff;
  border: 1px solid #CFD8E1;
  border-radius: 4px;
  z-index: 100;
  min-width: 206px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #0000000F;
  display: none;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip h6 {
  background-color: #FAFCFF;
  line-height: 29px;
  color: #5B6874;
  font-size: 12px;
  font-weight: 500;
  padding: 0 12px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip p {
  padding: 8px 12px;
  line-height: 24px;
  position: relative;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip p.break,
.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip p.rest {
  padding-left: 30px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip p.break:before,
.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip p.rest:before {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  background-color: #FFBC49;
  border-radius: 4px;
  height: 6px;
  width: 12px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator .attendance-tooltip p.rest:before {
  background-color: #FFB6F6;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .attendance-chart .indicator:hover .attendance-tooltip {
  display: block;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tfoot tr td {
  padding-left: 16px;
  padding-right: 16px;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper tfoot p {
  color: #000000;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .view-more {
  color: var(--color-assets-primary);
  font-weight: 500;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .view-more icon {
  vertical-align: middle;
}

.timesheet-page.tito-timesheet-new .timesheet-table-wrapper .view-more icon ::ng-deep i {
  vertical-align: middle;
  color: var(--color-assets-primary);
}

.timesheet-page.tito-timesheet-new .total-breakup-table {
  padding: 0 16px;
}

.timesheet-page.tito-timesheet-new .total-breakup-table thead th {
  background-color: #EDF2FE;
  text-align: left;
  height: 42px;
  font-size: 12px;
  color: #5B6874;
  text-transform: uppercase;
}

.timesheet-page.tito-timesheet-new .total-breakup-table thead th:first-child {
  padding-left: 0;
}

.timesheet-page.tito-timesheet-new .total-breakup-table thead th.mark-holiday {
  color: #A3B0C0;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr td {
  height: 42px;
  font-size: 12px;
  color: #3A4550;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr td p {
  color: #3A4550;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr td:first-child {
  padding-left: 0;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr td.mark-holiday {
  color: #84919F;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr.parent-row td {
  color: #001A43;
  font-weight: 500;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr.parent-row td p {
  color: #001A43;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr.parent-row td.mark-holiday {
  color: #84919F;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr.child-row {
  display: none;
}

.timesheet-page.tito-timesheet-new .total-breakup-table tbody tr.child-row.active {
  display: table-row;
}

.timesheet-page.tito-timesheet-new .add-new-modal {
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
  max-width: 776px;
  width: 90%;
  border-radius: 4px;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header {
  position: relative;
  background-color: #EDF2FE;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header .close-modal {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
  cursor: pointer;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul {
  list-style-type: none;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li {
  display: inline-block;
  text-align: center;
  margin-right: 38px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li p {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 4px;
  color: #001A43;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 3px;
  line-height: 21px;
  cursor: pointer;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li p span {
  display: block;
  line-height: 14px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li .total {
  font-size: 12px;
  position: relative;
  color: #5B6874;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li .total::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  width: 6px;
  height: 6px;
  background-color: #AFB9C5;
  border-radius: 50%;
  content: '';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li.filled .total {
  color: #0055FF;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li.filled .total::before {
  background-color: #0055FF;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-new-header ul li.active p {
  background-color: #4C7FF0;
  color: #ffffff;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body {
  overflow: auto;
  max-height: calc(100vh - 220px);
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .time-entry-loop .form-group:first-child,
.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .time-entry-loop .form-group:nth-child(2) {
  max-width: 21%;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .time-entry-loop .form-group:nth-child(4) {
  max-width: 12%;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper .form-control {
  padding-left: 30px;
  padding-right: 40px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  left: 8px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper .time-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  right: 8px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper .show-date {
  background-color: #ADB6FE;
  color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  left: 0;
  right: 0;
  text-align: center;
  display: none;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper .show-date a.prev-day {
  position: absolute;
  top: 0;
  left: 10px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper .show-date a.next-day {
  position: absolute;
  top: 0;
  right: 10px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper .show-date icon i {
  color: #ffffff;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper.date-active.time-in:hover .form-control {
  border-color: #ADB6FE;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper.date-active.time-in:hover .show-date {
  display: block;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper.date-active.time-out:hover .form-control {
  border-color: #79DD5B;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .input-wrapper.date-active.time-out:hover .show-date {
  display: block;
  background-color: #79DD5B;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .add-btn {
  background-color: #EDF2FE;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-right: 8px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .add-btn i {
  color: #1C60D0;
  line-height: 34px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .remove-btn {
  background-color: #FFC5C5;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-right: 8px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .remove-btn i {
  color: #FF0000;
  line-height: 34px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .form-control .min-height {
  min-height: 100px;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-body .form-loop .form-group-row:first-child .remove-btn {
  display: none;
}

.timesheet-page.tito-timesheet-new .add-new-modal .add-time-footer {
  border-top: 1px solid var(--color-border-secondary);
}

.timesheet-page.tito-timesheet-new .add-new-modal.active {
  visibility: visible;
  opacity: 1;
}

.timesheet-page.tito-timesheet-new .modal-overlay {
  background: #1f2031;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
}

.timesheet-page.tito-timesheet-new .modal-overlay.active {
  opacity: 0.85;
  visibility: visible;
}

.pages-sub-nav {
  border-bottom: 1px solid var(--color-border-secondary);
}

.pages-sub-nav ul {
  list-style-type: none;
}

.pages-sub-nav ul li {
  display: inline-block;
  line-height: 50px;
  margin-left: 24px;
  position: relative;
}

.pages-sub-nav ul li:after {
  position: absolute;
  bottom: -1px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  border-bottom: 2px solid transparent;
}

.pages-sub-nav ul li icon i {
  font-size: 18px;
  color: #000000;
}

.pages-sub-nav ul li.active {
  color: var(--color-assets-primary);
}

.pages-sub-nav ul li.active icon i {
  color: var(--color-assets-primary);
}

.pages-sub-nav ul li.active:after {
  border-bottom-color: var(--color-assets-primary);
}

.responsive-timesheet-view,
.hide-day-col-mobile,
.project-mobile-view,
.add-project-mobile {
  display: none;
}

.decimal_calculation {
  position: relative;
}

.decimal_calculation p {
  position: absolute;
  left: 20px;
  width: calc(100% - 40px);
  background-color: #ffbc0b;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  font-size: 10px;
  transition: .2s ease-in-out;
  z-index: 8;
  visibility: hidden;
  opacity: 0;
}

.decimal_calculation input:hover+p {
  opacity: 1;
  visibility: visible;
}

.decimal_calculation input:focus+p {
  opacity: 0;
  visibility: hidden;
}

.no-resize {
  resize: none;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #CCBCFF;
  font-size: 10px;
  border: 2px solid #FFFFFF;
  position: absolute;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:nth-child(1) {
  width: 22px;
  height: 22px;
  left: 0;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:nth-child(2) {
  left: 13px;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:nth-child(3) {
  left: 25px;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:nth-child(4) {
  left: 37px;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:nth-child(5) {
  left: 49px;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:nth-child(6) {
  left: 61px;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:nth-child(7) {
  left: 73px;
  color: #4C7FF0;
  background-color: #EBF0F5 !important;
}

.manager-images-list-multi-approvl .manager-avatar-list-multi-approvl:hover {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.more-name-list-multi-approval {
  position: absolute;
  top: 13px;
  left: 0;
  width: 200px;
  background-color: #ffffff;
  border: 1px solid #DDEAFF;
  visibility: hidden;
  z-index: 8;
}

.more-name-list-multi-approval h6 {
  background-color: #FBFAFF;
  line-height: 24px;
  padding: 0 8px;
  font-size: 10px;
  color: #002626;
  font-weight: 400;
}

.more-name-list-multi-approval ul {
  list-style-type: none;
  max-height: 120px;
  overflow: auto;
  /* Handle */
  /* Handle on hover */
}

.more-name-list-multi-approval ul li {
  margin-top: 12px;
  padding: 0 12px;
}

.more-name-list-multi-approval ul li div {
  font-size: 11px;
  line-height: 15px;
  color: #2F3943;
}

.more-name-list-multi-approval ul li div.name-avatar {
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  background-color: #E3E6E8;
  text-align: center;
  font-size: 7px;
  line-height: 15px;
  color: #002626;
  margin-right: 8px;
}

.more-name-list-multi-approval ul::-webkit-scrollbar {
  width: 4px;
}

.more-name-list-multi-approval ul::-webkit-scrollbar-track {
  background: transparent;
}

.more-name-list-multi-approval ul::-webkit-scrollbar-thumb {
  background: #0089FF;
}

.more-name-list-multi-approval ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.more-name-list-multi-approval ul:last-child {
  margin-bottom: 12px;
}

.more-name-list-multi-approval.relative-item {
  position: absolute;
  left: 75%;
  top: 10px;
}

.more-name-list-multi-approval.active {
  visibility: visible;
}

.more-name-list-multi-approval.active.flip {
  transform: translateY(-118%) !important;
}

.billing-details-widget h4 {
  padding-right: 70px;
}

.billing-details-widget .value-breakup {
  position: relative;
  padding-left: 48px;
  padding-right: 48px;
}

.billing-details-widget .value-breakup::before {
  background-color: #E3E9F0;
  width: 1px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.billing-details-widget .value-breakup p {
  color: #0F2E4E;
}

.billing-details-widget .value-breakup p.small {
  color: #5B6874;
}

.billing-details-widget table thead tr th {
  border-top: 1px solid #E3E9F0;
  border-bottom: 1px solid #E3E9F0;
  border-left: 1px solid #E3E9F0;
  font-size: 12px;
  white-space: nowrap;
  height: 42px;
  line-height: 42px;
  padding: 0 16px;
  text-align: left;
}

.billing-details-widget table thead tr th:first-child {
  border-left: 0;
}

.billing-details-widget table thead tr th.gray-bg {
  background-color: #FAFCFF;
}

.billing-details-widget table tbody tr td {
  border-left: 1px solid #E3E9F0;
  font-size: 12px;
  font-weight: 500;
  color: #001A43;
  white-space: nowrap;
  height: 35px;
  line-height: 35px;
  padding: 0 16px;
  vertical-align: top;
}

.billing-details-widget table tbody tr td i {
  font-size: 14px;
  color: #0F2E4E;
  padding-right: 8px;
  cursor: pointer;
}

.billing-details-widget table tbody tr td i.info-icon {
  color: #84919F;
  padding-right: 0;
  padding-left: 12px;
}

.billing-details-widget table tbody tr td:first-child {
  border-left: 0;
}

.billing-details-widget table tbody tr td.gray-bg {
  background-color: #FAFCFF;
}

.billing-details-widget table tbody tr td span.left-space {
  padding-left: 24px;
}

.billing-details-widget table tbody tr td span.left-space-double {
  padding-left: 48px;
}

.billing-details-widget table tbody tr td span.no-child {
  padding-left: 22px;
}

.billing-details-widget table tbody tr td table tr td {
  font-weight: 400;
  color: #5B6874;
}

.billing-details-widget table tbody tr td table tr td i {
  color: #5B6874;
  cursor: auto;
}

.billing-details-widget table tbody.detailed-body-item tr td {
  font-weight: 400;
  color: #5B6874;
}

.billing-details-widget table tbody.detailed-body-item tr td i {
  color: #5B6874;
  cursor: auto;
}

@media screen and (max-width: 1400px) {
  .small.total-heading {
    font-size: 11px;
  }

  .timesheet-page .sheet-wrapper .day-col .col-item.padding-responsive {
    padding-left: 12px;
    padding-right: 12px;
  }

  .timesheet-page .sheet-wrapper .day-col .col-item.padding-responsive icon i {
    font-size: 17px !important;
  }
}

.breadcrumb-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #ffffff;
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
  z-index: 10;
  min-width: 366px;
}

.breadcrumb-dropdown ul {
  list-style-type: none;
}

.breadcrumb-dropdown ul li {
  border-top: 1px solid var(--color-border-secondary);
}

.breadcrumb-dropdown ul li a {
  font-weight: 500;
  transition: 0.2s ease-in-out;
  padding: 16px;
  display: block;
}

.breadcrumb-dropdown ul li a span {
  font-weight: 400;
  margin-top: 4px;
}

.breadcrumb-dropdown ul li a:hover {
  background-color: #F7FAFC;
}

.breadcrumb-dropdown ul li:first-child {
  border: 0;
}

:root {
  --color-assets-primary: #4c7ff0;
  --color-assets-primary-tint: #7a89fd;
  --color-assets-primary-shade: #4e61ef;
  --color-assets-secondary: #2b2d42;
  --color-assets-secondary-tint: #404255;
  --color-assets-secondary-shade: #26283a;
  --color-assets-light: #f6fafd;
  --navigation-text: #515996;
  --navigation-text-hover: #515996;
  --navigation-icon: #4b91bf;
  --navigation-bg: #fff;
  --navigation-border: #d7e0e7;
  --navigation-submenu-text: #000000;
  --navigation-switch-color: #ffffff;
  --navigation-sidebar-shadow: #0000000a;
  --navigation-submenu-hover: #f8f9fa;
  --navigation-program-panel-bg: #5e71fe;
  --navigation-program-panel-shadow: #222f96;
  --navigation-program-panel-arrow: #222f96;
  --navigation-settings-user-name: #222f96;
  --navigation-border-color: #e9ecef;
  --color-body-txt-default: #2b2d42;
  --color-body-txt-secondary: #5f6d89;
  --color-border-primary: #e9ecef;
  --color-border-secondary: #ebeef3;
  --header-bg-color: #4c7ff0;
  --header-icon-color: #ffffff;
  --header-icon-hover-color: #3f72e3;
  --responsive-detail-header: #4c7ff0;
  --responsive-detail-header-color: #ffffff;
}

.sidebar-view-wrapper.interview-detail-view .interview-info>div {
  flex-basis: 50%;
  margin-bottom: 8px;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .interview-type {
  margin-top: 4px;
  background-color: #5E71FE2F;
  border-radius: 11px;
  padding: 2px 12px;
  color: #5E71FE;
  font-size: 12px;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .interview-type icon {
  vertical-align: middle;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .img-item {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .attandees {
  list-style-type: none;
  margin-top: 4px;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .attandees li {
  display: inline-block;
  position: relative;
  line-height: 24px;
  background-color: #776de0;
  border: 1px solid #ffffff;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  width: 24px;
  height: 24px;
  font-size: 12px;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .attandees li:nth-child(2) {
  left: -9px;
  z-index: 2;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .attandees li:nth-child(3) {
  left: -15px;
  z-index: 3;
}

.sidebar-view-wrapper.interview-detail-view .interview-info .other-attandees {
  vertical-align: top;
  margin-top: 4px;
}

.sidebar-view-wrapper.interview-detail-view .instruction {
  border: 1px solid #FCE6FF;
  border-radius: 4px;
}

.sidebar-view-wrapper.interview-detail-view .instruction .trigger {
  cursor: pointer;
}

.sidebar-view-wrapper.interview-detail-view .instruction .trigger p {
  flex-basis: 93%;
  color: var(--color-assets-primary);
}

.sidebar-view-wrapper.interview-detail-view h4 {
  color: #002626;
  font-weight: 500;
}

.sidebar-view-wrapper.interview-detail-view h4 icon {
  vertical-align: middle;
}

.sidebar-view-wrapper.interview-detail-view h4 icon ::ng-deep i {
  line-height: 24px;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-header .first {
  flex-basis: 40%;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-header .second {
  flex-basis: 40%;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-header .third {
  flex-basis: 20%;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body {
  border: 1px solid #ebeef3;
  border-radius: 4px;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body .date-thumb {
  background-color: #776de0;
  border-radius: 4px;
  width: 30px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  margin-right: 16px;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body .date {
  flex-basis: 31%;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body .time {
  flex-basis: 44%;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body .status-icon {
  flex-basis: 20px;
  height: 20px;
  border: 2px solid #eb445a;
  text-align: center;
  border-radius: 50%;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body .status-icon icon ::ng-deep i {
  color: #eb445a;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body.active {
  border-color: #93FFBE;
  background-color: #DFF8E9;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body.active .date-thumb {
  background-color: #2DD36F;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body.active .status-icon {
  border-color: #2DD36F;
  background-color: #2DD36F;
}

.sidebar-view-wrapper.interview-detail-view .Scheduled-body.active .status-icon icon ::ng-deep i {
  color: #ffffff;
}

app-hourly-print-component {
  display: none;
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-block {
    display: block !important;
  }

  app-hourly-print-component {
    display: block;
  }

  @page {
    size: A4 portrait;
    margin-left: -32px;
    margin-right: 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    page-break-before: avoid;
    border: 1px solid gray;
  }

  body {
    margin: 0;
    color: #000;
    background-color: #fff;
    font-size: 10px !important;
  }
}

.accordion-item .accordion-trigger {
  background-color: #FAFCFF;
  padding: 12px 24px;
  cursor: pointer;
  color: #222428;
  transition: 0.2s ease-in-out;
  margin: 4px;
}

.accordion-item .accordion-trigger:hover {
  background-color: #f2f7ff;
  color: #3c62af;
}

.accordion-item .accordion-trigger.active {
  background-color: #f2f7ff;
  color: #3c62af;
  font-weight: 500;
}

.accordion-item .accordion-content {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  margin-left: 4px;
  margin-right: 4px;
}

.accordion-item .accordion-content .notification-type .item-name {
  font-size: 13px;
  color: #776de0;
}

.accordion-item .accordion-content .notification-type .tag {
  margin-left: 8px;
  padding: 0 8px;
  line-height: 22px;
  font-size: 12px;
  border-radius: 11px;
  text-transform: capitalize;
}

.accordion-item .accordion-content .notification-type .tag.information,
.accordion-item .accordion-content .notification-type .tag.Information {
  background-color: #4C7FF01D;
  color: #4C7FF0;
}

.accordion-item .accordion-content .notification-type .tag.action,
.accordion-item .accordion-content .notification-type .tag.Action {
  background-color: #6ABA3F21;
  color: #5DAF31;
}

.accordion-item .accordion-content .notification-type .tag.reminder,
.accordion-item .accordion-content .notification-type .tag.Reminder {
  background-color: #F6AB2B2E;
  color: #E89810;
}

.accordion-item .accordion-content .toggle-panel {
  width: auto;
}

.accordion-item .accordion-content .toggle-panel .toggle-panel-inner {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.accordion-item .accordion-content.active {
  visibility: visible;
  max-height: inherit;
  opacity: 1;
}

.alert_message {
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  text-align: left;
}

.alert_message icon {
  margin-top: 0px;
}

.alert_message .medium-font {
  font-size: 13px;
}

.alert_message.alert_pending {
  border-left: 5px solid #ed8936;
  color: #ed8936;
  margin-top: -14px;
  margin-bottom: 7px;
  background: #feebc8;
}

.alert_message.alert_error {
  border-left: 5px solid #f72951;
  color: #fd556b;
  margin-top: -14px;
  margin-bottom: 7px;
  background: #ffc8cf;
}

.alert_message.info {
  background-color: #EDF2FE;
}

.alert_message.info p {
  color: #00338E;
}

.alert_message.info icon i {
  color: #4C7FF0;
}

.alert_message.info .alert-message-text {
  flex-grow: 1;
}

.alert_message.info .close-icon {
  top: 20px;
  right: 20px;
}

.alert_message.info .close-icon i {
  font-size: 14px;
  color: #4C7FF0;
}

.workflow-page {
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
}

.workflow-page nav {
  overflow: hidden;
  height: 55px;
}

.workflow-page nav .nav-item {
  line-height: 55px;
  color: #84919F;
  font-size: 13px;
}

.workflow-page nav .nav-item:before {
  background-color: #ffffff;
  width: 30px;
  height: 55px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  position: absolute;
  top: 0;
  left: -27px;
  z-index: 1;
}

.workflow-page nav .nav-item:after {
  position: absolute;
  right: 12px;
  top: 0;
  width: 55px;
  height: 55px;
  border-right: 1px solid var(--color-border-secondary);
  border-bottom: 1px solid var(--color-border-secondary);
  content: '.';
  text-indent: -9999px;
  display: block;
  overflow: hidden;
  transform: rotate(-45deg);
  background-color: #ffffff;
  z-index: 2;
}

.workflow-page nav .nav-item span {
  position: relative;
  z-index: 4;
  display: block;
  margin-right: 29px;
  padding-left: 50px;
}

.workflow-page nav .nav-item span:before {
  position: absolute;
  top: 50%;
  left: 26px;
  transform: translateY(-50%);
  font-family: 'Material Icons';
  font-size: 18px;
  display: block;
  visibility: visible;
}

.workflow-page nav .nav-item.active:before {
  background-color: #FAFCFF;
}

.workflow-page nav .nav-item.active span {
  background-color: #FAFCFF;
}

.workflow-page nav .nav-item.active:after {
  background-color: #FAFCFF;
}

.workflow-page nav .nav-item.lock span:before {
  content: 'lock';
}

.workflow-page nav .nav-item.done span {
  color: #009514;
}

.workflow-page nav .nav-item.done span:before {
  color: #009514;
  content: 'check_circle';
}

.workflow-page nav .nav-item.rejected span {
  color: #FF2929;
}

.workflow-page nav .nav-item.rejected span:before {
  font-family: 'Material Icons Outlined';
  color: #FF2929;
  content: 'person_off';
}

.workflow-page nav .nav-item.pending span {
  color: #D8834B;
}

.workflow-page nav .nav-item.pending span:before {
  font-family: 'Material Icons Outlined';
  color: #D8834B;
  content: 'hourglass_top';
}

.workflow-page .workflow-accordion-title {
  border-top: 1px solid var(--color-border-secondary);
  padding: 16px 16px 16px 24px;
}

.workflow-page .workflow-accordion-title .item-label icon i {
  font-size: 20px;
}

.workflow-page .workflow-accordion-title .item-label p span {
  font-weight: 500;
}

.workflow-page .workflow-accordion-title .status {
  font-size: 13px;
  color: #84919F;
  padding-left: 32px;
}

.workflow-page .workflow-accordion-title .status:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: 'cancel';
  font-family: 'Material Icons';
  font-size: 16px;
  display: block;
  visibility: visible;
  color: #84919F;
}

.workflow-page .workflow-accordion-title .status.completed {
  color: #009514;
}

.workflow-page .workflow-accordion-title .status.completed:before {
  content: 'check_circle';
  color: #009514;
}

.workflow-page .workflow-accordion-title .status.pending {
  color: #D8834B;
}

.workflow-page .workflow-accordion-title .status.pending:before {
  content: 'hourglass_top';
  color: #D8834B;
}

.workflow-page .workflow-accordion-title .status.rejected {
  color: #FF0000;
}

.workflow-page .workflow-accordion-title .status.rejected:before {
  font-family: 'Material Icons outlined';
  content: 'person_off';
  color: #FF0000;
}

.workflow-page .workflow-accordion-title .status.not-started {
  color: #84919F;
}

.workflow-page .workflow-accordion-title .status.not-started:before {
  content: 'lock';
  color: #84919F;
}

.workflow-page .workflow-accordion-title.active {
  background-color: #FAFCFF;
}

.workflow-page .workflow-accordion-content table tbody tr td {
  border: 1px solid var(--color-border-secondary);
}

.workflow-page .workflow-accordion-content table tbody tr td:nth-child(2) {
  position: relative;
}

.workflow-page .workflow-accordion-content table tbody tr td:nth-child(2) .drag-handle {
  width: 4px;
  height: 100%;
  cursor: w-resize;
  position: absolute;
  left: -1px;
  top: 0;
  z-index: 10;
  display: block;
}

.workflow-page .workflow-accordion-content table figure {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 22px;
}

.workflow-page .workflow-accordion-content table figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.workflow-page .workflow-accordion-content table figure .initials {
  color: #ffffff;
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
}

.workflow-page .workflow-accordion-content table .users {
  padding: 24px;
  align-items: center;
}

.workflow-page .workflow-accordion-content table .approval-ui {
  position: relative;
  flex-basis: 70%;
  padding: 24px;
}

.workflow-page .workflow-accordion-content table .approval-ui .reason {
  border: 1px solid var(--color-border-secondary);
  padding: 8px;
  margin-top: 8px;
}

.workflow-page .workflow-accordion-content table .replace-btn {
  color: var(--color-assets-primary);
  text-decoration: underline;
}

.workflow-page .workflow-accordion-content table .approval {
  position: relative;
  font-weight: 500;
  margin-right: 4px;
  padding-left: 24px;
}

.workflow-page .workflow-accordion-content table .approval:before {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Material Icons';
  font-size: 18px;
  display: block;
  visibility: visible;
}

.workflow-page .workflow-accordion-content table .approval.approved {
  color: #009514;
}

.workflow-page .workflow-accordion-content table .approval.approved:before {
  content: 'how_to_reg';
  color: #009514;
}

.workflow-page .workflow-accordion-content table .approval.rejected {
  color: #FF0000;
}

.workflow-page .workflow-accordion-content table .approval.rejected:before {
  font-family: 'Material Icons outlined';
  content: 'person_off';
  color: #FF0000;
}

.workflow-page .workflow-accordion-content table .approval.not-started {
  color: #84919F;
}

.workflow-page .workflow-accordion-content table .approval.not-started:before {
  content: 'lock';
  color: #84919F;
}

.workflow-page .workflow-accordion-content table .approval.cancelled {
  color: #84919F;
}

.workflow-page .workflow-accordion-content table .approval.cancelled:before {
  content: 'cancel';
  color: #84919F;
}

.workflow-page .workflow-accordion-content table .approval.pending {
  color: #BC5A1E;
}

.workflow-page .workflow-accordion-content table .approval.pending:before {
  content: 'hourglass_top';
  color: #BC5A1E;
}

.workflow-page .workflow-accordion-content.active {
  display: block;
}

.workflow-page .workflow-accordion-content .replace-ui {
  opacity: 0;
  visibility: hidden;
  background-color: #ffffff;
  top: 20px;
  right: 0;
  width: 396px;
  transition: 0.2s ease-in-out;
  border: 1px solid #AFB9C5;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
}

.workflow-page .workflow-accordion-content .replace-ui .replace-info {
  background-color: #DDEAFF;
  border-bottom: 1px solid #CFD8E1;
}

.workflow-page .workflow-accordion-content .replaced-icon:hover~.replace-ui {
  opacity: 1;
  visibility: visible;
  max-height: 1000px;
}

.workflow-page .min-width-div {
  min-width: 32%;
}

.no-workflow {
  height: calc(100vh - 375px);
}

.analytics-wrapper {
  /* padding-top: 50px; */
  width: 100%;
  background-color: #F7FAFC;
  border-bottom: 1px solid var(--color-border-secondary);

  &.analytics-wrapper-white {
    background-color: #fff;
  }
}

.analytics-wrapper ul {
  list-style-type: none;
}

.analytics-wrapper ul.report-template-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.analytics-wrapper ul.report-template-list li {
  width: 25%;
 
}
.analytics-wrapper ul.report-template-list li .list-content
{
  border: 1px solid var(--color-border-secondary);
  border-radius: 8px;
  margin-bottom: 20px;
  margin-right: 20px;
  overflow: hidden;
}

.analytics-wrapper ul.report-template-list li.dashed-border {
  border: 1px dashed #AAC8FF !important;
  background-color: #F6F8FC;
}

.analytics-wrapper ul.report-template-list li.dashed-border i {
  font-size: 53px;
  color: var(--color-assets-primary);
}

.analytics-wrapper ul.report-template-list li.dashed-border p {
  font-size: 14px;
  color: var(--color-assets-primary);
}

.analytics-wrapper ul.report-template-list li figure {
  background-color: #F6F8FC;
  width: 100%;
  height: 189px;
  padding: 24px;
  text-align: center;
  position: relative;
}
.analytics-wrapper ul.report-template-list li figure svg
{
  width: 97%;
  height: auto;
}
.analytics-wrapper ul.report-template-list li:first-child {}

.analytics-wrapper ul.report-template-list li figure button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background-color: #ffffff;
  border: 0;
  padding: 6px;
  cursor: pointer;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1607843137)
}

.analytics-wrapper ul.report-template-list li:hover .list-content
{
  border-color: #4c7ff1;
    box-shadow: 0px 0px 0px 5px #4c7ff12e;
}

.analytics-wrapper ul.report-template-list li figure img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.analytics-wrapper ul.report-template-list li .list-caption {
  padding: 12px;
}

.analytics-wrapper ul.report-template-list li .list-caption p.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics-wrapper ul.report-template-list li .list-caption p.short-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 50px;
}

.analytics-wrapper ul.report-template-list li .right-buttons {
  padding: 12px 0;
  border-top: 1px solid var(--color-border-secondary);
  margin-left: 12px;
  margin-right: 12px;
}

.analytics-wrapper ul.report-template-list li .template-btn {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: var(--color-assets-primary);
  font-weight: 500;
  cursor: pointer;
}

.analytics-wrapper p.report-list-title {
  color: #001A43;
}

.analytics-wrapper .landing-page-wrapper {
  height: calc(100vh - 51px);
}

.analytics-wrapper .landing-page-wrapper .landing-page {
  width: 835px;
}

.analytics-wrapper .landing-page-wrapper .landing-page .landing-page-item {
  background-color: #ffffff;
  border: 1px solid var(--color-border-secondary);
  border-radius: 8px;
  
}

.analytics-wrapper .landing-page-wrapper .landing-page .landing-page-item:hover {
  box-shadow: 0px 0px 0px 4px #4c7ff026;
  border: 1px solid #4c7ff0;
}


.analytics-wrapper .landing-page-wrapper .landing-page i {
  font-size: 20px;
}

.analytics-wrapper .folder-list {
  height: calc(100vh - 90px);
  overflow: auto;
}

.analytics-wrapper .folder-list ul {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
}

.analytics-wrapper .folder-list ul li {
  width: 170px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #e4e5e6;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  padding-bottom: 12px !important;
}

.analytics-wrapper .folder-list ul li figure {
  /*border-bottom: 1px solid var(--color-border-secondary);
  background-color: #EDF2FE;*/
  padding: 24px 24px 10px 24px;
}
.analytics-wrapper .folder-list ul li figure span
{
  font-size: 40px;
  color: #4c7ff1;
}
.analytics-wrapper .folder-list ul li figure span.empty
{
  color: #cfcaca;
}
.analytics-wrapper .folder-list ul li .caption {
  padding: 4px 12px;
}

.analytics-wrapper .folder-list ul li.empty figure {
  background-color: #ffffff;
}

.analytics-wrapper .folder-list ul li:hover {
  /*box-shadow: 0px 7px 13px #0000001D;*/
  background: #f9f7ff !important;
  border-color: #4c7ff161;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 0;
  background: #f9f7ff;
  border-top: 1px solid #e9ecef !important;
}
.analytics-wrapper .sub-header {
  border-bottom: 1px solid #f9f7ff;
}

.analytics-wrapper {
  .main-wrapper {
    background-color: #ffffff;
    width: 100%;
    float: left;
  }
  &.add-analytics-wrapper{
    .main-wrapper {
      background-color: #ffffff;
      width: 100%;
      float: inherit;
      display: inline-block;
    }
  }
}

.analytics-wrapper .main-wrapper .side-filter-content {
  width: 242px;
  float: left;
}

.analytics-wrapper .main-wrapper .side-filter-content .filter-list {
  color: #000000;
  font-size: 12px;
}

.analytics-wrapper .main-wrapper .side-filter-content .filter-list i {
  font-size: 20px;
}

.analytics-wrapper .main-wrapper .side-filter-content .checkbox-item {
  list-style-type: none;
}

.analytics-wrapper .main-wrapper .side-filter-content .checkbox-item .c-input-checkbox .cb-ui {
  border-radius: 2px;
}

.analytics-wrapper .main-wrapper .analytics-section-content {
  /*width: calc(100% - 242px); */
  float: right;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.analytics-wrapper .main-wrapper .analytics-section-content .report-search .search-input {
  min-width: 286px;
  height: 40px;
  border: 1px solid #CFD8E1;
  padding-left: 40px;
  border-radius: 4px;
  color: #2F3943;
  outline: none;
}

.analytics-wrapper .main-wrapper .analytics-section-content .report-search i {
  position: absolute;
  left: 12px;
  top: 9px;
  font-size: 20px;
  color: #2F3943;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list {
  overflow: auto;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .report-template-list li .list-item .rectangle-blue-bg {
  width: 40px;
  height: 40px;
  background-color: #EDF2FE;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .report-template-list li .list-item .right-buttons i {
  font-size: 30px;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .report-template-list li .list-item .right-buttons button {
  font-size: 13px;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li {
  min-width: 298px;
  max-width: 298px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  overflow: hidden;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li.dashed-border {
  background-color: #FAFCFF;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li .add-blank-template {
  color: var(--color-assets-primary);
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li .add-blank-template i {
  font-size: 53px;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li figure {
  width: 100%;
  height: 189px;
  text-align: center;
  padding: 24px;
  background-color: #F6F8FC;
  position: relative;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li figure.dark-bg {
  background-color: #16113A;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li figure img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li figure button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  background-color: #ffffff;
  border: 0;
  padding: 6px;
  cursor: pointer;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px #00000029;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li .templte-caption {
  border-bottom: 1px solid var(--color-border-secondary);
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li .templte-caption .sub-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li .template-footer button {
  color: var(--color-assets-primary);
  background: transparent;
  border: none;
  font-size: 13px;
}

.analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li .template-footer i {
  font-size: 20px;
}

.analytics-wrapper .main-wrapper .analytics-section-content ::-webkit-scrollbar {
  width: 5px;
}

.analytics-wrapper .main-wrapper .analytics-section-content ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

.analytics-wrapper .main-wrapper .analytics-section-content ::-webkit-scrollbar-thumb {
  background: #a5aeb7;
  border-radius: 5px;
}

.analytics-wrapper .main-wrapper .analytics-section-content ::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.analytics-wrapper .standard-report {
  max-width: 987px;
  margin: auto;
}

.analytics-wrapper .global-report-search {
  width: 100%;
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
}

.analytics-wrapper .global-report-search input {
  border: 0;
  background-color: #ffffff;
  outline: none;
  padding: 12px 50px;
  width: 100%;
}

.analytics-wrapper .global-report-search .search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 10;
}

.analytics-wrapper .global-report-search .filter-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  z-index: 10;
}

.analytics-wrapper .icon-style {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
}

.analytics-wrapper .icon-style i {
  font-size: 14px;
  vertical-align: text-bottom;
}

.analytics-wrapper .icon-style.green {
  background-color: #ACFFB5;
}

.analytics-wrapper .icon-style.green i {
  color: #009514;
}

.analytics-wrapper .icon-style.pink {
  background-color: #FFE2FC;
}

.analytics-wrapper .icon-style.pink i {
  color: #8E007C;
}

.analytics-wrapper .standard-report-list {
  list-style-type: none;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.analytics-wrapper .standard-report-list li p.small
{
  color: #565656;
}
.analytics-wrapper .standard-report-list li {
  padding: 16px 12px 16px 12px;
  flex-basis: 310px;
  max-width: 310px;

  &.link-item:hover {
    cursor: pointer;
    background-color: #f8f9fa;
  }
}

:root {
  --color-assets-primary: #4c7ff0;
  --color-assets-primary-tint: #7a89fd;
  --color-assets-primary-shade: #4e61ef;
  --color-assets-secondary: #2b2d42;
  --color-assets-secondary-tint: #404255;
  --color-assets-secondary-shade: #26283a;
  --color-assets-light: #f6fafd;
  --navigation-text: #515996;
  --navigation-text-hover: #515996;
  --navigation-icon: #4b91bf;
  --navigation-bg: #fff;
  --navigation-border: #d7e0e7;
  --navigation-submenu-text: #000000;
  --navigation-switch-color: #ffffff;
  --navigation-sidebar-shadow: #0000000a;
  --navigation-submenu-hover: #f8f9fa;
  --navigation-program-panel-bg: #5e71fe;
  --navigation-program-panel-shadow: #222f96;
  --navigation-program-panel-arrow: #222f96;
  --navigation-settings-user-name: #222f96;
  --navigation-border-color: #e9ecef;
  --color-body-txt-default: #2b2d42;
  --color-body-txt-secondary: #5f6d89;
  --color-border-primary: #e9ecef;
  --color-border-secondary: #ebeef3;
  --header-bg-color: #4c7ff0;
  --header-icon-color: #ffffff;
  --header-icon-hover-color: #3f72e3;
  --responsive-detail-header: #4c7ff0;
  --responsive-detail-header-color: #ffffff;
}

.ng-tooltip {
  position: absolute;
  max-width: 400px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 4px 8px;
  background: #536476;
  border-radius: 4px;
  z-index: 99999;
  opacity: 0;
  word-wrap: break-word;
  pointer-events: none;
  line-height: 22px;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #536476 transparent transparent transparent;
}

.ng-tooltip.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent #536476 transparent;
}

.ng-tooltip.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent #536476;
}

.ng-tooltip.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent #536476 transparent transparent;
}

.ng-tooltip.ng-tooltip-show {
  opacity: 1;
}

.ng-tooltip.large {
  max-width: 250px;
}

:root {
  --color-assets-primary: #4c7ff0;
  --color-assets-primary-tint: #7a89fd;
  --color-assets-primary-shade: #4e61ef;
  --color-assets-secondary: #2b2d42;
  --color-assets-secondary-tint: #404255;
  --color-assets-secondary-shade: #26283a;
  --color-assets-light: #f6fafd;
  --navigation-text: #515996;
  --navigation-text-hover: #515996;
  --navigation-icon: #4b91bf;
  --navigation-bg: #fff;
  --navigation-border: #d7e0e7;
  --navigation-submenu-text: #000000;
  --navigation-switch-color: #ffffff;
  --navigation-sidebar-shadow: #0000000a;
  --navigation-submenu-hover: #f8f9fa;
  --navigation-program-panel-bg: #5e71fe;
  --navigation-program-panel-shadow: #222f96;
  --navigation-program-panel-arrow: #222f96;
  --navigation-settings-user-name: #222f96;
  --navigation-border-color: #e9ecef;
  --color-body-txt-default: #2b2d42;
  --color-body-txt-secondary: #5f6d89;
  --color-border-primary: #e9ecef;
  --color-border-secondary: #ebeef3;
  --header-bg-color: #4c7ff0;
  --header-icon-color: #ffffff;
  --header-icon-hover-color: #3f72e3;
  --responsive-detail-header: #4c7ff0;
  --responsive-detail-header-color: #ffffff;
}

.page-sub-nav {
  list-style-type: none;
}

.page-sub-nav li {
  float: left;
  list-style: none;
  margin-right: 1px;
}

.page-sub-nav li a {
  line-height: 46px;
  color: #ffffff;
  padding: 0 20px;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  display: block;
}

.page-sub-nav li a:hover {
  background-color: #ffffff;
  color: var(--color-body-txt-secondary);
}

.page-sub-nav li a.active {
  background-color: #ffffff;
  color: var(--color-body-txt-secondary);
}

.page-sub-nav li.hidden {
  display: none;
}

.page-sub-nav li.more {
  position: relative;
}

.page-sub-nav li.more.hidden {
  display: none;
}

.page-sub-nav li.more a {
  color: #ffc409;
}

.page-sub-nav li.more a icon ::ng-deep i {
  vertical-align: middle;
}

.page-sub-nav li.more ul {
  position: absolute;
  top: 46px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background: #ffffff;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 10px 13px #00000021;
  border-radius: 0px 0px 4px 4px;
  z-index: 10;
  width: 176px;
  padding: 10px 0;
}

.page-sub-nav li.more ul li {
  float: none;
}

.page-sub-nav li.more ul li a {
  color: #2b2d42;
}

.page-sub-nav li.more:hover a {
  background-color: #ffffff;
  color: #776de0;
}

.page-sub-nav li.more:hover ul {
  opacity: 1;
  visibility: visible;
}

.page-sub-nav li.more:hover ul li {
  float: none;
}

.page-sub-nav li.more:hover ul li a {
  color: #2b2d42;
}

.page-sub-nav li.more:hover ul li a:hover {
  background-color: #f6fafd;
}

.manager-profile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-container {
  width: 100%;
}

.page-container .content {
  float: right;
  width: calc(100% - 226px);
  position: relative;
  padding-top: 50px;
}

.page-container .content .table-overflow .ps__rail-x {
  left: 284px !important;
}

.page-container .content.config-active {
  float: none;
  width: 100%;
  padding-top: 0;
}

.page-container .content:has(#analytics) {
  padding-top: unset !important;
}

.page-container.mini-sidebar .dropdown-box-wrapper {
  transform: translateX(0);
}

.page-container.mini-sidebar .no-min-height~.dropdown-box-wrapper {
  transform: translateX(0) !important;
}

.page-container.mini-sidebar .logo-mini {
  display: block !important;
}

.page-container.mini-sidebar .content {
  width: calc(100% - 64px);
}

.page-container.mini-sidebar .content .table-overflow .ps__rail-x {
  left: 120px !important;
}

.page-container.mini-sidebar .content.config-active {
  float: none;
  width: 100%;
  padding-top: 0;
}

.page-container.mini-sidebar .current-program {
  width: 63px;
}

.page-container.mini-sidebar .current-program .mini-mode {
  display: inline-block !important;
}

.page-container.mini-sidebar .current-program .expanded-mode {
  display: none;
}

.page-container.mini-sidebar .user-settings {
  width: 63px;
  border-top: 0 !important;
}

.page-container.mini-sidebar .user-settings a {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.page-container.mini-sidebar .user-settings a figure {
  float: none !important;
  margin: 0 auto !important;
}

.page-container.mini-sidebar .user-settings a figure .availability {
  right: -1px !important;
}

.page-container.mini-sidebar .user-settings a .current-user-info {
  display: none;
}

.page-container.mini-sidebar .user-settings a:hover {
  background: transparent !important;
}

.page-container.mini-sidebar .user-settings a:hover figure {
  border-color: #c8f2e3 !important;
}

.page-container.mini-sidebar .user-settings a.active {
  background: transparent !important;
}

.page-container.mini-sidebar .user-settings a.active figure {
  border-color: #c8f2e3 !important;
}

.page-container.mini-sidebar .sidebar {
  width: 64px;
  text-align: center;
}

.page-container.mini-sidebar .sidebar .sidebar-inner {
  width: 63px !important;
}

.page-container.mini-sidebar .sidebar .switch-menu::after {
  content: "menu" !important;
}

.page-container.mini-sidebar .sidebar .main-nav {
  margin-top: 12px !important;
}

.page-container.mini-sidebar .sidebar .main-nav>li {
  margin: 0 !important;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a {
  padding: 8px 12px 8px 12px !important;
  line-height: 16px !important;
  margin-bottom: 8px;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a icon {
  padding: 8px;
  float: none !important;
  display: block !important;
  margin: 0 !important;
  line-height: 22px !important;
  font-size: 21px !important;
  height: 40px !important;
  pointer-events: none;
  border-radius: 4px;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a icon .sidebar-icon {
  line-height: 16px !important;
  font-size: 24px !important;
  vertical-align: middle;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a label {
  font-size: 12px;
  display: none;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a:hover {
  text-decoration: none;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a:hover icon {
  background-color: var(--color-assets-primary);
}

.page-container.mini-sidebar .sidebar .main-nav>li>a:hover icon i {
  color: #ffffff !important;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a.nav-item:hover {
  text-decoration: none;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a.nav-item:hover:before {
  display: none !important;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a.active,
.page-container.mini-sidebar .sidebar .main-nav>li>a.active-perent-menu {
  text-decoration: none;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a.active icon,
.page-container.mini-sidebar .sidebar .main-nav>li>a.active-perent-menu icon {
  background-color: var(--color-assets-primary);
}

.page-container.mini-sidebar .sidebar .main-nav>li>a.active icon i,
.page-container.mini-sidebar .sidebar .main-nav>li>a.active-perent-menu icon i {
  color: #ffffff !important;
}

.page-container.mini-sidebar .sidebar .main-nav>li>a:before {
  display: none;
}

.page-container.mini-sidebar .sidebar .main-nav>li:last-child {
  border-bottom: 0px !important;
}

.page-container.mini-sidebar .sidebar:last-child {
  border-bottom: 0px !important;
}

.page-container.mini-sidebar .sidenav {
  left: 64px !important;
}

.page-container.mini-sidebar .sidenav.sidenav-active {
  left: 64px !important;
  text-align: left !important;
}

.page-container.mini-sidebar .header-fixed {
  left: 64px;
}

.page-container.mini-sidebar .subheader-active .content-area-topHead {
  left: 64px;
}

.page-container.mini-sidebar .fixed-header {
  left: 64px;
}

.page-container.mini-sidebar .total-result-box {
  left: 64px !important;
}

.page-container.mini-sidebar .generic-list-page .c-listing-header,
.page-container.mini-sidebar .fee-settings .c-listing-header {
  left: 63px;
  width: calc(100% - 63px);
}

.page-container.mini-sidebar .generic-list-page .c-listing-footer,
.page-container.mini-sidebar .fee-settings .c-listing-footer {
  left: 63px;
  width: calc(100% - 63px);
}

.page-container.mini-sidebar .generic-list-page .c-listing-table-wrapper perfect-scrollbar>.ps.ps--active-x>.ps__rail-x,
.page-container.mini-sidebar .fee-settings .c-listing-table-wrapper perfect-scrollbar>.ps.ps--active-x>.ps__rail-x {
  left: 63px !important;
}

.loader {
  border: none;
  border-top: 2px solid #e9e9e9;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dotloader,
.dotloader:before,
.dotloader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.dotloader {
  color: #5050a2;
  font-size: 10px;
  margin: 100px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.dotloader:before,
.dotloader:after {
  content: '';
  position: absolute;
  top: 0;
}

.dotloader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.dotloader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.notification-panel {
  position: fixed;
  top: 0;
  background-color: #ffffff;
  right: 0;
  width: 90%;
  max-width: 1046px;
  box-shadow: -8px 0px 11px #00000008;
  height: 100vh;
  transition: 0.2s ease-in-out;
  transform: translateX(1046px);
  opacity: 0;
  z-index: 103;
}

.notification-panel.active {
  transform: translateX(0);
  opacity: 1;
}

.notification-panel.small {
  max-width: 305px;
}

.backdrop-Overlay {
  background: #100c0ca8;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  left: 0px;
  top: 0px;
}

.choose-role .ng-select-container {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select-tab .ng-select-container {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 93%;
}

.program-manager-table .icon-close {
  color: var(--color-body-txt-default);
  cursor: pointer;
  display: flex;
}

.toggle-panel-inner .icon-toggle {
  display: flex;
  cursor: pointer;
}

.toggle-panel-inner.if-active {
  color: #2b2d42;
}

.toggle-panel-inner.if-active .icon-toggle {
  color: var(--color-assets-primary);
}

.toggle-panel-inner.if-inactive {
  color: #d7e0e7;
}

.toggle-panel-inner.if-inactive .icon-toggle {
  color: #7f92a3;
}

.toggle-panel-inner .toggle-switch {
  background: #d7e0e7;
  width: 27px;
  height: 16px;
  border-radius: 30px;
  position: relative;
  transition: ease-in-out .2s;
  cursor: pointer;
}

.toggle-panel-inner .toggle-switch:before {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffffff;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  transition: ease-in-out .2s;
}

.toggle-panel-inner .toggle-switch.toggle-off:before {
  transform: translateX(0);
}

.toggle-panel-inner .toggle-switch.toggle-on {
  background: var(--color-assets-primary);
}

.toggle-panel-inner .toggle-switch.toggle-on:before {
  transform: translateX(11px);
}

.form-group-row {
  display: flex;
}

.form-group-row .form-group {
  width: 100%;
}

.form-group-row .form-group:not(:last-child) {
  margin-right: 16px;
}

.status-active {
  color: #2dd36f;
}

.status-inactive {
  color: var(--color-assets-secondary-tint);
}

.status-pending {
  color: var(--color-assets-primary);
}

.progress-bar {
  text-align: center;
  margin: 12px 0 32px;
}

.progress-bar ul {
  list-style-type: none;
  position: relative;
  display: inline-block;
}

.progress-bar ul li {
  display: inline-block;
  position: relative;
  z-index: 2;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #a1a1a1;
  position: relative;
  border: 4px solid #fff;
  margin: 0 12px;
  box-sizing: content-box;
}

.progress-bar ul li:before {
  position: absolute;
  right: 9px;
  width: 28px;
  top: 4px;
  z-index: 1;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  background: #ede8e8;
  height: 1px;
}

.progress-bar ul li:first-child:before {
  display: none;
}

.progress-bar ul li.active {
  width: 7px;
  height: 7px;
  background: #fff;
  border-color: #bb8141;
  border-width: 5px;
}

.progress-bar ul li.active:before {
  background: #bb8141;
  height: 2px;
  top: 3px;
}

.progress-bar ul li.done {
  width: 7px;
  height: 7px;
  background: #bb8141;
  border-color: #bb8141;
  border-width: 5px;
}

.progress-bar ul li.done:before {
  background: #bb8141;
  height: 2px;
  top: 3px;
}

.progress-bar ul li.done:after {
  position: absolute;
  top: -1px;
  left: -1px;
  content: "done";
  font-family: "Material Icons";
  visibility: visible;
  display: block;
  font-size: 10px;
  color: #fff;
}

.edit-date .datepicker-inline {
  position: absolute;
  top: auto;
  z-index: 1;
  bottom: 255px;
}

.pac-container {
  z-index: 99999;
}

.toggle-panel {
  width: 25%;
}

.toggle-panel-inner {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  border: 1px solid var(--color-border-primary);
  border-radius: 4px;
  height: 40px;
  background-color: #f6fafd;
}

.status-text {
  color: #002626;
  text-transform: capitalize;
}

.error-text {
  color: #c20000;
}

body.collapse .alert-left-bottom,
body.collapse .alert-left-top {
  left: 64px !important;
}

body.collapse .alert-center-top,
body.collapse .alert-center-bottom {
  margin-left: 30px !important;
}

.add-page-progress-bar {
  list-style-type: none;
  text-align: center;
  position: relative;
}

.add-page-progress-bar li {
  display: inline-block;
  width: 120px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  padding-top: 14px;
  color: #7f92a3;
}

.add-page-progress-bar li:before {
  height: 2px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  top: 4px;
  left: -60px;
  width: 120px;
  background-color: #d7e0e7;
  z-index: 1;
}

.add-page-progress-bar li:after {
  height: 8px;
  width: 8px;
  content: '.';
  border-radius: 50%;
  text-indent: -9999px;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #d7e0e7;
  background-color: #fafbfc;
  z-index: 2;
}

.add-page-progress-bar li:first-child:before {
  display: none;
}

.add-page-progress-bar li.active {
  color: var(--color-assets-secondary);
}

.add-page-progress-bar li.active:before {
  background-color: #2dd36f;
}

.add-page-progress-bar li.active:after {
  background-color: #2dd36f;
  border-color: #2dd36f;
}

.datepicker-position .datepicker-inline {
  position: absolute;
  top: auto;
  z-index: 1;
  bottom: -75px;
  width: 26%;
}

.create-job .drop-arrow {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid var(--color-border-primary);
}

.create-job .drop-arrow.active {
  border-bottom: 1px solid #f6fafd;
  background-color: #f6fafd;
}

.create-job .hierarchy-logo .logo {
  height: 40px;
  width: 40px;
}

.create-job .hierarchy-logo .star {
  width: 12px;
  height: 12px;
  color: #ffffff;
  background-color: #2dd36f;
  border-radius: 100%;
}

.create-job .hierarchy-logo .text-dark {
  color: #222428;
}

.profiles-img {
  display: inline-flex;
}

.profiles-img span {
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-border-primary);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f8f9fa;
  box-shadow: 0px 3px 6px #00000019;
  position: relative;
  float: left;
}

.profiles-img span:nth-of-type(2) {
  left: -16px;
  margin-right: -10px;
}

.profiles-img span:nth-of-type(3) {
  left: -16px;
  margin-right: -10px;
}

.profiles-img span:nth-of-type(4) {
  left: -16px;
  margin-right: -10px;
}

.create-wrapper {
  background-color: #F8F9FA;
  min-height: calc(100vh - 57px);
}

.create-wrapper .create-form {
  max-width: 1200px;
  margin: auto;
}

.create-wrapper .create-form.medium {
  max-width: 949px;
}

.create-wrapper .create-form .card {
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.06);
  border: 1px solid #EBEEF3;
  border-radius: 4px;
}

.create-wrapper .create-form .card .breadcrumb {
  align-items: center;
  border-bottom: 1px solid #EBEEF3;
}

.create-wrapper .create-form .card .breadcrumb>div {
  border-left: 1px solid #EBEEF3;
  line-height: 64px;
  cursor: pointer;
}

.create-wrapper .create-form .card .breadcrumb>div icon {
  display: none;
}

.create-wrapper .create-form .card .breadcrumb>div span {
  width: 27px;
  height: 27px;
  line-height: 27px;
  background-color: #F6FAFD;
  border-radius: 50%;
  overflow: hidden;
  color: #343A40;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
}

.create-wrapper .create-form .card .breadcrumb>div span.title {
  border-radius: inherit;
}

.create-wrapper .create-form .card .breadcrumb>div:first-child {
  border-left: 0;
}

.create-wrapper .create-form .card .breadcrumb>div.active span {
  background-color: var(--color-assets-primary);
  color: #ffffff;
}

.create-wrapper .create-form .card .breadcrumb>div.active span.title {
  background-color: transparent;
  color: #343a40;
}

.create-wrapper .create-form .card .breadcrumb>div.finished span.num {
  display: none;
}

.create-wrapper .create-form .card .breadcrumb>div.finished icon {
  display: inline-block;
  vertical-align: middle;
}

.create-wrapper .create-form .card .breadcrumb>div.finished icon i {
  vertical-align: middle;
  color: var(--color-assets-primary);
  margin-top: -5px;
}

.create-wrapper .create-form .card .breadcrumb.mobile-comp>div span {
  width: auto;
  height: auto;
  background-color: transparent;
}

.create-wrapper .create-form .card .breadcrumb.mobile-comp>div span.num {
  width: 27px;
  height: 27px;
  line-height: 27px;
  background-color: #F6FAFD;
}

.create-wrapper .create-form .card .breadcrumb.mobile-comp>div.active span {
  color: #343A40;
}

.create-wrapper .create-form .card .breadcrumb.mobile-comp>div.active span.num {
  background-color: var(--color-assets-primary);
  color: #ffffff;
}

.create-wrapper .create-form .card .input-with-fixed-value span {
  position: absolute;
  top: 1px;
  left: 1px;
  background: #FAFBFC;
  width: 52px;
  text-align: center;
  bottom: 1px;
  line-height: 40px;
  border-right: 1px solid var(--color-border-secondary);
}

.create-wrapper .create-form .card .input-with-fixed-value .form-control {
  padding-left: 64px;
}

.create-wrapper .create-form .card .input-with-fixed-value.right-aligned span {
  left: auto;
  right: 1px;
  border-left: 1px solid var(--color-border-secondary);
  border-right: 0;
}

.create-wrapper .create-form .card .input-with-fixed-value.right-aligned .form-control {
  padding-left: 13px;
  padding-right: 64px;
}

.create-wrapper .create-form .card .date-picker ::ng-deep .datepicker--imagesize {
  left: auto;
  right: 8px;
}

.create-wrapper .create-form .card .date-picker ::ng-deep input {
  padding-left: 13px !important;
  padding-right: 32px !important;
}

.create-wrapper .create-form .card .date-picker ::ng-deep .datepicker {
  width: 267px;
}

.create-wrapper .create-form .card .radio-group {
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
  overflow: hidden;
  height: 40px;
}

.create-wrapper .create-form .card .radio-group .radio-container {
  width: 50%;
  text-align: center;
  height: 40px;
  margin-bottom: 0;
}

.create-wrapper .create-form .card .radio-group .radio-container input {
  left: 0;
  width: 100%;
  height: 100%;
}

.create-wrapper .create-form .card .radio-group .radio-container .checkmark {
  width: auto;
  right: 0;
  line-height: 40px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
}

.create-wrapper .create-form .card .radio-group .radio-container:first-child {
  border-right: 1px solid var(--color-border-secondary);
}

.create-wrapper .create-form .card .radio-group .radio-container input:checked~.checkmark {
  background-color: var(--color-assets-primary);
  color: #ffffff;
}

.create-wrapper .create-form .card .radio-group .radio-container input:checked~.checkmark:after {
  display: none;
}

.create-wrapper .create-form .card .budget-section>div {
  flex: 1;
}

.create-wrapper .create-form .card .budget-section .budget-item {
  width: 100%;
  background-color: #f6fafd;
  border-radius: 4px;
}

.create-wrapper .create-form .card .budget-section .budget-item icon {
  float: left;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  margin-top: 4px;
}

.create-wrapper .create-form .card .budget-section .budget-item icon ::ng-deep i {
  color: #ffffff;
  line-height: 36px;
}

.create-wrapper .create-form .card .budget-section .budget-item icon.green {
  background-color: #2dd36f;
}

.create-wrapper .create-form .card .budget-section .budget-item icon.blue {
  background-color: #776de0;
}

.create-wrapper .create-form .card .budget-section .budget-item icon.yellow {
  background-color: #ffc409;
}

.create-wrapper .create-form .card .budget-section .budget-item p {
  float: left;
  color: #000000;
}

.create-wrapper .create-form .card .budget-section .budget-item p span {
  display: block;
  color: #343A40;
}

.create-wrapper .create-form .card .budget-section .detail-button button {
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
  font-size: 13px;
  color: #776de0;
  outline: none;
  cursor: pointer;
  background: #ffffff;
  width: 100%;
  line-height: 72px;
  text-align: center;
}

.create-wrapper .create-form .card .toggle-with-icon .toggle-panel-inner {
  height: auto;
  justify-content: start;
  align-items: stretch;
  background-color: #F8F9FA;
  border: 0;
}

.create-wrapper .create-form .card .toggle-with-icon .toggle-panel-inner icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--color-assets-primary);
  color: #fff;
}

.create-wrapper .create-form .card .toggle-with-icon .toggle-panel-inner icon ::ng-deep i {
  color: #ffffff;
  line-height: 30px;
}

.create-wrapper .create-form .card .toggle-with-icon .toggle-panel-inner p {
  color: #3A4550;
  line-height: 30px;
  flex: 2;
}

.create-wrapper .create-form .card .toggle-with-icon .toggle-panel-inner .toggle-switch {
  margin-top: 8px;
  background: #536476;
}

.create-wrapper .create-form .card .toggle-with-icon .toggle-panel-inner .toggle-switch.toggle-on {
  background: var(--color-assets-primary);
}

.create-wrapper .create-form .card .approvers {
  background-color: #F8F9FA;
}

.create-wrapper .create-form .card .approvers figure {
  width: 17px;
  height: 17px;
  background: #8E168D;
  color: #ffffff;
  text-align: center;
  line-height: 17px;
  overflow: hidden;
  font-size: 12px;
  border-radius: 50%;
}

.create-wrapper .create-form .card .approvers figure img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.create-wrapper .create-form .card .approvers p {
  color: #002626;
  line-height: 18px;
}

.create-wrapper .create-form .card .approvers p span {
  display: block;
  font-size: 11px;
  color: var(--color-body-txt-secondary);
}

.create-wrapper .create-form .card .gray-bg .ng-select-container {
  background-color: #FAF8FF;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.create-wrapper .create-form .card .gray-bg input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.create-wrapper .create-form .card .has-info-icon icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
  z-index: 100;
}

.create-wrapper .create-form .card .has-info-icon icon i {
  color: #ADB5BD;
}

.create-wrapper .create-form .card .has-info-icon icon:hover i {
  color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .has-info-icon icon.on-select {
  right: 43px;
}

.create-wrapper .create-form .card .has-info-icon input {
  padding-right: 40px;
}

.create-wrapper .create-form .card .has-info-icon .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 67px;
}

.create-wrapper .create-form .card .btn-add-item {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .btn-add-item i {
  color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .close-item {
  background-color: transparent;
  border: 0;
  outline: 0;
  width: 14px;
  height: 14px;
  line-height: 14px;
  cursor: pointer;
}

.create-wrapper .create-form .card .close-item icon {
  display: flex;
}

.create-wrapper .create-form .card .close-item icon i {
  color: #002626;
}

.create-wrapper .create-form .card .qualification-dropdown {
  border: 1px solid var(--color-assets-primary);
  border-radius: 4px;
  position: relative;
}

.create-wrapper .create-form .card .qualification-dropdown p {
  padding: 0 15px;
  line-height: 32px;
  color: var(--color-assets-primary);
  cursor: pointer;
}

.create-wrapper .create-form .card .qualification-dropdown p i {
  color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .qualification-dropdown ul {
  list-style-type: none;
  position: absolute;
  top: 30px;
  left: -0.5px;
  right: -1px;
  transition: 0.2s ease-in-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  border: 1px solid var(--color-assets-primary);
  border-top: 0;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 100;
  background-color: #ffffff;
}

.create-wrapper .create-form .card .qualification-dropdown ul li .checkbox-container .label {
  font-size: 13px;
  color: #002626;
}

.create-wrapper .create-form .card .qualification-dropdown ul li .checkbox-container .checkmark {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-color: #ADB5BD;
  top: 11px;
  left: 13px;
}

.create-wrapper .create-form .card .qualification-dropdown ul li .checkbox-container .checkmark:after {
  display: block;
  color: #ADB5BD;
  left: 0;
  top: 0;
  font-size: 10px;
  width: 12px;
  height: 10px;
}

.create-wrapper .create-form .card .qualification-dropdown ul li .checkbox-container input:checked~.checkmark {
  background-color: #2dd36f;
  border-color: #2dd36f;
}

.create-wrapper .create-form .card .qualification-dropdown ul li .checkbox-container input:checked~.checkmark:after {
  color: #ffffff;
}

.create-wrapper .create-form .card .qualification-dropdown ul li .checkbox-container input:checked~.label {
  color: #2dd36f;
}

.create-wrapper .create-form .card .qualification-dropdown:hover p icon {
  transform: rotate(180deg);
}

.create-wrapper .create-form .card .qualification-dropdown:hover ul {
  opacity: 1;
  visibility: visible;
  max-height: 600px;
}

.create-wrapper .create-form .card .icon-on-label i {
  color: #ADB5BD;
}

.create-wrapper .create-form .card .icon-on-label:hover i {
  color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .delete-confirmation {
  background-color: #FFC9C9;
  border-left: 4px solid #FF0000;
  padding: 24px;
  margin-bottom: 8px;
}

.create-wrapper .create-form .card .delete-confirmation p {
  color: #FF0A4C;
}

.create-wrapper .create-form .card .delete-confirmation .Yes {
  background-color: #FF0A4C;
  color: #ffffff;
  border-radius: 18px;
  border: 0;
  outline: 0;
  cursor: pointer;
  margin-right: 8px;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 21px;
}

.create-wrapper .create-form .card .delete-confirmation .no {
  background-color: #6c757d;
  color: #ffffff;
  border-radius: 18px;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 21px;
}

.create-wrapper .create-form .card .contact-infos .icon-bg {
  background-color: #FAF8FF;
  border: 1px solid var(--color-border-secondary);
  padding: 11px;
  border-right: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.create-wrapper .create-form .card .contact-infos .no-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 24px;
}

.create-wrapper .create-form .card .contact-infos .no-radius .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 24px;
}

.create-wrapper .create-form .card .contact-infos search-address {
  width: 100%;
  padding-right: 0 !important;
}

.create-wrapper .create-form .card .contact-infos .select-bg .ng-select-container {
  background-color: #FAF8FF;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.create-wrapper .create-form .card .contact-infos .field-info-icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.create-wrapper .create-form .card .contact-infos .field-info-icon i {
  color: #ADB5BD;
}

.create-wrapper .create-form .card .contact-infos .field-info-icon:hover i {
  color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .contact-infos button {
  margin-top: 32px;
  width: 25px;
  height: 25px;
  border-color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .contact-infos button i {
  color: var(--color-assets-primary);
}

.create-wrapper .create-form .card .resume-uploader {
  flex-basis: 86%;
}

.create-wrapper .create-form .card .profile-image {
  flex-basis: 10%;
}

.create-wrapper .create-form .card .colored-box {
  background-color: #F7F8FF;
  border: 1px solid #EAECFC;
}

.create-wrapper .create-form .card .colored-box button {
  border-radius: 23px;
  font-size: 14px;
}

.create-wrapper .create-form .card .colored-box .color-black {
  color: #002626;
}

.create-wrapper .create-form .card .colored-box .icon-box {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #77EFA7;
}

.create-wrapper .create-form .card .colored-box .icon-box i {
  color: #ffffff;
}

.create-wrapper .create-form .card .colored-box .day {
  background-color: #ffffff;
  border: 1px solid #77EFA7;
  padding: 8px;
  flex-basis: 13.28%;
}

.create-wrapper .create-form .card .colored-box .day>p {
  color: #002626;
}

.create-wrapper .create-form .card .colored-box .day ul {
  list-style-type: none;
}

.create-wrapper .create-form .card .colored-box .day ul li {
  padding-left: 16px;
  margin-top: 12px;
  position: relative;
  border-left: 1px solid var(--color-border-primary);
  margin-left: 4px;
}

.create-wrapper .create-form .card .colored-box .day ul li p {
  border: 5px solid var(--color-border-primary);
  border-radius: 10px;
  margin-top: 18px;
  width: 85%;
}

.create-wrapper .create-form .card .colored-box .day ul li:fist-child p {
  margin-top: 0;
}

.create-wrapper .create-form .card .colored-box .day ul li:before {
  position: absolute;
  top: 0px;
  left: -4px;
  width: 7px;
  height: 7px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  background-color: var(--color-border-primary);
  border-radius: 50%;
}

.create-wrapper .create-form .card .colored-box .day ul li:after {
  position: absolute;
  bottom: 0px;
  left: -4px;
  width: 7px;
  height: 7px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  background-color: var(--color-border-primary);
  border-radius: 50%;
}

.create-wrapper .create-form .card .colored-box .day ul li.filled {
  border-left: 1px solid #77EFA7;
}

.create-wrapper .create-form .card .colored-box .day ul li.filled p {
  border: 0;
  margin-top: 0;
  border-radius: 0;
  width: auto;
}

.create-wrapper .create-form .card .colored-box .day ul li.filled:before {
  position: absolute;
  top: 0px;
  left: -4px;
  width: 7px;
  height: 7px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  background-color: #77EFA7;
  border-radius: 50%;
}

.create-wrapper .create-form .card .colored-box .day ul li.filled:after {
  position: absolute;
  bottom: 0px;
  left: -4px;
  width: 7px;
  height: 7px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  background-color: #77EFA7;
  border-radius: 50%;
}

.create-wrapper .create-form .card .colored-box .day .holiday {
  height: 120px;
  flex-basis: 100%;
  text-align: center;
}

.create-wrapper .create-form .card .colored-box .day.off-day>p {
  color: #ADB5BD;
}

.create-wrapper .form-footer {
  background-color: #FAFBFC;
  border-top: 1px solid #EBEEF3;
  text-align: right;
  justify-content: flex-end;
}

.create-wrapper .form-footer .footer-left {
  flex: 2;
  text-align: left;
  position: relative;
}

.create-wrapper .form-footer .footer-left h4 {
  color: #776de0;
}

.create-wrapper .form-footer .footer-left p {
  color: #495057;
}

.create-wrapper .form-footer .footer-left:after {
  border-top: 14px solid #FAFBFC;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  width: 1px;
  height: 10px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  position: absolute;
  bottom: -44px;
  left: 30px;
  z-index: 4;
}

.create-wrapper .form-footer .advanced-profile {
  position: absolute;
  top: -1px;
  left: 32px;
  background-color: #ffffff;
  border: 1px solid #d7e0e7;
  border-top: 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  outline: 0;
  cursor: pointer;
  padding: 10px 10px 10px 18px;
  color: var(--color-assets-primary);
}

.create-wrapper .form-footer .advanced-profile icon i {
  vertical-align: middle;
  color: var(--color-assets-primary);
}

.create-wrapper .with-icon .icon-on-inout {
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
  z-index: 1;
  height: 24px;
  overflow: hidden;
}

.create-wrapper .with-icon input {
  padding-left: 45px;
}

.create-wrapper ::ng-deep .ql-container.ql-snow {
  border-color: var(--color-border-secondary);
}

.create-wrapper ::ng-deep .ql-toolbar.ql-snow {
  border-color: var(--color-border-secondary);
}

.create-wrapper ::ng-deep .ql-editor P {
  color: #222428;
}

.create-wrapper ::ng-deep .ql-editor:before {
  display: none;
  font-style: normal;
  font-weight: 500;
  color: #222428;
}

ngx-skeleton-loader .loader {
  animation: none !important;
  -webkit-animation: none !important;
}

ngx-skeleton-loader span {
  outline: none;
}

body.no-pointer {
  pointer-events: none;
}

body.no-pointer .calendar-popup {
  pointer-events: auto;
}

.hide-arrow .ng-arrow-wrapper {
  display: none;
}

.value-group {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.value-group .value-head {
  font-size: 12px;
  color: #707070;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.value-group .value-content {
  font-size: 13px;
  color: #000000;
  word-break: break-word;
}

body.sidebar-overflow,
body.sidebar-ng-overflow,
body.nav-overflow,
body.sidebar-notify-overflow,
body.svms-modal-overflow {
  overflow: hidden;
}

body.dropdown-menu-overflow,
body.dropdown-manager-overflow,
body.manager-dropdown-overflow {
  overflow: hidden;
  padding-right: 17px;
  background-color: #f8f9fa;
}

body.dropdown-menu-overflow .header-fixed,
body.dropdown-manager-overflow .header-fixed,
body.manager-dropdown-overflow .header-fixed {
  padding-right: 17px;
}

.info-cards {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.info-cards .info-cards-child {
  display: flex;
  align-items: center;
  flex: 1;
}

.info-cards .info-cards-child icon {
  margin-right: 8px;
}

.info-cards .info-cards-child icon i {
  color: #495056;
  font-size: 18px;
}

.info-cards .info-cards-child .right-content {
  margin-left: 8px;
  padding-left: 4px;
}

.info-cards .info-cards-child .right-content .dob-vision {
  display: flex;
}

.info-cards .info-cards-child .right-content .dob-vision .dob-hide {
  width: 100px;
  height: 20px;
  background-image: linear-gradient(145deg, #a8eba8 21.43%, #ebf0ff 21.43%, #ebf0ff 50%, #a8eba8 50%, #a8eba8 71.43%, #ebf0ff 71.43%, #ebf0ff 100%);
  background-size: 10px 10px;
  margin-right: 4px;
}

.info-cards .info-cards-child .right-content .dob-vision .dot-show {
  width: 100px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 4px;
}

.info-cards .info-cards-child .right-content .dob-vision icon {
  margin-left: 8px;
}

.info-cards .info-cards-child .right-content .dob-vision icon i {
  font-size: 18px;
  color: #495057;
  cursor: pointer;
}

.info-cards .info-cards-child .right-content p {
  font-size: 14px;
  color: #5F6D89;
}

.info-cards .info-cards-child .right-content p.label {
  font-size: 12px;
}

@media screen and (min-width: 1441px) {
  .create-wrapper .create-form {
    /*zoom: 1.17;*/
  }
}

.grid-data-wrap {
  margin-top: 24px;
  flex-basis: 50%;
}

.grid-data-wrap icon {
  margin-right: 20px !important;
}

.grid-data-wrap icon i {
  color: #495057 !important;
  font-size: 20px !important;
}

.grid-data-wrap .img-item {
  margin-right: 20px !important;
}

.grid-data-wrap .img-item img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.grid-data-wrap .initials {
  text-align: center;
  line-height: 24px;
  font-size: 11px;
  width: 24px;
  height: 24px;
  color: #fff;
  margin-right: 16px !important;
  display: table;
}

.grid-data-wrap .grid-data {
  width: 100%;
}

.grid-data-wrap .grid-data span {
  font-size: 13px;
  color: #5F6D89;
  margin-bottom: 8px;
  float: left;
  width: 100%;
  display: block;
}

.grid-data-wrap .grid-data .sensitive-data {
  float: left;
}

.grid-data-wrap .grid-data .sensitive-data .hidden-value {
  background: #f7811c26;
  color: transparent;
  box-shadow: 0px 0px 10px #f7811c12;
  margin-right: 12px;
  background-image: linear-gradient(145deg, #a8eba8 21.43%, #ebf0ff 21.43%, #ebf0ff 50%, #a8eba8 50%, #a8eba8 71.43%, #ebf0ff 71.43%, #ebf0ff 100%);
  background-size: 10px 7px;
  border-radius: 4px;
}

.grid-data-wrap .grid-data label {
  font-size: 14px;
  color: #2B2D42;
  margin-bottom: 0px;
  float: left;
  text-transform: capitalize;
}

.grid-data-wrap .grid-data label.fixed-width {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grid-data-wrap .grid-data .label-rounded {
  background: #F0DEFF;
  border-radius: 12px;
  padding: 3px 9px 3px 7px;
  color: #AC54F7 !important;
  font-size: 12px !important;
}

.grid-data-wrap .grid-data .label-rounded icon {
  float: left;
  margin-right: 7px !important;
}

.grid-data-wrap .grid-data .label-rounded icon i {
  float: left;
  font-size: 16px !important;
}

.grid-data-wrap .grid-data ul {
  margin: -3px 9px 0px 0px;
  padding: 0px;
  float: left;
}

.grid-data-wrap .grid-data ul li {
  list-style: none;
  float: left;
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}

.grid-data-wrap .grid-data ul li:nth-child(2) {
  margin-left: -10px;
}

.grid-data-wrap .grid-data ul li:nth-child(3) {
  margin-left: -10px;
}

.grid-data-wrap .grid-data ul li img {
  width: 21px;
  height: 21px;
  border-radius: 50%;
}

.grid-data-wrap .grid-data ul li span {
  text-align: center;
  line-height: 22px;
  font-size: 10px;
  width: 21px;
  height: 21px;
  color: #fff;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.grid-data-wrap .grid-data ul li .count {
  background: orange;
}

.grid-data-wrap .grid-data ul li:hover {
  z-index: 99;
  position: relative;
  box-shadow: 0px 0px 6px #00000038;
}

.grid-data-wrap .remote-status icon {
  margin-right: 12px !important;
}

.grid-data-wrap .remote-status icon i {
  color: #FF0000 !important;
}

.grid-data-wrap .remote-status span {
  color: #FF0000;
}

.grid-data-wrap .remote-status.enabled icon i {
  color: #4BA324 !important;
}

.grid-data-wrap .remote-status.enabled span {
  color: #4BA324;
}

.notes-wrap.mini {
  border: 1px solid #FCE6FF;
  border-radius: 4px;
}

.notes-wrap.mini .notes-header {
  cursor: pointer;
  padding: 12px;
}

.notes-wrap.mini .notes-header label {
  flex-basis: 91%;
  color: var(--color-assets-primary);
  margin: 0px 0px 0px 8px;
}

.notes-wrap.mini .more-link {
  color: #4c4cd6;
  margin-left: 4px;
  cursor: pointer;
}

.notes-wrap.mini .detail-instruction {
  padding: 8px 12px 12px 12px;
  font-size: 13px;
  line-height: 21px;
  color: #4f5869;
}

.notes-wrap.empty .notes-header {
  cursor: auto;
}

.notes-wrap.empty .notes-header label {
  color: var(--color-assets-primary);
}

.radio-group {
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
  overflow: hidden;
  height: 40px;
}

.radio-group .radio-container {
  width: 50%;
  text-align: center;
  height: 40px;
  margin-bottom: 0;
}

.radio-group .radio-container input {
  left: 0;
  width: 100%;
  height: 100%;
}

.radio-group .radio-container .checkmark {
  width: auto;
  right: 0;
  line-height: 40px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
}

.radio-group:first-child {
  border-right: 1px solid var(--color-border-secondary);
}

.radio-group input:checked~.checkmark {
  background-color: var(--color-assets-primary);
  color: #ffffff;
}

.radio-group input:checked~.checkmark:after {
  display: none;
}

.job-view-content-header {
  background-color: #FCFAFF;
  padding: 24px;
  overflow: auto;
  border-bottom: 1px solid #F0F0FE;
}

.job-view-content-header h4 {
  float: left;
  margin-right: 10px;
  font-weight: 500;
  color: #000;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.job-view-content-header .status-ui {
  float: left;
}

.job-view-content-header .sub-head-info {
  width: 100%;
  float: left;
  margin-top: 7px;
}

.job-view-content-header .sub-head-info label {
  font-size: 12px;
  margin-right: 24px;
  margin-bottom: 0px;
  color: #5F6D89;
  float: left;
}

.job-view-content-header .sub-head-info label icon {
  float: left;
  margin-right: 8px;
}

.job-view-content-header .sub-head-info label icon i {
  font-size: 16px !important;
}

.publish-date-time {
  margin-left: 24px;
}

.publish-date-time icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}

.back-btn {
  color: #0066FF;
}

.text-active {
  color: #0066FF !important;
}

.profile-interview .initials {
  width: 23px !important;
  height: 23px !important;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 9px;
  background-color: white;
  margin-right: 0px;
}

.profile-interview .initials.error {
  border-color: #FF9300;
  color: #FF9300;
}

.profile-interview .initials img {
  width: 100%;
}

.profile-interview .initials:not(:first-child) {
  margin-left: -10px;
}

.disableDiv {
  pointer-events: none;
}

.disableDiv .datepicker--imgdate [readonly] {
  background-color: #fafbfc !important;
}

.close-button {
  position: absolute;
  background-color: var(--color-assets-secondary-shade);
  color: #ffffff;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  right: 40px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}

.back-button {
  position: absolute;
  background-color: var(--color-assets-secondary-shade);
  color: #ffffff;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  left: 32px;
  top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
}

ngx-dropdown-treeview .dropdown-menu {
  position: static;
  opacity: 1;
  visibility: visible;
}

.stars.rating-stars {
  width: 133px;
  height: 20px;
}

.stars.rating-stars .rating label.star {
  font-size: 20px;
  padding: 0 8px 0 0;
  color: #FFD000;
}

.stars.rating-stars .rating input.star-5:checked~label.star:before {
  color: #FFD000 !important;
  text-shadow: 0 0 0px !important;
}

.stars.rating-stars .rating input.star-1:checked~label.star:before {
  color: #FFD000 !important;
}

.rate-info-table .info .initials {
  width: 36px;
  height: 36px;
  color: #000;
}

.rate-info-table .info .initials-blue {
  background-color: #5e71fe17;
  color: #5e71fe;
}

.rate-info-table .info .initials-green {
  background-color: #caffb2;
  color: #59bc2e;
}

.rate-info-table .info .initials-orange {
  background-color: #ffebc4;
  color: #d18915;
}

.rate-info-table .info .initials-pink {
  background-color: #ffdee0;
  color: #e23333;
}

.rate-info-table .table-rate {
  border-collapse: collapse;
}

.rate-info-table .table-rate th,
.rate-info-table .table-rate td {
  border: 1px solid #ecf3fa;
}

.rate-info-table .table-rate thead {
  background-color: #fcfaff;
}

.more-name-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  background-color: #ffffff;
  border: 1px solid #DDEAFF;
  visibility: hidden;
  z-index: 8;
}

.more-name-list h6 {
  background-color: #FBFAFF;
  line-height: 24px;
  padding: 0 8px;
  font-size: 10px;
  color: #002626;
  font-weight: 400;
}

.more-name-list ul {
  list-style-type: none;
  max-height: 120px;
  overflow: auto;
  /* Handle */
  /* Handle on hover */
}

.more-name-list ul li {
  margin-top: 12px;
  padding: 0 12px;
}

.more-name-list ul li div {
  font-size: 11px;
  line-height: 15px;
  color: #2F3943;
}

.more-name-list ul li div.name-avatar {
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  background-color: #E3E6E8;
  text-align: center;
  font-size: 7px;
  line-height: 15px;
  color: #002626;
  margin-right: 8px;
}

.more-name-list ul::-webkit-scrollbar {
  width: 4px;
}

.more-name-list ul::-webkit-scrollbar-track {
  background: transparent;
}

.more-name-list ul::-webkit-scrollbar-thumb {
  background: #0089FF;
}

.more-name-list ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.more-name-list ul:last-child {
  margin-bottom: 12px;
}

.more-name-list.relative-item {
  position: absolute;
  left: 75%;
  top: 10px;
}

.more-name-list.active {
  visibility: visible;
}

.more-name-list.active.flip {
  transform: translateY(-118%) !important;
}

.detail-page-wrapper {
  background-color: #F7FAFC;
  min-height: calc(100vh - 50px);
}

.detail-page-wrapper .table-bg-inner .title {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.detail-page-wrapper .pagination-border {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}

.detail-page-wrapper .pagination-border .pagination {
  margin-right: 0px !important;
}

.detail-page-wrapper .back-btn {
  color: #5050a2;
  padding: 9px 10px 10px 3px;
  cursor: pointer;
}

.detail-page-wrapper .back-btn:hover {
  background: #edf1f5;
  border-radius: 4px;
  color: #344ca7;
}

.detail-page-wrapper .back-btn icon {
  vertical-align: middle;
}

.detail-page-wrapper .notifications {
  overflow: hidden;
}

.detail-page-wrapper .notifications ul {
  list-style-type: none;
}

.detail-page-wrapper .notifications ul li {
  background-color: #eb445a;
  color: #ffffff;
  box-shadow: 0px 7px 13px #0000001D;
}

.detail-page-wrapper .notifications ul li icon {
  vertical-align: middle;
  background-color: #B72A3D;
  width: 31px;
  height: 31px;
  text-align: center;
}

.detail-page-wrapper .notifications ul li icon i {
  line-height: 31px;
}

.detail-page-wrapper .description-panel {
  max-width: 1600px;
  margin: auto;
}

.detail-page-wrapper .description-panel .card {
  border-radius: 4px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.06);
}

.detail-page-wrapper .description-panel .detail-page-header {
  padding: 24px;
}

.detail-page-wrapper .description-panel .detail-page-header h3 {
  color: #002626;
  font-weight: 500;
  line-height: 42px !important;
}

.detail-page-wrapper .description-panel .detail-page-header h3 a:hover {
  text-decoration: underline;
}

.detail-page-wrapper .description-panel .detail-page-header h3 span.tag {
  background-color: #e6b92b;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  padding: 0 12px 0 30px;
  border-radius: 4px;
  font-weight: 400;
}

.detail-page-wrapper .description-panel .detail-page-header h3 span.tag:before {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 7px;
  height: 7px;
  background-color: #ffffff;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  transform: translateY(-50%);
}

.detail-page-wrapper .description-panel .detail-page-header p {
  color: #536476;
}

.detail-page-wrapper .description-panel .detail-page-header p .meta-label i {
  color: #ffc409;
  vertical-align: middle;
}

.detail-page-wrapper .description-panel .detail-page-header p .updated i {
  vertical-align: middle;
}

.detail-page-wrapper .description-panel .detail-page-header p.max-width {
  max-width: 60%;
}

.detail-page-wrapper .description-panel .detail-page-header .actions {
  top: 24px;
  right: 24px;
}

.detail-page-wrapper .description-panel .detail-page-header .actions .action-list {
  list-style-type: none;
  width: 245px;
  top: 47px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
  max-height: 0;
  z-index: 2;
  box-shadow: 0px 12px 13px #00000014;
  overflow: hidden;
  border: 1px solid var(--color-border-secondary);
  border-radius: 4px;
}

.detail-page-wrapper .description-panel .detail-page-header .actions .action-list li a {
  line-height: 24px;
  font-weight: 500;
  display: block;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  padding: 12px;
}

.detail-page-wrapper .description-panel .detail-page-header .actions .action-list li a icon i {
  vertical-align: middle;
}

.detail-page-wrapper .description-panel .detail-page-header .actions .action-list li a:hover {
  background-color: #fafbfc;
}

.detail-page-wrapper .description-panel .detail-page-header .actions .action-list.active {
  opacity: 1;
  visibility: visible;
  max-height: 400px;
}

.detail-page-wrapper .description-panel .detail-page-header .action-list {
  list-style-type: none;
  width: 245px;
  top: 62px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
  max-height: 0;
  z-index: 2;
  box-shadow: 0px 12px 13px #00000014;
}

.detail-page-wrapper .description-panel .detail-page-header .action-list li a {
  line-height: 24px;
  font-weight: 500;
  display: block;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.detail-page-wrapper .description-panel .detail-page-header .action-list li a icon ::ng-deep i {
  vertical-align: middle;
}

.detail-page-wrapper .description-panel .detail-page-header .action-list li a:hover {
  background-color: #fafbfc;
}

.detail-page-wrapper .description-panel .detail-page-header .action-list.active {
  opacity: 1;
  visibility: visible;
  max-height: 400px;
}

.detail-page-wrapper .description-panel .detail-nav {
  border-bottom: 1px solid var(--color-border-secondary);
}

.detail-page-wrapper .description-panel .detail-nav ul {
  list-style-type: none;
}

.detail-page-wrapper .description-panel .detail-nav ul li {
  display: inline-block;
  margin-left: 24px;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more {
  position: relative;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more>a {
  position: relative;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more>a:after {
  position: absolute;
  top: 1px;
  right: -21px;
  content: 'expand_more';
  font-family: 'Material Icons';
  visibility: visible;
  display: block;
  font-size: 20px;
  bottom: auto;
  left: auto;
  height: auto;
  opacity: 1;
  text-indent: inherit;
  background-color: transparent;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more>a:hover {
  color: #222428;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more>a:hover:after {
  opacity: 1;
  visibility: visible;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more .secondary {
  position: absolute;
  top: 47px;
  right: 0;
  display: none;
  background-color: #ffffff;
  box-shadow: 0px 12px 13px #00000014;
  border: 1px solid var(--color-border-secondary);
  z-index: 100;
  width: 250px;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more .secondary li {
  display: block;
  margin-left: 0;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more .secondary li a {
  display: block;
  padding: 0 10px;
}

.detail-page-wrapper .description-panel .detail-nav ul li.more .secondary li a:after {
  display: none;
}

.detail-page-wrapper .description-panel .detail-nav ul li a {
  display: block;
  line-height: 45px;
  color: #222428;
  transition: 0.2s ease-in-out;
  position: relative;
}

.detail-page-wrapper .description-panel .detail-nav ul li a i {
  vertical-align: middle;
}

.detail-page-wrapper .description-panel .detail-nav ul li a:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  background-color: var(--color-assets-primary);
  height: 3px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.detail-page-wrapper .description-panel .detail-nav ul li a:hover {
  color: var(--color-assets-primary);
}

.detail-page-wrapper .description-panel .detail-nav ul li a:hover i {
  color: var(--color-assets-primary);
}

.detail-page-wrapper .description-panel .detail-nav ul li a:hover:after {
  opacity: 0;
  visibility: visible;
}

.detail-page-wrapper .description-panel .detail-nav ul li a.active {
  color: var(--color-assets-primary);
}

.detail-page-wrapper .description-panel .detail-nav ul li a.active i {
  color: var(--color-assets-primary);
}

.detail-page-wrapper .description-panel .detail-nav ul li a.active:after {
  opacity: 1;
  visibility: visible;
}

.detail-page-wrapper .description-panel .detail-nav ul li:first-child {
  margin-left: 0;
}

.detail-page-wrapper .description-panel .detail-nav ul.primary {
  white-space: nowrap;
}

.detail-page-wrapper .description-panel .detail-nav ul.primary li.hidden {
  display: none;
}

.detail-page-wrapper .description-panel .detail-nav ul.not-loaded {
  visibility: hidden;
}

.detail-page-wrapper .description-panel .detail-nav ul .secondary li.hidden {
  display: none !important;
}

.detail-page-wrapper .description-panel .detail-nav.show-secondary li.more .secondary {
  display: block;
}

.detail-page-wrapper .description-panel .detail-sidebar {
  width: 384px;
  float: right;
}

.detail-page-wrapper .description-panel .detail-sidebar .bg-gray {
  background: #FAF8FF;
}

.detail-page-wrapper .description-panel .detail-sidebar h4 {
  color: #000000;
  font-weight: 500;
}

.detail-page-wrapper .description-panel .detail-sidebar .info-item {
  flex: 1;
  color: #1C2022;
}

.detail-page-wrapper .description-panel .detail-sidebar .info-item.item-value {
  font-weight: 500;
  color: #002626;
}

.detail-page-wrapper .description-panel .detail-sidebar .info-item figure {
  width: 21px;
  height: 21px;
  overflow: hidden;
  border-radius: 50%;
  vertical-align: middle;
  min-width: 21px;
}

.detail-page-wrapper .description-panel .detail-sidebar .info-item figure img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.detail-page-wrapper .description-panel .detail-sidebar .info-item figure .initials {
  font-size: 9px;
}

.detail-page-wrapper .description-panel .content-part .section-title {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.detail-page-wrapper .description-panel .content-part .section-title i {
  vertical-align: middle;
  margin-top: -3px;
}

.detail-page-wrapper .description-panel .content-part .description {
  position: relative;
  overflow: hidden;
  line-height: 24px;
}

.detail-page-wrapper .description-panel .content-part .description p {
  color: #343a40;
}

.detail-page-wrapper .description-panel .content-part .description ul {
  margin-left: 17px;
}

.detail-page-wrapper .description-panel .content-part .description ul li {
  color: #343a40;
  line-height: 24px;
}

.detail-page-wrapper .description-panel .content-part .description ol {
  margin-left: 30px;
}

.detail-page-wrapper .description-panel .content-part .description.short-view {
  max-height: 250px;
}

.detail-page-wrapper .description-panel .content-part .description.short-view:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  height: 45px;
  background: linear-gradient(180deg, #FFFFFF36 0%, #ffffff 100%);
  z-index: 5;
}

.detail-page-wrapper .description-panel .content-part .info-horizontal>div {
  width: 30%;
  margin-right: 3%;
}

.detail-page-wrapper .description-panel .content-part .info-horizontal>div p {
  color: #002626;
}

.detail-page-wrapper .description-panel .content-part .info-horizontal>div p.label {
  color: #7f92a3;
}

.detail-page-wrapper .description-panel .content-part .q-label {
  flex: .5;
}

.detail-page-wrapper .description-panel .content-part .q-label p {
  font-weight: 500;
  color: #000000;
}

.detail-page-wrapper .description-panel .content-part .q-value {
  flex: 2;
}

.detail-page-wrapper .description-panel .content-part .q-value ul {
  list-style-type: none;
  margin: 0;
  display: inline;
}

.detail-page-wrapper .description-panel .content-part .q-value ul li {
  display: inline-block;
  background-color: #f6fafd;
  line-height: 26px;
  padding: 0 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 15px;
}

.detail-page-wrapper .description-panel .content-part .more-link {
  background: #ffffff;
  border: 1px solid #ebe7f7;
  padding: 8px 16px;
  border-radius: 4px;
  color: #968fe6;
  cursor: pointer;
  outline: 0;
}

.detail-page-wrapper .description-panel .toggle-section {
  top: 77px;
  right: 357px;
  width: 26px;
  height: 26px;
  border-right: 1px solid #d7e0e7;
  z-index: 17;
  transition: 0.2s ease-in-out;
  display: block;
}

.detail-page-wrapper .description-panel .toggle-section icon ::ng-deep i {
  line-height: 26px;
}

.detail-page-wrapper .description-panel.expanded {
  margin-right: 90px;
}

.detail-page-wrapper .description-panel.expanded ::ng-deep .grid-item {
  width: 32% !important;
}

.detail-page-wrapper .description-panel.expanded ::ng-deep .grid-item:nth-child(2n) {
  margin-right: 2% !important;
}

.detail-page-wrapper .description-panel.expanded ::ng-deep .grid-item:nth-child(3n) {
  margin-right: 0 !important;
}

.detail-page-wrapper .description-panel.expanded .job-header {
  right: 91px;
}

.detail-page-wrapper .description-panel.expanded .toggle-section {
  right: 77px;
}

.detail-page-wrapper .description-panel .invoice-meta.max-width {
  max-width: 60%;
}

.detail-page-wrapper .financial-info {
  list-style-type: none;
}

.detail-page-wrapper .financial-info li {
  width: 144px;
  border: 1px solid #f0f2f4;
  box-shadow: 0px 3px 16px #0000000A;
}

.detail-page-wrapper .financial-info li p {
  font-size: 12px;
}

.detail-page-wrapper ::ng-deep .overflow-box ::ng-deep .table-outer {
  width: 100%;
  overflow: auto;
  position: relative;
  padding-bottom: 60px;
}

.detail-page-wrapper ::ng-deep .overflow-box ::ng-deep .table-outer::-webkit-scrollbar {
  display: none;
}

.detail-page-wrapper ::ng-deep .overflow-box ::ng-deep .table-outer .table-listing {
  min-width: 1244px;
}

.detail-page-wrapper ::ng-deep .recent-items .item-paginator {
  right: 0;
  top: -48px;
}

.detail-page-wrapper ::ng-deep .recent-items ul {
  list-style-type: none;
}

.detail-page-wrapper ::ng-deep .recent-items ul li {
  width: 49%;
  float: left;
  border: 1px solid var(--color-border-primary);
  border-radius: 4px;
}

.detail-page-wrapper ::ng-deep .recent-items ul li icon {
  vertical-align: middle;
}

.detail-page-wrapper ::ng-deep .recent-items ul li h4 {
  color: #002626;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .type {
  color: #776de0;
  font-size: 12px;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .time {
  border-radius: 15px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 8px;
  color: #ffffff;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .time.red {
  background-color: #eb445a;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .time.blue {
  background-color: #776de0;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .time i {
  line-height: 30px;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .other-info p {
  flex: 1;
  font-size: 12px;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .other-info p span {
  font-size: 14px;
  color: #002626;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .item-footer {
  background-color: #fafbfc;
  border-top: 1px solid #d7e0e7;
  padding: 12px 16px;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .item-footer p {
  flex: 1;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .item-footer p.posted {
  font-size: 12px;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .item-footer figure {
  width: 26px;
  height: 26px;
  overflow: hidden;
  border-radius: 4px;
  vertical-align: middle;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .item-footer figure img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.detail-page-wrapper ::ng-deep .recent-items ul li .item-footer span {
  color: #002626;
}

.detail-page-wrapper ::ng-deep .recent-items ul li:nth-child(2n) {
  float: right;
}

.max-width-wrapper {
  max-width: 100%;
}

.meta-tooltip {
  background-color: #ffffff;
  min-width: 250px;
  border-radius: 4px;
  box-shadow: -1px -2px 14px rgba(0, 0, 0, 0.06);
  z-index: 100;
  display: none;
  visibility: hidden;
  overflow: hidden;
  height: 0;
  transform: translateY(40px);
}

.meta-tooltip.no-translate {
  transform: translateY(0);
}

.meta-tooltip:before {
  position: absolute;
  top: -25px;
  left: 50%;
  border-bottom: 15px solid #ffffff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  z-index: 101;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  transform: translateX(-50%);
}

.meta-tooltip.active {
  display: block;
  visibility: visible;
  height: auto;
  overflow: inherit;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.row-cols-1>* {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2>* {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3>* {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4>* {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5>* {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6>* {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-sm-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-sm-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-md-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-md-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-lg-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-lg-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .row-cols-xl-1>* {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2>* {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3>* {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row-cols-xl-4>* {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5>* {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6>* {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.self-config-wrapper {
  min-height: 100vh;
  background-color: #EDF2FE;
  -webkit-font-smoothing: antialiased;
  font-family: Inter;
  font-weight: 400;
  font-size: 13px;
  color: #001A43;
}

.self-config-wrapper a {
  text-decoration: none;
  cursor: pointer;
}

.self-config-wrapper .sidebar-menu-toggle {
  position: fixed;
  top: 19px;
  left: 17px;
  z-index: 10;
}

.self-config-wrapper.sidebar-menu-active .nav-bar ::ng-deep nav {
  position: static;
  max-width: 354px;
}

.self-config-wrapper.sidebar-menu-active .nav-bar ::ng-deep nav ul.short-view {
  display: none !important;
}

.self-config-wrapper.sidebar-menu-active .nav-bar ::ng-deep nav ul.full-view {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.self-config-wrapper.sidebar-menu-active .c-content {
  margin-left: 360px;
}

.self-config-wrapper.sidebar-menu-active .c-content ::ng-deep .c-header-panel {
  left: 354px !important;
  width: calc(100% - 378px);
}

.self-config-wrapper.sidebar-menu-active .c-content ::ng-deep .section-header {
  left: 360px !important;
}

.self-config-wrapper.sidebar-menu-active .c-content ::ng-deep .program-config-page .tab-menu {
  left: 360px !important;
}

.self-config-wrapper.sidebar-menu-active .c-content.shadow-enabled ::ng-deep .section-header {
  box-shadow: 0px 9px 10px -14px #0000006b;
}

.self-config-wrapper.sidebar-menu-active ::ng-deep .c-listing-header,
.self-config-wrapper.sidebar-menu-active ::ng-deep .c-listing-footer,
.self-config-wrapper.sidebar-menu-active ::ng-deep .c-search-panel {
  width: calc(100% - 384px);
  left: 360px;
}

.self-config-wrapper.sidebar-menu-active ::ng-deep .c-listing-table-wrapper perfect-scrollbar .ps__rail-x {
  left: 360px !important;
}

.self-config-wrapper.sidebar-menu-active ::ng-deep .c-listing-table-wrapper.scrlled-bottom perfect-scrollbar .ps-content {
  padding-bottom: 15px;
}

.self-config-wrapper.sidebar-menu-active ::ng-deep .c-listing-table-wrapper.scrlled-bottom perfect-scrollbar>.ps.ps--active-x>.ps__rail-x {
  position: absolute;
  left: 0px !important;
}

.self-config-wrapper ::ng-deep p {
  font-size: 13px;
}

.self-config-wrapper ::ng-deep p.large {
  font-size: 16px;
}

.self-config-wrapper ::ng-deep p.small {
  font-size: 12px;
}

.self-config-wrapper ::ng-deep perfect-scrollbar>.ps {
  overflow: hidden !important;
}

.self-config-wrapper .c-content.shadow-enabled ::ng-deep .section-header {
  box-shadow: 0px 9px 10px -14px #0000006b;
}

.self-config-wrapper .c-content ::ng-deep .table-bg .content-area-inner .fixed-header {
  display: none;
}

.self-config-wrapper .c-content ::ng-deep .table-bg .content-area-inner .listing-wrap {
  max-height: inherit;
  margin-bottom: 0;
}

.self-config-wrapper .c-content ::ng-deep .table-bg .content-area-inner .total-result {
  padding-bottom: 0;
}

.self-config-wrapper .c-content ::ng-deep .table-bg .content-area-inner .total-result .temp-total-result-box,
.self-config-wrapper .c-content ::ng-deep .table-bg .content-area-inner .total-result .total-result-box {
  display: none;
}

/*** components ***/
.nav-bar {
  width: 354px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #EDF2FE;
  z-index: 7;
}

.c-content {
  margin-left: 60px;
  background-color: #ffffff;
  padding-top: 56px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: 0.2s ease-in-out;
  min-height: calc(100vh - 56px);
}

.c-content ::ng-deep .c-page-content perfect-scrollbar>.ps.ps--active-y>.ps__rail-y {
  z-index: 2;
}

.c-content ::ng-deep .c-listing-table-wrapper perfect-scrollbar .ps__rail-x {
  left: 60px !important;
}

::ng-deep .c-header-panel {
  background-color: #EDF2FE;
  position: fixed;
  top: 0;
  left: 200px;
  width: calc(100% - 224px);
  min-height: 58px;
  z-index: 100;
  transition: 0.2s ease-in-out;
}

::ng-deep .c-header-panel .user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #4C7FF0;
  overflow: hidden;
}

::ng-deep .c-header-panel .user-avatar img {
  width: 30px;
  height: auto;
  object-fit: contain;
}

::ng-deep .breadcrumb {
  list-style-type: none;
  margin-left: 6px;
}

::ng-deep .breadcrumb li {
  display: inline-block;
  font-size: 12px;
  color: #001A43;
  line-height: 34px;
}

::ng-deep .breadcrumb li i {
  vertical-align: middle;
  color: #A3B0C0;
}

::ng-deep .breadcrumb li a {
  color: #00338E;
  font-size: 12px;
}

.c-action-dropdown {
  position: absolute;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #CFD8E1;
  box-shadow: 0px 3px 5px #0000000B;
  top: 20px;
  left: 20px;
  z-index: 100;
  min-width: 194px;
  display: none;
}

.c-action-dropdown ul {
  list-style-type: none;
}

.c-action-dropdown ul li a {
  line-height: 40px;
  padding: 0 16px;
  display: block;
  transition: 0.2s ease-in-out;
  color: #001A43;
  text-transform: capitalize;
}

.c-action-dropdown ul li a:hover {
  background-color: #FAFCFF;
}

.c-action-dropdown.align-right {
  left: auto;
  right: 0;
}

.c-action-dropdown.active {
  display: block;
}

.c-btn {
  outline: none;
  border: 0;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 16px;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.c-btn-primary-medium {
  background-color: #4C7FF0;
  color: #ffffff;
}

.c-btn-primary-medium-left-icon {
  background-color: #4C7FF0;
  color: #ffffff;
  text-transform: capitalize;
}

.c-btn-primary-medium-left-icon i {
  color: #ffffff;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 4px;
}

.c-btn-primary-medium-left-icon:hover {
  background-color: #0055FF;
}

.c-btn-primary-medium-left-icon.active,
.c-btn-primary-medium-left-icon:active,
.c-btn-primary-medium-left-icon.focus,
.c-btn-primary-medium-left-icon:focus {
  background-color: #00338E;
}

.c-btn-primary-medium-right-icon {
  background-color: #4C7FF0;
  color: #ffffff;
}

.c-btn-primary-medium-right-icon i {
  color: #ffffff;
  vertical-align: middle;
  font-size: 16px;
  margin-left: 4px;
}

.c-btn-primary-medium-right-icon:hover {
  background-color: #0055FF;
}

.c-btn-primary-medium-right-icon.active,
.c-btn-primary-medium-right-icon:active,
.c-btn-primary-medium-right-icon.focus,
.c-btn-primary-medium-right-icon:focus {
  background-color: #00338E;
}

.c-btn-primary-medium:hover {
  background-color: #0055FF;
}

.c-btn-primary-medium.active,
.c-btn-primary-medium:active,
.c-btn-primary-medium.focus,
.c-btn-primary-medium:focus {
  background-color: #00338E;
}

.c-btn-primary-medium-d {
  background-color: rgba(0, 68, 197, 0.5);
  color: #ffffff;
  cursor: not-allowed;
}

.c-btn-primary-medium-left-icon-d {
  background-color: rgba(0, 68, 197, 0.5);
  color: #ffffff;
  cursor: not-allowed;
}

.c-btn-primary-medium-left-icon-d i {
  color: #ffffff;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 4px;
}

.c-btn-primary-medium-right-icon-d {
  background-color: rgba(0, 68, 197, 0.5);
  color: #ffffff;
  cursor: not-allowed;
}

.c-btn-primary-medium-right-icon-d i {
  color: #ffffff;
  vertical-align: middle;
  font-size: 16px;
  margin-left: 4px;
}

.c-btn-success-medium {
  background-color: #00722E;
  color: #ffffff;
}

.c-btn-success-medium:hover {
  background-color: #01AF44;
}

.c-btn-success-medium.active,
.c-btn-success-medium:active,
.c-btn-success-medium.focus,
.c-btn-success-medium:focus {
  background-color: #00943A;
}

.c-btn-warning-medium {
  background-color: #AC4300;
  color: #ffffff;
}

.c-btn-warning-medium:hover {
  background-color: #CF6625;
}

.c-btn-warning-medium.active,
.c-btn-warning-medium:active,
.c-btn-warning-medium.focus,
.c-btn-warning-medium:focus {
  background-color: #BC5A1E;
}

.c-btn-secondary-medium {
  background-color: #EDF2FE;
  color: #00338E;
}

.c-btn-secondary-medium-left-icon {
  background-color: #EDF2FE;
  color: #00338E;
}

.c-btn-secondary-medium-left-icon i {
  color: #00338E;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 4px;
}

.c-btn-secondary-medium-left-icon:hover {
  color: #4C7FF0;
}

.c-btn-secondary-medium-left-icon.active,
.c-btn-secondary-medium-left-icon:active,
.c-btn-secondary-medium-left-icon.focus,
.c-btn-secondary-medium-left-icon:focus {
  background-color: #DDEAFF;
  color: #00338E;
}

.c-btn-secondary-medium:hover {
  color: #4C7FF0;
}

.c-btn-secondary-medium.active,
.c-btn-secondary-medium:active,
.c-btn-secondary-medium.focus,
.c-btn-secondary-medium:focus {
  background-color: #DDEAFF;
  color: #00338E;
}

.c-btn-outline-medium {
  background-color: #ffffff;
  border: 1px solid #4C7FF0;
  color: #4C7FF0;
}

.c-btn-outline-medium-left-icon {
  background-color: #ffffff;
  border: 1px solid #4C7FF0;
  color: #4C7FF0;
  text-transform: capitalize;
  border: 1px solid #4C7FF0;
}

.c-btn-outline-medium-left-icon i {
  color: #4C7FF0;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 4px;
}

.c-btn-outline-medium-left-icon:hover {
  border-color: #00338E;
}

.c-btn-outline-medium-left-icon.active,
.c-btn-outline-medium-left-icon:active,
.c-btn-outline-medium-left-icon.focus,
.c-btn-outline-medium-left-icon:focus {
  border-color: #00338E;
}

.c-btn-outline-medium-right-icon {
  background-color: #ffffff;
  border: 1px solid #4C7FF0;
  color: #4C7FF0;
  border: 1px solid #4C7FF0;
}

.c-btn-outline-medium-right-icon i {
  color: #4C7FF0;
  vertical-align: middle;
  font-size: 16px;
  margin-left: 4px;
}

.c-btn-outline-medium-right-icon:hover {
  border-color: #0055FF;
}

.c-btn-outline-medium-right-icon.active,
.c-btn-outline-medium-right-icon:active,
.c-btn-outline-medium-right-icon.focus,
.c-btn-outline-medium-right-icon:focus {
  border-color: #00338E;
}

.c-btn-outline-medium:hover {
  border-color: #0055FF;
}

.c-btn-outline-medium.active,
.c-btn-outline-medium:active,
.c-btn-outline-medium.focus,
.c-btn-outline-medium:focus {
  border-color: #00338E;
}

.c-btn-secondary-medium-d {
  background-color: rgba(237, 242, 254, 0.5);
  color: rgba(0, 51, 142, 0.5);
  cursor: not-allowed;
}

.c-btn-3-dot-actions {
  background-color: #EDF2FE;
  padding: 0 8px;
}

.c-btn-3-dot-actions i {
  color: #001A43;
  vertical-align: middle;
}

.c-btn-3-dot-actions:hover {
  color: #00338E;
}

.c-btn-3-dot-actions:hover i {
  color: #00338E;
}

.c-btn-3-dot-actions.active,
.c-btn-3-dot-actions:active,
.c-btn-3-dot-actions.focus,
.c-btn-3-dot-actions:focus {
  background-color: #DDEAFF;
  color: #00338E;
}

.c-btn-icon-button {
  background-color: transparent;
  padding: 0 8px;
}

.c-btn-icon-button i {
  color: #001A43;
}

.c-btn-icon-button:hover {
  color: #4C7FF0;
}

.c-btn-icon-button.active,
.c-btn-icon-button:active,
.c-btn-icon-button.focus,
.c-btn-icon-button:focus {
  background-color: #DDEAFF;
  color: #00338E;
}

.c-btn.c-btn-add {
  background-color: #EDF2FE;
  border: 1px solid olors(primary, 1);
  color: #00338E;
}

.c-btn.c-btn-add i {
  font-size: 13px;
}

.c-btn-transparent-bg {
  background-color: transparent;
  color: #4C7FF0;
  padding: 0;
}

.c-btn-transparent-bg i {
  vertical-align: middle;
  color: #4C7FF0;
}

.c-btn-danger {
  background-color: #FF4343;
  color: #ffffff;
}

.c-btn.small {
  height: 32px;
  line-height: 32px;
}

.c-btn:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.c-input-checkbox {
  position: relative;
  display: inline-block;
  line-height: 15px;
  cursor: pointer;
}

.c-input-checkbox .cb-ui {
  border: 1px solid #5B6874;
  width: 15px;
  height: 15px;
  border-radius: 3px;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  pointer-events: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.c-input-checkbox .cb-ui:before {
  position: absolute;
  content: "done";
  font-family: 'Material Icons';
  visibility: hidden;
  display: block;
  top: -1px;
  left: 0px;
  width: 15px;
  height: 15px;
  color: #ffffff;
}

.c-input-checkbox .cb-label {
  line-height: 15px;
  vertical-align: middle;
  color: #001A43;
}

.c-input-checkbox input {
  width: 100%;
  height: 15px;
  visibility: hidden;
  z-index: 2;
  position: absolute;
}

.c-input-checkbox input:checked~.cb-ui {
  border-color: #4C7FF0;
  background-color: #4C7FF0;
}

.c-input-checkbox input:checked~.cb-ui:before {
  visibility: visible;
}

.c-input-checkbox input:checked~.cb-label {
  color: #4C7FF0;
}

.c-input-checkbox:hover .cb-ui,
.c-input-checkbox:hover .cb-label {
  border-color: #4C7FF0;
  color: #4C7FF0;
}

.c-checkbox-block {
  border: 1px solid #CFD8E1;
  border-radius: 4px;
  padding: 16px;
  transition: 0.2s ease-in-out;
}

.c-checkbox-block .input-checkbox {
  display: flex;
}

.c-checkbox-block .input-checkbox .cb-ui {
  margin-top: 6px;
  margin-right: 16px;
}

.c-checkbox-block:hover {
  border-color: color(gray, 4);
}

.c-checkbox-block.active {
  border-color: #CFD8E1;
  background-color: #FAFCFF;
}

.self-config-wrapper ::ng-deep .c-form-group-title,
.generic-list-page ::ng-deep .c-form-group-title,
.fee-settings ::ng-deep .c-form-group-title {
  font-weight: 500;
  color: #001A43;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 24px;
}

.self-config-wrapper ::ng-deep .c-form-group-row,
.generic-list-page ::ng-deep .c-form-group-row,
.fee-settings ::ng-deep .c-form-group-row {
  display: flex;
}

.self-config-wrapper ::ng-deep .c-form-group-row .c-form-group,
.generic-list-page ::ng-deep .c-form-group-row .c-form-group,
.fee-settings ::ng-deep .c-form-group-row .c-form-group {
  width: 100%;
  margin-bottom: 24px;
}

.self-config-wrapper ::ng-deep .c-form-group-row .c-form-group:not(:last-child),
.generic-list-page ::ng-deep .c-form-group-row .c-form-group:not(:last-child),
.fee-settings ::ng-deep .c-form-group-row .c-form-group:not(:last-child) {
  margin-right: 16px;
}

.self-config-wrapper ::ng-deep label,
.generic-list-page ::ng-deep label,
.fee-settings ::ng-deep label {
  font-size: 13px;
  color: #5B6874;
}

.self-config-wrapper ::ng-deep label.small,
.generic-list-page ::ng-deep label.small,
.fee-settings ::ng-deep label.small {
  font-size: 12px;
}

.self-config-wrapper ::ng-deep .c-form-control,
.generic-list-page ::ng-deep .c-form-control,
.fee-settings ::ng-deep .c-form-control {
  border: 1px solid #CFD8E1;
  background-color: #ffffff;
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  height: 40px;
  transition: 0.2s ease-in-out;
  color: #003D46;
}

.self-config-wrapper ::ng-deep .c-form-control.small,
.generic-list-page ::ng-deep .c-form-control.small,
.fee-settings ::ng-deep .c-form-control.small {
  height: 32px;
  padding: 9px 12px;
  font-size: 12px;
}

.self-config-wrapper ::ng-deep .c-form-control:hover,
.generic-list-page ::ng-deep .c-form-control:hover,
.fee-settings ::ng-deep .c-form-control:hover {
  border-color: #A3B0C0;
}

.self-config-wrapper ::ng-deep .c-form-control:focus,
.generic-list-page ::ng-deep .c-form-control:focus,
.fee-settings ::ng-deep .c-form-control:focus {
  border-color: #0089FF;
}

.self-config-wrapper ::ng-deep .c-form-control.error,
.generic-list-page ::ng-deep .c-form-control.error,
.fee-settings ::ng-deep .c-form-control.error {
  border-color: #FF0000;
}

.self-config-wrapper ::ng-deep .c-form-control.transparent,
.generic-list-page ::ng-deep .c-form-control.transparent,
.fee-settings ::ng-deep .c-form-control.transparent {
  background-color: transparent;
  border: 0;
  padding: 8px 0;
  max-width: 80%;
}

.self-config-wrapper ::ng-deep textarea,
.generic-list-page ::ng-deep textarea,
.fee-settings ::ng-deep textarea {
  resize: none;
  border: 1px solid #CFD8E1;
  background-color: #ffffff;
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  min-height: 145px;
}

.self-config-wrapper ::ng-deep textarea.v-resize,
.generic-list-page ::ng-deep textarea.v-resize,
.fee-settings ::ng-deep textarea.v-resize {
  resize: vertical;
}

.self-config-wrapper ::ng-deep textarea.h-resize,
.generic-list-page ::ng-deep textarea.h-resize,
.fee-settings ::ng-deep textarea.h-resize {
  resize: horizontal;
}

.self-config-wrapper ::ng-deep ::-webkit-input-placeholder,
.generic-list-page ::ng-deep ::-webkit-input-placeholder,
.fee-settings ::ng-deep ::-webkit-input-placeholder {
  color: #AFB9C5;
  font-size: 13px;
  font-weight: 400;
}

.self-config-wrapper ::ng-deep :-ms-input-placeholder,
.generic-list-page ::ng-deep :-ms-input-placeholder,
.fee-settings ::ng-deep :-ms-input-placeholder {
  color: #AFB9C5;
  font-size: 13px;
  font-weight: 400;
}

.self-config-wrapper ::ng-deep ::placeholder,
.generic-list-page ::ng-deep ::placeholder,
.fee-settings ::ng-deep ::placeholder {
  color: #AFB9C5;
  opacity: 1;
  font-size: 13px;
  font-weight: 400;
}

.self-config-wrapper ::ng-deep :-ms-input-placeholder,
.generic-list-page ::ng-deep :-ms-input-placeholder,
.fee-settings ::ng-deep :-ms-input-placeholder {
  color: #AFB9C5;
  font-size: 13px;
  font-weight: 400;
}

.self-config-wrapper ::ng-deep ::-ms-input-placeholder,
.generic-list-page ::ng-deep ::-ms-input-placeholder,
.fee-settings ::ng-deep ::-ms-input-placeholder {
  color: #AFB9C5;
  font-size: 13px;
  font-weight: 400;
}

.self-config-wrapper ::ng-deep .file-input,
.generic-list-page ::ng-deep .file-input,
.fee-settings ::ng-deep .file-input {
  position: relative;
  height: 40px;
}

.self-config-wrapper ::ng-deep .file-input>input,
.generic-list-page ::ng-deep .file-input>input,
.fee-settings ::ng-deep .file-input>input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
}

.self-config-wrapper ::ng-deep .styled-interface,
.generic-list-page ::ng-deep .styled-interface,
.fee-settings ::ng-deep .styled-interface {
  position: relative;
  z-index: 2;
}

.self-config-wrapper ::ng-deep .styled-interface input,
.generic-list-page ::ng-deep .styled-interface input,
.fee-settings ::ng-deep .styled-interface input {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 50px;
  border-color: #EDF2FE;
  pointer-events: none;
}

.self-config-wrapper ::ng-deep .styled-interface button,
.generic-list-page ::ng-deep .styled-interface button,
.fee-settings ::ng-deep .styled-interface button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  pointer-events: none;
}

.self-config-wrapper ::ng-deep .styled-interface.file-attach input,
.generic-list-page ::ng-deep .styled-interface.file-attach input,
.fee-settings ::ng-deep .styled-interface.file-attach input {
  border-radius: 4px;
  border: 1px solid #CFD8E1;
}

.self-config-wrapper ::ng-deep .styled-interface.file-attach .c-btn.c-btn-secondary-medium,
.generic-list-page ::ng-deep .styled-interface.file-attach .c-btn.c-btn-secondary-medium,
.fee-settings ::ng-deep .styled-interface.file-attach .c-btn.c-btn-secondary-medium {
  border-radius: 0 4px 4px 0;
  border: 1px solid #CFD8E1;
}

.self-config-wrapper ::ng-deep .error-message,
.generic-list-page ::ng-deep .error-message,
.fee-settings ::ng-deep .error-message {
  font-size: 13px;
  color: #C50000;
  font-weight: 500;
}

.self-config-wrapper ::ng-deep .error-message i,
.generic-list-page ::ng-deep .error-message i,
.fee-settings ::ng-deep .error-message i {
  color: #FF0000;
  font-size: 18px;
  vertical-align: middle;
}

.self-config-wrapper ::ng-deep [readonly],
.generic-list-page ::ng-deep [readonly],
.fee-settings ::ng-deep [readonly] {
  background-color: #fafbfc;
}

.self-config-wrapper ::ng-deep .checklist-sidebar-wrapper .task-list li .checkbox-wrapper,
.generic-list-page ::ng-deep .checklist-sidebar-wrapper .task-list li .checkbox-wrapper,
.fee-settings ::ng-deep .checklist-sidebar-wrapper .task-list li .checkbox-wrapper {
  padding: 16px 24px 16px 32px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-opened>.ng-select-container,
.generic-list-page ::ng-deep .ng-select.ng-select-opened>.ng-select-container,
.fee-settings ::ng-deep .ng-select.ng-select-opened>.ng-select-container {
  background: #ffffff;
  border-color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-opened>.ng-select-container:hover,
.generic-list-page ::ng-deep .ng-select.ng-select-opened>.ng-select-container:hover,
.fee-settings ::ng-deep .ng-select.ng-select-opened>.ng-select-container:hover {
  box-shadow: none;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow,
.generic-list-page ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow,
.fee-settings ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  top: 0;
  border-color: transparent transparent transparent;
  border-width: 0;
  width: auto !important;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow:after,
.generic-list-page ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow:after,
.fee-settings ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow:after {
  content: "expand_less";
  font-family: Material Icons;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: #001A43;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover,
.generic-list-page ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover,
.fee-settings ::ng-deep .ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #000000;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container,
.generic-list-page ::ng-deep .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container,
.fee-settings ::ng-deep .ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 1px solid transparent;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-opened.ng-select-top>.ng-select-container,
.generic-list-page ::ng-deep .ng-select.ng-select-opened.ng-select-top>.ng-select-container,
.fee-settings ::ng-deep .ng-select.ng-select-opened.ng-select-top>.ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 1px solid transparent;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container,
.generic-list-page ::ng-deep .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container,
.fee-settings ::ng-deep .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #CFD8E1;
  box-shadow: none;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-disabled>.ng-select-container,
.generic-list-page ::ng-deep .ng-select.ng-select-disabled>.ng-select-container,
.fee-settings ::ng-deep .ng-select.ng-select-disabled>.ng-select-container {
  background-color: #fafbfc;
}

.self-config-wrapper ::ng-deep .ng-select .ng-has-value .ng-placeholder,
.generic-list-page ::ng-deep .ng-select .ng-has-value .ng-placeholder,
.fee-settings ::ng-deep .ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.self-config-wrapper ::ng-deep .ng-select .ng-select-container,
.generic-list-page ::ng-deep .ng-select .ng-select-container,
.fee-settings ::ng-deep .ng-select .ng-select-container {
  color: #1D252D;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #CFD8E1;
  min-height: 40px;
  align-items: center;
}

.self-config-wrapper ::ng-deep .ng-select .ng-select-container:hover,
.generic-list-page ::ng-deep .ng-select .ng-select-container:hover,
.fee-settings ::ng-deep .ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 transparent;
}

.self-config-wrapper ::ng-deep .ng-select .ng-select-container .ng-value-container,
.generic-list-page ::ng-deep .ng-select .ng-select-container .ng-value-container,
.fee-settings ::ng-deep .ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 13px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select .ng-select-container .ng-value-container,
.generic-list-page ::ng-deep .ng-select .ng-select-container .ng-value-container,
.fee-settings ::ng-deep .ng-select .ng-select-container .ng-value-container {
  padding-right: 13px;
  padding-left: 0;
}

.self-config-wrapper ::ng-deep .ng-select .ng-select-container .ng-value-container .ng-placeholder,
.generic-list-page ::ng-deep .ng-select .ng-select-container .ng-value-container .ng-placeholder,
.fee-settings ::ng-deep .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #AFB9C5;
  font-size: 13px;
  font-weight: 400;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-single .ng-select-container,
.generic-list-page ::ng-deep .ng-select.ng-select-single .ng-select-container,
.fee-settings ::ng-deep .ng-select.ng-select-single .ng-select-container {
  height: 40px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.generic-list-page ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.fee-settings ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 10px;
  left: 0;
  padding-left: 13px;
  padding-right: 50px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.generic-list-page ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input,
.fee-settings ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 13px;
  padding-left: 50px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value,
.fee-settings ::ng-deep .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  background-color: #e3eef9;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label,
.fee-settings ::ng-deep .ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
  padding-left: 7px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 13px;
  margin-bottom: 5px;
  background-color: #EDF2FE;
  border-radius: 4px;
  margin-right: 5px;
  color: #001A43;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #ffffff;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 5px 8px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 5px 5px 5px 5px;
  color: #EDF2FE;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.close-curr,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.close-curr,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.close-curr {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #51C6D5;
  width: 12px;
  padding: 0 !important;
  height: 12px;
  font-size: 11px;
  margin-left: 5px;
  border-radius: 50%;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  position: relative;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:before,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:before,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:before {
  position: absolute;
  content: "cancel";
  font-family: 'Material Icons';
  font-size: 12px;
  color: #51C6D5;
  top: 7px;
  right: 3px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 11px;
  padding-bottom: 5px;
  padding-left: 3px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}

.self-config-wrapper ::ng-deep .ng-select.ng-select-multiple .ng-clear-wrapper,
.generic-list-page ::ng-deep .ng-select.ng-select-multiple .ng-clear-wrapper,
.fee-settings ::ng-deep .ng-select.ng-select-multiple .ng-clear-wrapper {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.self-config-wrapper ::ng-deep .ng-select .ng-clear-wrapper:hover .ng-clear,
.generic-list-page ::ng-deep .ng-select .ng-clear-wrapper:hover .ng-clear,
.fee-settings ::ng-deep .ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #D0021B;
}

.self-config-wrapper ::ng-deep .ng-select .ng-spinner-zone,
.generic-list-page ::ng-deep .ng-select .ng-spinner-zone,
.fee-settings ::ng-deep .ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select .ng-spinner-zone,
.generic-list-page ::ng-deep .ng-select .ng-spinner-zone,
.fee-settings ::ng-deep .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.self-config-wrapper ::ng-deep .ng-select .ng-arrow-wrapper,
.generic-list-page ::ng-deep .ng-select .ng-arrow-wrapper,
.fee-settings ::ng-deep .ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
  padding-top: 5px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-select .ng-arrow-wrapper,
.generic-list-page ::ng-deep .ng-select .ng-arrow-wrapper,
.fee-settings ::ng-deep .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}

.self-config-wrapper ::ng-deep .ng-select .ng-arrow-wrapper:hover .ng-arrow,
.generic-list-page ::ng-deep .ng-select .ng-arrow-wrapper:hover .ng-arrow,
.fee-settings ::ng-deep .ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666666;
}

.self-config-wrapper ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow,
.generic-list-page ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow,
.fee-settings ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: transparent transparent transparent;
  border-style: none;
  border-width: 0px;
  width: auto !important;
}

.self-config-wrapper ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow:after,
.generic-list-page ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow:after,
.fee-settings ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow:after {
  content: "expand_more";
  font-family: Material Icons;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  color: #001A43;
}

.self-config-wrapper ::ng-deep .ng-select.medium .ng-select-container,
.generic-list-page ::ng-deep .ng-select.medium .ng-select-container,
.fee-settings ::ng-deep .ng-select.medium .ng-select-container {
  min-height: 48px;
}

.self-config-wrapper ::ng-deep .ng-select.medium .ng-select-container .ng-value-container,
.generic-list-page ::ng-deep .ng-select.medium .ng-select-container .ng-value-container,
.fee-settings ::ng-deep .ng-select.medium .ng-select-container .ng-value-container {
  padding-left: 19px;
}

.self-config-wrapper ::ng-deep .ng-select.medium.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.generic-list-page ::ng-deep .ng-select.medium.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.fee-settings ::ng-deep .ng-select.medium.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 15px;
}

.self-config-wrapper ::ng-deep .ng-select.small .ng-select-container,
.generic-list-page ::ng-deep .ng-select.small .ng-select-container,
.fee-settings ::ng-deep .ng-select.small .ng-select-container {
  min-height: 32px;
  height: 32px;
}

.self-config-wrapper ::ng-deep .ng-select.small .ng-select-container .ng-value-container,
.generic-list-page ::ng-deep .ng-select.small .ng-select-container .ng-value-container,
.fee-settings ::ng-deep .ng-select.small .ng-select-container .ng-value-container {
  padding-left: 8px;
}

.self-config-wrapper ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.generic-list-page ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.fee-settings ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 3px;
  color: #AFB9C5;
  font-weight: 400;
}

.self-config-wrapper ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.generic-list-page ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.fee-settings ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  line-height: 14px;
  font-size: 12px;
}

.self-config-wrapper ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:before,
.generic-list-page ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:before,
.fee-settings ::ng-deep .ng-select.small.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:before {
  top: 5px;
}

.self-config-wrapper ::ng-deep .ng-select.small.ng-select-multiple .ng-clear-wrapper .ng-clear,
.generic-list-page ::ng-deep .ng-select.small.ng-select-multiple .ng-clear-wrapper .ng-clear,
.fee-settings ::ng-deep .ng-select.small.ng-select-multiple .ng-clear-wrapper .ng-clear {
  line-height: 24px;
}

.self-config-wrapper ::ng-deep .ng-select.small.ng-select-multiple .ng-spinner-loader,
.generic-list-page ::ng-deep .ng-select.small.ng-select-multiple .ng-spinner-loader,
.fee-settings ::ng-deep .ng-select.small.ng-select-multiple .ng-spinner-loader {
  margin-right: -2px;
}

.self-config-wrapper ::ng-deep .ng-select.small .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.generic-list-page ::ng-deep .ng-select.small .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.fee-settings ::ng-deep .ng-select.small .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 6px;
}

.self-config-wrapper ::ng-deep .ng-select.small .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label,
.generic-list-page ::ng-deep .ng-select.small .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label,
.fee-settings ::ng-deep .ng-select.small .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
  font-size: 12px;
}

.self-config-wrapper ::ng-deep .ng-select.small .ng-clear-wrapper,
.generic-list-page ::ng-deep .ng-select.small .ng-clear-wrapper,
.fee-settings ::ng-deep .ng-select.small .ng-clear-wrapper {
  width: 11px;
}

.self-config-wrapper ::ng-deep .ng-select.small .ng-clear-wrapper .ng-clear,
.generic-list-page ::ng-deep .ng-select.small .ng-clear-wrapper .ng-clear,
.fee-settings ::ng-deep .ng-select.small .ng-clear-wrapper .ng-clear {
  line-height: 24px;
}

.self-config-wrapper ::ng-deep .ng-select.small .ng-arrow-wrapper,
.generic-list-page ::ng-deep .ng-select.small .ng-arrow-wrapper,
.fee-settings ::ng-deep .ng-select.small .ng-arrow-wrapper {
  padding-left: 0;
  width: 23px;
}

.self-config-wrapper ::ng-deep .ng-select.small.ng-select-opened .ng-select-container .ng-value-container .ng-input>input,
.generic-list-page ::ng-deep .ng-select.small.ng-select-opened .ng-select-container .ng-value-container .ng-input>input,
.fee-settings ::ng-deep .ng-select.small.ng-select-opened .ng-select-container .ng-value-container .ng-input>input {
  background-color: #ffffff;
}

.self-config-wrapper ::ng-deep .ng-select.small.ng-select-single .ng-select-container .ng-value-container .ng-input,
.generic-list-page ::ng-deep .ng-select.small.ng-select-single .ng-select-container .ng-value-container .ng-input,
.fee-settings ::ng-deep .ng-select.small.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 3px;
  padding-left: 8px;
  padding-right: 8px;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel,
.generic-list-page ::ng-deep .ng-dropdown-panel,
.fee-settings ::ng-deep .ng-dropdown-panel {
  background-color: #ffffff;
  border: 1px solid #4C7FF0;
  border-top-color: #AFB9C5 !important;
  box-shadow: 0px 10px 14px -10px #0000002b;
  left: 0;
  font-size: 13px;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel.ng-select-bottom,
.generic-list-page ::ng-deep .ng-dropdown-panel.ng-select-bottom,
.fee-settings ::ng-deep .ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: transparent !important;
  margin-top: 0;
  border-right-color: #4C7FF0;
  border-left-color: #4C7FF0;
  border-bottom-color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child,
.generic-list-page ::ng-deep .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child,
.fee-settings ::ng-deep .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel.ng-select-top,
.generic-list-page ::ng-deep .ng-dropdown-panel.ng-select-top,
.fee-settings ::ng-deep .ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: transparent !important;
  margin-bottom: -1px;
  border-right-color: #4C7FF0;
  border-left-color: #4C7FF0;
  border-top-color: #4C7FF0 !important;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child,
.generic-list-page ::ng-deep .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child,
.fee-settings ::ng-deep .ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-header,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-header,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-footer,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-footer,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #ebf5ff;
  font-weight: 500;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  padding: 13px;
  white-space: pre-wrap !important;
  margin-bottom: 1px;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #EDF2FE;
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 500;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #EDF2FE;
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #cccccc;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}

.self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label,
.generic-list-page ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label,
.fee-settings ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

[dir="rtl"] .self-config-wrapper ::ng-deep .ng-dropdown-panel,
.generic-list-page ::ng-deep .ng-dropdown-panel,
.fee-settings ::ng-deep .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

.self-config-wrapper ::ng-deep.error .ng-select-container,
.generic-list-page ::ng-deep.error .ng-select-container,
.fee-settings ::ng-deep.error .ng-select-container {
  border-color: #C50000;
}

.self-config-wrapper ::ng-deep .select-svg-template svg g,
.generic-list-page ::ng-deep .select-svg-template svg g,
.fee-settings ::ng-deep .select-svg-template svg g {
  stroke: #4C7FF0;
}

.self-config-wrapper ::ng-deep.no-border ::ng-deep .ng-select-container,
.generic-list-page ::ng-deep.no-border ::ng-deep .ng-select-container,
.fee-settings ::ng-deep.no-border ::ng-deep .ng-select-container {
  border: 0;
}

.self-config-wrapper ::ng-deep.no-border ::ng-deep .ng-dropdown-panel,
.generic-list-page ::ng-deep.no-border ::ng-deep .ng-dropdown-panel,
.fee-settings ::ng-deep.no-border ::ng-deep .ng-dropdown-panel {
  border: 0;
}

::ng-deep .c-notifications {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}

::ng-deep .c-notifications .notification-text-box {
  max-width: 85%;
}

::ng-deep .c-notifications .type {
  margin-right: 16px;
}

::ng-deep .c-notifications .close {
  font-size: 16px;
  cursor: pointer;
}

::ng-deep .c-notifications.info {
  background-color: #EDF2FE;
}

::ng-deep .c-notifications.info .type {
  color: #4C7FF0;
}

::ng-deep .c-notifications.info p {
  color: #4C7FF0;
}

::ng-deep .c-notifications.info p.summary {
  color: #00338E;
}

::ng-deep .c-notifications.info .close {
  color: #4C7FF0;
}

::ng-deep .c-notifications.error {
  background-color: #FFC5C5;
}

::ng-deep .c-notifications.error .type {
  color: #C50000;
}

::ng-deep .c-notifications.error p {
  color: #C50000;
}

::ng-deep .c-notifications.error p.summary {
  color: #C50000;
}

::ng-deep .c-notifications.error .close {
  color: #C50000;
}

::ng-deep .c-notifications.warning {
  background-color: #F8E8DD;
}

::ng-deep .c-notifications.warning .type {
  color: #D8834B;
}

::ng-deep .c-notifications.warning p {
  color: #CF6625;
}

::ng-deep .c-notifications.warning p.summary {
  color: #CF6625;
}

::ng-deep .c-notifications.warning .close {
  color: #CF6625;
}

::ng-deep .c-notifications.success {
  background-color: #ACFFB5;
}

::ng-deep .c-notifications.success .type {
  color: #007311;
}

::ng-deep .c-notifications.success p {
  color: #007311;
}

::ng-deep .c-notifications.success p.summary {
  color: #007311;
}

::ng-deep .c-notifications.success .close {
  color: #009514;
}

.c-input-radio {
  position: relative;
  display: inline-block;
  line-height: 17px;
}

.c-input-radio .cb-ui {
  border: 1px solid #5B6874;
  width: 17px;
  height: 17px;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  pointer-events: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  border-radius: 50%;
}

.c-input-radio .cb-ui:before {
  position: absolute;
  background-color: #4C7FF0;
  visibility: hidden;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  top: 3px;
  left: 3px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.c-input-radio .cb-label {
  line-height: 17px;
  vertical-align: middle;
}

.c-input-radio input {
  width: 100%;
  height: 17px;
  visibility: hidden;
  z-index: 2;
  position: absolute;
}

.c-input-radio input:checked~.cb-ui {
  border-color: #4C7FF0;
}

.c-input-radio input:checked~.cb-ui:before {
  visibility: visible;
}

.c-input-radio input:checked~.cb-label {
  color: #4C7FF0;
}

.c-input-radio:hover .cb-ui,
.c-input-radio:hover .cb-label {
  border-color: #4C7FF0;
}

::ng-deep .c-status-ui {
  position: relative;
  background-color: #EBF0F5;
  padding: 0 8px 0 21px;
  line-height: 24px;
  display: inline-block;
  border-radius: 4px;
  font-size: 13px;
}

::ng-deep .c-status-ui:before {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 8px;
  height: 8px;
  content: '.';
  text-indent: -9999px;
  overflow: hidden;
  display: block;
  visibility: visible;
  border-radius: 50%;
  background-color: #2F3943;
  transform: translateY(-50%);
}

::ng-deep .c-status-ui.active {
  background-color: #E8F3EA;
  color: #007311;
}

::ng-deep .c-status-ui.active:before {
  background-color: #007311;
}

::ng-deep .c-status-ui.inactive {
  background-color: #EBF0F5;
  color: #2F3943;
}

::ng-deep .c-status-ui.inactive:before {
  background-color: #2F3943;
}

::ng-deep .dropdown-menu {
  position: absolute;
  top: 38px;
  left: 0;
  background-color: #ffffff;
  list-style-type: none;
  z-index: 10;
  box-shadow: 0px 3px 6px #00000012;
  border: 1px solid #CFD8E1;
  border-radius: 4px;
  min-width: 160px;
  opacity: 0;
  visibility: hidden;
}

::ng-deep .dropdown-menu li {
  display: block;
  white-space: nowrap;
}

::ng-deep .dropdown-menu li a {
  display: block;
  padding: 8px 12px;
  line-height: 24px;
  color: #0F2E4E;
  transition: 0.2s ease-in-out;
}

::ng-deep .dropdown-menu li a:hover {
  background-color: #EBF0F5;
}

::ng-deep .dropdown-menu.right-align {
  left: auto;
  right: 0;
}

::ng-deep .dropdown-menu.active {
  opacity: 1;
  visibility: visible;
}

::ng-deep :root {
  --color-assets-primary: #4c7ff0;
  --color-assets-primary-tint: #7a89fd;
  --color-assets-primary-shade: #4e61ef;
  --color-assets-secondary: #2b2d42;
  --color-assets-secondary-tint: #404255;
  --color-assets-secondary-shade: #26283a;
  --color-assets-light: #f6fafd;
  --navigation-text: #515996;
  --navigation-text-hover: #515996;
  --navigation-icon: #4b91bf;
  --navigation-bg: #fff;
  --navigation-border: #d7e0e7;
  --navigation-submenu-text: #000000;
  --navigation-switch-color: #ffffff;
  --navigation-sidebar-shadow: #0000000a;
  --navigation-submenu-hover: #f8f9fa;
  --navigation-program-panel-bg: #5e71fe;
  --navigation-program-panel-shadow: #222f96;
  --navigation-program-panel-arrow: #222f96;
  --navigation-settings-user-name: #222f96;
  --navigation-border-color: #e9ecef;
  --color-body-txt-default: #2b2d42;
  --color-body-txt-secondary: #5f6d89;
  --color-border-primary: #e9ecef;
  --color-border-secondary: #ebeef3;
  --header-bg-color: #4c7ff0;
  --header-icon-color: #ffffff;
  --header-icon-hover-color: #3f72e3;
  --responsive-detail-header: #4c7ff0;
  --responsive-detail-header-color: #ffffff;
}

::ng-deep a {
  color: #00338E;
}

::ng-deep a:hover {
  color: #0055FF;
}

::ng-deep .page-title-main {
  border-bottom: 1px solid #EBF0F5;
}

::ng-deep .page-title-main .page-title a {
  display: inherit;
}

::ng-deep .invoice-config .invoice-form .invoice-c-input {
  width: 84%;
}

::ng-deep .invoice-config .invoice-form .invoice-c-dropdown {
  width: 50%;
}

::ng-deep .invoice-config .invoice-form .active-inactive-toggle .toggle-panel-inner {
  width: 100%;
}

::ng-deep .invoice-config .invoice-form .select-unselect {
  color: #00338E;
}

::ng-deep .invoice-config .invoice-form .checkbox-wrapper .checkbox-container {
  font-size: 13px;
  color: #001A43;
}

::ng-deep .invoice-config .invoice-form .checkbox-wrapper .checkbox-container:hover {
  color: #4C7FF0;
}

::ng-deep .invoice-config .invoice-form .checkbox-wrapper .checkbox-container input {
  left: 0;
}

::ng-deep .invoice-config .invoice-form .checkbox-wrapper .checkbox-container input:checked~.checkmark {
  background-color: #4C7FF0;
  border: 1px solid #4C7FF0;
}

::ng-deep .invoice-config .invoice-form .checkbox-wrapper .checkmark {
  height: 15px;
  width: 15px;
  border-radius: 0;
  border: 1px solid #5B6874;
}

::ng-deep .invoice-config .invoice-form .checkbox-wrapper .checkmark::after {
  top: -1px;
  left: -1px;
}

::ng-deep .invoice-config-inner .list-heading {
  border-top: 1px solid #EBF0F5;
}

::ng-deep .invoice-config-inner .list-heading p {
  color: #4C7FF0;
}

::ng-deep .invoice-config-inner .list-heading .list-subHeading {
  color: #577088;
}

::ng-deep .toggle-panel-inner {
  border: 1px solid #EDF2FE;
  background-color: #EDF2FE;
}

::ng-deep .toggle-panel-inner .toggle-switch {
  background: #d7e0e7;
  width: 30px;
  height: 16px;
}

::ng-deep .toggle-panel-inner .toggle-switch.toggle-on {
  background-color: #4C7FF0;
}

::ng-deep .toggle-panel-inner .toggle-switch.toggle-on::before {
  transform: translate(14px);
}

::ng-deep .invoice-config .invoice-form .invoice-c-input {
  width: 84%;
}

::ng-deep .invoice-config .invoice-form .invoice-c-dropdown {
  width: 50%;
}

::ng-deep .invoice-config .invoice-form .active-inactive-toggle {
  width: 15%;
  margin-top: 25px;
}

::ng-deep .invoice-config .invoice-form .active-inactive-toggle .toggle-panel-inner {
  width: 100%;
}

::ng-deep .invoice-config .invoice-form .select-unselect {
  color: #00338E;
}

::ng-deep .invoice-config .toggle-panel-inner {
  border: 1px solid #EDF2FE;
  background-color: #EDF2FE;
}

::ng-deep .invoice-config .toggle-panel-inner .toggle-switch {
  background: #d7e0e7;
  width: 32px;
  height: 16px;
}

::ng-deep .invoice-config .toggle-panel-inner .toggle-switch.toggle-on {
  background-color: #4C7FF0;
}

::ng-deep .invoice-config .toggle-panel-inner .toggle-switch.toggle-on::before {
  transform: translate(14px);
}

::ng-deep .invoice-config-inner .list-heading {
  border-top: 1px solid #EBF0F5;
}

::ng-deep .invoice-config-inner .list-heading p {
  color: #4C7FF0;
}

::ng-deep .invoice-config-inner .list-heading .list-subHeading {
  color: #577088;
}

::ng-deep .tab-inner-parent .tab-inner {
  padding: 16px 24px;
  border-top: 1px solid #57708824;
}

::ng-deep .tab-inner-parent .tab-inner i.invoice-icon {
  color: #5b6874;
  font-size: 20px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-left-content {
  width: 60%;
  padding-right: 20px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-left-content h2 {
  font-size: 12px;
  color: #5B6874;
  font-weight: 500;
}

::ng-deep .tab-inner-parent .tab-inner .tab-left-content h4 {
  font-size: 13px;
  color: #0F2E4E;
  font-weight: 500;
  margin-bottom: 0;
}

::ng-deep .tab-inner-parent .tab-inner .tab-left-content p {
  font-size: 12px;
  color: #577088;
}

::ng-deep .tab-inner-parent .tab-inner .tab-left-content i {
  font-size: 22px;
  color: #2D75FF;
  margin-right: 20px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-left-content i.invoice-icon {
  color: #5b6874;
  font-size: 20px;
  position: absolute;
  left: 24px;
  top: 35%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content {
  width: 35%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content ng-select,
::ng-deep .tab-inner-parent .tab-inner .tab-right-content .c-form-control,
::ng-deep .tab-inner-parent .tab-inner .tab-right-content textarea {
  width: 100%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content textarea {
  border: 1px solid #CFD8E1;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .btn.bg-lightblue {
  font-size: 13px;
  color: #2D75FF;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .c-timepicker fieldset {
  border: 0;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .checkbox-item {
  list-style-type: none;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input.percentage-input .ng-select-input-inner {
  width: 100%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input.percentage-input .ng-select-input-inner span {
  left: 1px;
  top: 1px;
  height: 100%;
  height: 38px;
  width: 40px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input.percentage-input .ng-select-input-inner input.c-form-control {
  height: 40px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input.percentage-input .ng-select-input-inner input.c-form-control.no-left-border {
  padding-left: 56px;
  border-left: 1px solid #CFD8E1;
  border-radius: 4px 0 0 4px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input.percentage-input .ng-select-input-inner input.c-form-control:focus {
  border-left: 1px solid #0089FF !important;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner {
  width: 100%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner input.c-form-control {
  height: 40px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner input.c-form-control.no-left-border {
  border-radius: 0 4px 4px 0;
  border-left: 0;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner input.c-form-control.focussed-left-bord:focus {
  border-left: 1px solid #0089FF;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner .c-form-control {
  width: 25%;
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner .c-form-control:focus {
  border-right: 1px solid #0089FF !important;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner .c-form-control.wo-border-left {
  border-right: 1px solid #CFD8E1;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner .c-form-control.wo-border-left:focus {
  border-left: 1px solid #0089FF !important;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner ng-select {
  width: 75%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner ng-select .ng-select-container {
  border-radius: 0 4px 4px 0;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner ng-select.input-both-side {
  width: 25%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner.ng-select-input-numb-inner ng-select.input-both-side .ng-select-container {
  border-radius: 0;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner ng-select {
  width: 50%;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner ng-select .ng-select-container {
  border-radius: 4px 0 0 4px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content.ng-select-input .ng-select-input-inner span.bg-lightblue {
  border-radius: 4px 0 0 4px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .checkbox-item {
  list-style-type: none;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .checkbox-item li {
  display: inline-block;
  margin-left: 24px;
  margin-bottom: 8px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .span-input {
  flex-direction: row-reverse;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .span-input input:hover~span {
  border-left: 1px solid #A3B0C0 !important;
  border-top: 1px solid #A3B0C0 !important;
  border-bottom: 1px solid #A3B0C0 !important;
}

::ng-deep .tab-inner-parent .tab-inner .tab-right-content .span-input input:focus~span {
  border-left: 1px solid #0089FF !important;
  border-top: 1px solid #0089FF !important;
  border-bottom: 1px solid #0089FF !important;
}

::ng-deep .header-layout {
  margin: 0 0 0 75px;
}

::ng-deep .header-layout .header-layout-box {
  border: 1px solid #CFD8E1;
  border-radius: 4px;
  max-width: 600px;
}

::ng-deep .header-layout .header-layout-box p {
  line-height: 18px;
}

::ng-deep .header-layout .address-box {
  width: 48%;
}

::ng-deep .header-layout .address-box .addrss-logo {
  background-color: #EBF0F5;
  width: 66px;
  height: 38px;
  border-radius: 4px;
}

::ng-deep .parent-item .tab-inner {
  padding-left: 75px;
}

::ng-deep .child-item {
  margin-left: 75px;
}

::ng-deep .child-item .tab-left-content .c-text-content {
  padding-left: 40px;
}

::ng-deep .child-item .tab-left-content .c-text-content .c-text-content-inner::before {
  position: absolute;
  display: block;
  visibility: visible;
  content: ".";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #aac8ff;
  left: -40px;
  text-indent: -9999px;
  overflow: hidden;
  top: 14%;
}

::ng-deep .child-item .tab-left-content .c-text-content .c-text-content-inner::after {
  position: absolute;
  display: block;
  visibility: visible;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  width: 1px;
  height: 18px;
  background-color: #aac8ff;
  left: -35px;
  top: 50%;
}

::ng-deep .child-item .tab-inner {
  padding-left: 0;
}

::ng-deep .child-item .tab-left-content {
  width: 55%;
}

::ng-deep .child-item .tab-right-content {
  width: 35%;
}

::ng-deep .sticky-header {
  position: sticky;
  top: 161px;
  z-index: 3;
  background-color: #ffffff;
}

::ng-deep .sticky-header.stuck {
  background-color: #FAFCFF;
  color: #4C7FF0;
  border-bottom: 1px solid #EBF0F5;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

::ng-deep .break-ui {
  background-color: #FFF5C546;
  border: 1px solid #F3D7C4;
  border-radius: 4px;
  padding: 16px;
  margin: 8px 24px 0 75px;
  position: relative;
}

::ng-deep .break-ui p i {
  font-size: 16px;
}

::ng-deep .break-ui p i.green {
  color: #6AC100;
}

::ng-deep .break-ui p i.red {
  color: #FF0000;
}

::ng-deep .break-ui p.dark {
  color: #001A43;
}

::ng-deep .break-ui p .values {
  color: #001A43;
}

::ng-deep .upload-box {
  border: 1px solid #CFD8E1;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
}

::ng-deep .upload-box p {
  line-height: 40px;
}

::ng-deep .upload-box .file-type {
  background-color: #EDF2FE;
  color: #4C7FF0;
  font-weight: 500;
  padding: 0 12px;
}

::ng-deep .upload-box .path-container {
  padding: 0 60px 0 12px;
  color: #0F2E4E;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::ng-deep .upload-box .path-container i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 1;
  cursor: pointer;
  font-size: 18px;
  color: #FF0000;
}

::ng-deep .program-config-page .tab-menu {
  position: fixed;
  top: 161px;
  right: 24px;
  left: 60px;
  width: auto !important;
  z-index: 5;
}

::ng-deep .program-config-page .tab-menu ul li a {
  font-size: 13px !important;
}

::ng-deep .program-config-page .tab-panel {
  padding-top: 49px !important;
}

::ng-deep .program-config-page .tab-inner {
  padding: 24px 0;
  border-bottom: 1px solid #57708824;
}

::ng-deep .program-config-page .tab-inner .tab-left-content {
  width: 60%;
  padding-right: 20px;
}

::ng-deep .program-config-page .tab-inner .tab-left-content .sub-child-padd {
  padding-left: 42px;
}

::ng-deep .program-config-page .tab-inner .tab-left-content h2 {
  font-size: 12px;
  color: #5B6874;
  font-weight: 500;
}

::ng-deep .program-config-page .tab-inner .tab-left-content .subtitle {
  color: #0F2E4E;
}

::ng-deep .program-config-page .tab-inner .tab-left-content .material-icons {
  font-size: 22px;
  color: #2D75FF;
  margin-right: 20px;
}

::ng-deep .program-config-page .tab-inner .tab-right-content {
  width: 40%;
}

::ng-deep .program-config-page .tab-inner .tab-right-content ng-select,
::ng-deep .program-config-page .tab-inner .tab-right-content .c-form-control,
::ng-deep .program-config-page .tab-inner .tab-right-content textarea {
  width: 65%;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .ng-select-input-inner {
  width: 65%;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .ng-select-input-inner.ng-select-input-numb-inner .c-form-control {
  width: 25%;
  border-radius: 4px 0 0 4px;
  border-right: 0;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .ng-select-input-inner.ng-select-input-numb-inner .c-form-control:focus {
  border-right: 1px solid #0089FF !important;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .ng-select-input-inner.ng-select-input-numb-inner ng-select {
  width: 75%;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .ng-select-input-inner.ng-select-input-numb-inner ng-select .ng-select-container {
  border-radius: 0 4px 4px 0;
  z-index: 1;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .ng-select-input-inner.ng-select-input-numb-inner ng-select .ng-dropdown-panel {
  z-index: 1;
}

::ng-deep .program-config-page .tab-inner .tab-right-content toggle-button {
  width: 32px;
  height: 16px;
}

::ng-deep .program-config-page .tab-inner .tab-right-content toggle-button .toggle-button-switch {
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
  margin-bottom: 0;
}

::ng-deep .program-config-page .tab-inner .tab-right-content toggle-button .toggle-button-text {
  background-color: #d7e0e7;
  width: 32px;
  height: 16px;
}

::ng-deep .program-config-page .tab-inner .tab-right-content toggle-button input:checked~.toggle-button-switch {
  left: 17px;
}

::ng-deep .program-config-page .tab-inner .tab-right-content toggle-button input:checked~.toggle-button-text {
  background-color: #4C7FF0;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .c-switch-tabs {
  overflow: hidden;
  position: relative;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .c-switch-tabs input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .c-switch-tabs label {
  background-color: #ffffff;
  color: #2F3943;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  margin: 0;
  padding: 8px 16px;
  border: 1px solid #5e71fe15;
  transition: all 0.1s ease-in-out;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .c-switch-tabs label:hover {
  cursor: pointer;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .c-switch-tabs input:checked+label {
  background-color: #4C7FF0;
  color: #ffffff;
  box-shadow: none;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .c-switch-tabs label:first-of-type {
  border-radius: 4px 0 0 4px;
}

::ng-deep .program-config-page .tab-inner .tab-right-content .c-switch-tabs label:last-of-type {
  border-radius: 0 4px 4px 0;
}

/*** components ***/
:host {
  width: 32px;
}

toggle-button.c-toggle-button-switch {
  width: 30px;
  height: 16px;
}

toggle-button.c-toggle-button-switch .toggle-button-switch {
  top: 2px !important;
  left: 2px !important;
  width: 12px !important;
  height: 12px !important;
}

toggle-button.c-toggle-button-switch .toggle-button-text {
  background-color: #84919F !important;
  height: 16px !important;
  width: 30px !important;
}

toggle-button.c-toggle-button-switch input[type="checkbox"]:checked~.toggle-button-switch {
  left: 16px !important;
}

toggle-button.c-toggle-button-switch input[type="checkbox"]:checked~.toggle-button-text {
  background-color: #4C7FF0 !important;
}

::ng-deep .section-header {
  position: fixed;
  background-color: #ffffff;
  left: 60px;
  right: 24px;
  z-index: 5;
  top: 56px;
  transition: 0.2s ease-in-out;
  border-bottom: 1px solid #E3E9F0;
  height: 105px;
}

::ng-deep .section-header.page-title-main {
  border-bottom: 1px solid #EBF0F5;
}

::ng-deep .section-header .back-arrow-icon {
  font-size: 18px;
  color: #001A43;
  padding: 12px;
}

::ng-deep .section-header .back-arrow-icon:hover {
  background-color: #EDF2FE;
  color: #4C7FF0;
  border-radius: 4px;
}

.self-config-wrapper ::ng-deep .section-body-content,
.fee-settings ::ng-deep .section-body-content {
  min-height: calc(100vh - 268px);
  margin-top: 105px;
}

.self-config-wrapper ::ng-deep .section-body-content .c-form-group.toggle-width,
.fee-settings ::ng-deep .section-body-content .c-form-group.toggle-width {
  max-width: 135px;
}

.self-config-wrapper ::ng-deep .section-body-content .c-form-group.onboarding-toggle,
.fee-settings ::ng-deep .section-body-content .c-form-group.onboarding-toggle {
  max-width: 160px;
}

.self-config-wrapper ::ng-deep .info-icon,
.fee-settings ::ng-deep .info-icon {
  vertical-align: middle;
  font-size: 16px;
  color: #A3B0C0;
  transition: 0.2s ease-in-out;
}

.self-config-wrapper ::ng-deep .info-icon:hover,
.fee-settings ::ng-deep .info-icon:hover {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .tooltip-icon,
.fee-settings ::ng-deep .tooltip-icon {
  font-size: 16px;
  color: #A3B0C0;
}

.self-config-wrapper ::ng-deep .tooltip-icon:hover,
.fee-settings ::ng-deep .tooltip-icon:hover {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep quill-editor .ql-toolbar,
.fee-settings ::ng-deep quill-editor .ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.self-config-wrapper ::ng-deep quill-editor .ql-toolbar.ql-snow,
.fee-settings ::ng-deep quill-editor .ql-toolbar.ql-snow {
  border-bottom-width: 0;
}

.self-config-wrapper ::ng-deep quill-editor .ql-container.ql-snow,
.fee-settings ::ng-deep quill-editor .ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .content-item:hover,
.fee-settings ::ng-deep .common-checkbox-modal .content-item:hover {
  background-color: #EDF2FE;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .content-item:hover .cb-ui,
.fee-settings ::ng-deep .common-checkbox-modal .content-item:hover .cb-ui {
  border-color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .content-item:hover .cb-label,
.fee-settings ::ng-deep .common-checkbox-modal .content-item:hover .cb-label {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .content-item:active,
.fee-settings ::ng-deep .common-checkbox-modal .content-item:active {
  background-color: #EDF2FE;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block {
  padding: 16px 10px;
  cursor: pointer;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default i,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default i {
  font-size: 16px;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default:hover i,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default:hover i {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default:hover span,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default:hover span {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default .filled,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default .filled {
  display: none;
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default .outlined,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default .outlined {
  display: block;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default.active .filled,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default.active .filled {
  display: block;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default.active .outlined,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default.active .outlined {
  display: none;
}

.self-config-wrapper ::ng-deep .common-checkbox-modal .checkbox-block .default.active span,
.fee-settings ::ng-deep .common-checkbox-modal .checkbox-block .default.active span {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .c-hyperlink,
.fee-settings ::ng-deep .c-hyperlink {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .custom-switch-width,
.fee-settings ::ng-deep .custom-switch-width {
  width: 150px;
}

.self-config-wrapper ::ng-deep .toggle-panel.transparent-bg-toggle,
.fee-settings ::ng-deep .toggle-panel.transparent-bg-toggle {
  width: auto;
}

.self-config-wrapper ::ng-deep .toggle-panel.transparent-bg-toggle .toggle-panel-inner,
.fee-settings ::ng-deep .toggle-panel.transparent-bg-toggle .toggle-panel-inner {
  background-color: transparent;
  border: 0;
}

.self-config-wrapper ::ng-deep .toggle-panel.transparent-bg-toggle .toggle-panel-inner .status-text,
.fee-settings ::ng-deep .toggle-panel.transparent-bg-toggle .toggle-panel-inner .status-text {
  white-space: nowrap;
  margin-left: 8px;
}

.self-config-wrapper ::ng-deep .value-list li,
.fee-settings ::ng-deep .value-list li {
  border-top: 1px solid #e9ecef;
}

.self-config-wrapper ::ng-deep .value-list li:first-child,
.fee-settings ::ng-deep .value-list li:first-child {
  border-top: 0;
}

.self-config-wrapper ::ng-deep .value-list .drag-indicator,
.fee-settings ::ng-deep .value-list .drag-indicator {
  font-size: 18px;
  color: #5B6874;
  cursor: grab;
}

.self-config-wrapper ::ng-deep .value-list .remove-link,
.fee-settings ::ng-deep .value-list .remove-link {
  font-size: 20px;
  color: #A3B0C0;
}

.self-config-wrapper ::ng-deep .value-list .selected-value,
.fee-settings ::ng-deep .value-list .selected-value {
  background-color: #EDF2FE;
  padding: 0 8px;
  border-radius: 4px;
  line-height: 32px;
}

.self-config-wrapper ::ng-deep .value-list .selected-value i,
.fee-settings ::ng-deep .value-list .selected-value i {
  font-size: 12px;
  color: #51C6D5;
  vertical-align: middle;
}

.self-config-wrapper ::ng-deep .permission-edit span,
.fee-settings ::ng-deep .permission-edit span {
  background-color: #EDF2FE;
  color: #001A43;
  font-size: 12px;
  border-radius: 4px;
  line-height: 21px;
}

.self-config-wrapper ::ng-deep .permission-edit i,
.fee-settings ::ng-deep .permission-edit i {
  font-size: 18px;
  color: #4C7FF0;
}

::ng-deep .c-listing-header {
  background-color: #ffffff;
  border-bottom: 1px solid #EBF0F5;
  position: fixed;
  top: 58px;
  left: 60px;
  width: calc(100% - 84px);
  z-index: 6;
}

::ng-deep .c-listing-header .flow-back {
  padding: 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
}

::ng-deep .c-listing-header .flow-back:hover {
  background-color: #EDF2FE;
  color: #4C7FF0;
}

::ng-deep .c-listing-header .informative-bubble {
  background-color: #FFEB8E;
  padding: 0 12px;
  line-height: 22px;
  display: inline-block;
  border-radius: 11px;
  color: #5B4B00;
  font-size: 13px;
}

::ng-deep .c-search-panel {
  border-bottom: 1px solid #EBF0F5;
  position: fixed;
  top: 151px;
  left: 60px;
  width: calc(100% - 84px);
  z-index: 6;
}

::ng-deep .c-search-panel input {
  border: 0;
  width: 100%;
  height: 50px;
  padding: 0 24px 0 53px;
  outline: 0;
  transition: 0.2s ease-in-out;
}

::ng-deep .c-search-panel input:hover {
  border-color: #A3B0C0;
}

::ng-deep .c-search-panel input:hover~i {
  color: #4C7FF0;
}

::ng-deep .c-search-panel input:focus {
  border-color: #0089FF;
}

::ng-deep .c-search-panel input:focus~i {
  color: #4C7FF0;
}

::ng-deep .c-search-panel i {
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  font-size: 19px;
  cursor: pointer;
}

::ng-deep .c-search-panel .filter-applied {
  position: absolute;
  width: 176px;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border-left: 1px solid #EBF0F5;
  min-height: 50px;
}

::ng-deep .c-search-panel .filter-applied p {
  line-height: 50px;
  cursor: pointer;
  padding-right: 24px;
}

::ng-deep .c-search-panel .filter-applied p i {
  position: static;
  vertical-align: middle;
  transform: translateY(0);
}

::ng-deep .c-search-panel .filter-applied p span {
  color: #D3270F;
}

::ng-deep .c-search-panel.filter-active input {
  padding-right: 200px;
}

::ng-deep .table-outer {
  min-height: 445px;
}

::ng-deep .c-listing-table-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 145px;
  padding-bottom: 72px;
}

::ng-deep .c-listing-table-wrapper perfect-scrollbar>.ps.ps--active-x>.ps__rail-x {
  position: fixed;
  height: 15px !important;
  bottom: 57px !important;
  z-index: 5;
  background-color: #fafafa;
  opacity: 1;
  border-top: 1px solid #e8e8e8;
  box-shadow: 0px -1px 6px -6px #000000;
}

::ng-deep .c-listing-table-wrapper.search-disabled {
  margin-top: 92px;
}

::ng-deep .c-listing-table {
  min-width: 100%;
  border: 0;
  border-collapse: separate;
  border-spacing: 0;
}

::ng-deep .c-listing-table thead {
  background-color: #FAFCFF;
}

::ng-deep .c-listing-table thead th {
  color: #001A43;
  font-weight: 500;
  font-size: 13px;
  border-bottom: 1px solid #EBF0F5;
  height: 45px;
  text-align: left;
  white-space: nowrap;
  text-transform: capitalize;
  padding: 8px 0 8px 24px;
  min-width: 150px;
}

::ng-deep .c-listing-table thead th .table-search-input {
  border: 1px solid #CFD8E1;
  width: 100%;
  outline: 0;
  padding: 8px 12px;
  font-size: 12px;
  color: #5B6874;
  border-radius: 4px;
}

::ng-deep .c-listing-table thead th .column-order-cta i {
  color: #4C7FF0;
  vertical-align: middle;
}

::ng-deep .c-listing-table thead th:first-child {
  border-right: 1px solid #EBF0F5;
  position: sticky;
  left: 0;
  z-index: 5;
  background-color: #FAFCFF;
  padding-right: 24px;
}

::ng-deep .c-listing-table thead th:last-child {
  padding-right: 24px;
}

::ng-deep .c-listing-table thead th:last-child app-svms-coloumn-filter {
  display: block;
  min-width: 126px;
}

::ng-deep .c-listing-table thead th .sort-icons {
  margin-left: 8px;
}

::ng-deep .c-listing-table thead th .sort-icons span {
  display: block;
  height: 8px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

::ng-deep .c-listing-table thead th .sort-icons span i {
  font-size: 17px;
  color: #A1A1C4;
  position: relative;
  top: -4px;
}

::ng-deep .c-listing-table thead th .sort-icons span.active i {
  color: #0C43A1;
}

::ng-deep .c-listing-table thead th .ng-select {
  text-transform: none;
}

::ng-deep .c-listing-table tbody tr td {
  padding: 16px 0 16px 24px;
  height: 53px;
  border-bottom: 1px solid rgba(235, 240, 245, 0.44);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  transition: 0.2s ease-in-out;
  font-size: 13px;
}

::ng-deep .c-listing-table tbody tr td span.text-visibility {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 13px;
}

::ng-deep .c-listing-table tbody tr td a {
  color: #00338E;
  font-weight: 500;
}

::ng-deep .c-listing-table tbody tr td a:hover {
  color: #0055FF;
}

::ng-deep .c-listing-table tbody tr td:first-child {
  border-right: 1px solid #EBF0F5;
  padding-right: 80px;
  position: sticky;
  left: 0;
  z-index: 4;
  background-color: #ffffff;
}

::ng-deep .c-listing-table tbody tr td .action-list {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: block;
  line-height: 18px;
  opacity: 0;
  visibility: hidden;
}

::ng-deep .c-listing-table tbody tr td .action-list i {
  vertical-align: middle;
  line-height: 18px;
}

::ng-deep .c-listing-table tbody tr td .action-list:hover {
  border: 1px solid #AFB9C5;
}

::ng-deep .c-listing-table tbody tr td .action-list.active {
  border: 1px solid #AFB9C5;
  opacity: 1;
  visibility: visible;
}

::ng-deep .c-listing-table tbody tr td .action-dropdown-list ul li a {
  color: #001A43;
  font-weight: 400;
}

::ng-deep .c-listing-table tbody tr td:last-child {
  padding-right: 24px;
}

::ng-deep .c-listing-table tbody tr:hover td .action-list {
  opacity: 1;
  visibility: visible;
}

::ng-deep .c-listing-table tbody tr.active td {
  background-color: #fafcff !important;
}

::ng-deep .c-listing-table .ng-select ::ng-deep .ng-select-container {
  min-height: 32px;
}

::ng-deep .c-listing-table .ng-select.ng-select-single ::ng-deep .ng-select-container {
  height: 32px;
}

::ng-deep .c-listing-table.cloned {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  pointer-events: none;
}

::ng-deep .c-listing-table.cloned thead tr th {
  opacity: 0;
  visibility: hidden;
}

::ng-deep .c-listing-table.cloned thead tr th:first-child {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #FAFCFF;
}

::ng-deep .c-listing-table.cloned tbody tr td {
  opacity: 0;
  visibility: hidden;
}

::ng-deep .c-listing-table.cloned tbody tr td:first-child {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #ffffff;
}

::ng-deep .c-listing-table .highlighted-bg {
  background-color: #EDF2FE;
  line-height: 22px;
  padding: 0 8px;
  border-radius: 4px;
  margin-right: 6px;
  display: inline-block;
  color: #001A43;
  font-size: 13px;
}

::ng-deep .c-listing-footer {
  position: fixed;
  bottom: 0;
  left: 60px;
  width: calc(100% - 84px);
  z-index: 5;
  background-color: #ffffff;
  border-top: 1px solid #E3E9F0;
  min-height: 58px;
}

::ng-deep .c-listing-footer .c-pagination a {
  line-height: 28px;
  height: 28px;
  border-radius: 4px;
  color: #001A43;
  display: inline-block;
  text-align: center;
  padding: 0 8px;
  margin-left: 4px;
  vertical-align: middle;
  font-size: 14px;
  border: 1px solid transparent;
  transition: 0.2s ease-in-out;
}

::ng-deep .c-listing-footer .c-pagination a i {
  vertical-align: middle;
  color: #1D252D;
}

::ng-deep .c-listing-footer .c-pagination a icon i {
  line-height: 28px;
}

::ng-deep .c-listing-footer .c-pagination a:hover {
  border: 1px solid #E3E9F0;
}

::ng-deep .c-listing-footer .c-pagination a.active {
  background-color: #4C7FF0;
  color: #ffffff;
}

::ng-deep .c-listing-footer .c-pagination .drop_div {
  width: 205px;
}

::ng-deep .c-listing-footer .ng-select ::ng-deep .ng-select-container {
  min-height: 32px !important;
}

::ng-deep .c-listing-footer .ng-select.ng-select-single ::ng-deep .ng-select-container {
  height: 32px !important;
}

::ng-deep .action-dropdown-list {
  background-color: #ffffff;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px #0000000B;
  z-index: 20000;
  position: absolute;
  display: none;
  right: 10px;
  margin-top: 10px;
}

::ng-deep .action-dropdown-list ul {
  list-style-type: none;
  border: 1px solid #CFD8E1;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  background: #ffffff;
}

::ng-deep .action-dropdown-list ul li a {
  line-height: 40px;
  padding: 0 16px;
  display: block;
  color: #001A43;
  font-weight: 400;
  white-space: nowrap;
  font-size: 13px;
}

::ng-deep .action-dropdown-list ul li a i {
  float: left;
  font-size: 18px;
  color: #001A43;
  margin: 12px 12px 0 0;
  font-size: 20px;
}

::ng-deep .action-dropdown-list ul li a:hover {
  text-decoration: none;
  background: #fafcff;
  color: #1D252D;
}

::ng-deep .action-dropdown-list ul li:first-child {
  border-top: 0;
}

::ng-deep .action-dropdown-list.active {
  display: block;
}

::ng-deep .action-dropdown-list.active.flip {
  transform: translateY(-118%);
}

::ng-deep .col-settings {
  border-top: 1px solid #EBF0F5;
}

::ng-deep .col-settings .col-settings-sidebar {
  width: 35%;
  background-color: #FAFCFF;
  border-right: 1px solid #EBF0F5;
}

::ng-deep .col-settings .col-settings-sidebar ul {
  list-style-type: none;
  margin-top: 8px;
}

::ng-deep .col-settings .col-settings-sidebar ul li {
  padding: 8px 0;
}

::ng-deep .col-settings .col-settings-sidebar ul li.disabled {
  opacity: .5;
  pointer-events: none;
}

::ng-deep .col-settings .col-settings-content {
  width: 65%;
}

::ng-deep .col-settings .col-settings-content .column-item {
  border-bottom: 1px solid #EBF0F5;
  padding: 16px 0;
}

::ng-deep .col-settings .col-settings-content .column-item .dragable-icon {
  font-size: 18px;
  color: #5B6874;
}

::ng-deep .col-settings .col-settings-content .column-item .item-status {
  visibility: hidden;
}

::ng-deep .col-settings .col-settings-content .column-item .item-status i {
  font-size: 14px;
  color: #84919F;
}

::ng-deep .col-settings .col-settings-content .column-item.disabled .dragable-icon {
  color: #CFD8E1;
}

::ng-deep .col-settings .col-settings-content .column-item.disabled .item-status {
  visibility: visible;
}

::ng-deep .create-view-table-main {
  border: 1px solid #E3E9F0;
  border-radius: 4px;
}

::ng-deep .create-view-table-main .top-head {
  border-bottom: 1px solid #E3E9F0;
  background-color: #FAFCFF;
}

::ng-deep .create-view-table-main .top-head p {
  color: #4C7FF0;
}

::ng-deep .create-view-table-main .top-head span {
  font-size: 12px;
  color: #5B6874;
}

::ng-deep .create-view-table-main .create-view-table {
  color: #0F2E4E;
}

::ng-deep .create-view-table-main .create-view-table th {
  background-color: #FAFCFF;
  text-align: left;
  font-size: 12px;
  padding: 8px 16px;
  height: 56px;
}

::ng-deep .create-view-table-main .create-view-table th span {
  position: relative;
}

::ng-deep .create-view-table-main .create-view-table th span .sorting {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  width: 15px;
  height: 18px;
}

::ng-deep .create-view-table-main .create-view-table th span .sorting span {
  position: absolute;
  color: #AFB9C5;
  cursor: pointer;
  left: 0;
  top: 3px;
  height: 10px;
  width: 15px;
  overflow: hidden;
  display: block;
}

::ng-deep .create-view-table-main .create-view-table th span .sorting span i {
  position: absolute;
  top: -5px;
  left: -5px;
  font-size: 17px;
}

::ng-deep .create-view-table-main .create-view-table th span .sorting span.dec {
  top: 11px;
}

::ng-deep .create-view-table-main .create-view-table td {
  font-size: 13px;
  border-top: 1px solid #EBF0F5;
  padding: 8px 16px;
  height: 56px;
}

::ng-deep .create-view-table-main .create-view-table td i {
  color: #FF0000;
}

::ng-deep .create-view-table-main .create-view-table td a {
  color: #0F2E4E;
}

::ng-deep .create-view-table-main .create-view-table td.no-document-td {
  height: 300px;
}

.generic-list-page ::ng-deep .c-listing-header,
.fee-settings ::ng-deep .c-listing-header {
  top: 51px;
  left: 225px;
  width: calc(100% - 225px);
}

.generic-list-page ::ng-deep .c-listing-footer,
.fee-settings ::ng-deep .c-listing-footer {
  left: 225px;
  width: calc(100% - 225px);
}

.generic-list-page ::ng-deep perfect-scrollbar>.ps.ps--active-x>.ps__rail-x,
.fee-settings ::ng-deep perfect-scrollbar>.ps.ps--active-x>.ps__rail-x {
  left: 225px !important;
}

::ng-deep .toggle-box {
  background-color: #ffffff;
  border: 1px solid #CFD8E1;
  transition: 0.2s ease-in-out;
  border-radius: 4px;
}

::ng-deep .toggle-box p.title {
  color: #001A43;
}

::ng-deep .toggle-box a {
  color: #4C7FF0;
}

::ng-deep .toggle-box .box-cb {
  flex-wrap: wrap;
  gap: 24px;
}

::ng-deep .toggle-box .box-cb label {
  margin-bottom: 0;
}

::ng-deep .toggle-box:hover {
  border-color: #AFB9C5;
}

::ng-deep .toggle-box.active {
  background-color: #FAFCFF;
}

::ng-deep .toggle-box .sub-item-box {
  margin-right: -24px;
}

::ng-deep .toggle-box .sub-item-arrow {
  color: #2D75FF;
  font-size: 18px;
}

:host {
  width: 32px;
}

toggle-button.c-toggle-button-switch {
  width: 30px;
  height: 16px;
}

toggle-button.c-toggle-button-switch .toggle-button-switch {
  top: 2px !important;
  left: 2px !important;
  width: 12px !important;
  height: 12px !important;
}

toggle-button.c-toggle-button-switch .toggle-button-text {
  background-color: #84919F !important;
  height: 16px !important;
  width: 30px !important;
}

toggle-button.c-toggle-button-switch input[type="checkbox"]:checked~.toggle-button-switch {
  left: 16px !important;
}

toggle-button.c-toggle-button-switch input[type="checkbox"]:checked~.toggle-button-text {
  background-color: #4C7FF0 !important;
}

::ng-deep .selected-items-ui {
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
}

::ng-deep .selected-items-ui .selected-item {
  width: 150px;
  background-color: #EDF2FE;
  border-radius: 4px;
  font-size: 14px;
  color: #001A43;
}

::ng-deep .selected-items-ui .selected-item i {
  font-size: 15px;
}

::ng-deep .selected-items-ui .selected-item p {
  color: #5B6874;
}

::ng-deep .selected-items-ui .selected-item .item-code {
  color: #84919F;
}

::ng-deep .selected-items-ui .selected-item .toggle-panel-inner {
  background-color: transparent;
  padding: 0;
  border: 0;
}

::ng-deep .self-config-wrapper .datepicker--imgdate .datepicker--imagesize {
  right: 8px;
  left: unset;
}

::ng-deep .self-config-wrapper .datepicker--imgdate .form-control {
  border: 1px solid #CFD8E1;
  background-color: #ffffff;
  border-radius: 4px;
  outline: none;
  padding: 8px 32px 8px 16px !important;
  font-size: 13px;
  font-family: Inter;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  height: 40px;
  transition: 0.2s ease-in-out;
  color: #003D46;
}

::ng-deep .self-config-wrapper .datepicker--imgdate .form-control:hover {
  border-color: #A3B0C0;
}

::ng-deep .self-config-wrapper .datepicker--imgdate .form-control:focus {
  border-color: #4C7FF0;
}

::ng-deep .self-config-wrapper .datepicker--cell span {
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  color: #0F2E4E;
}

::ng-deep .self-config-wrapper .datepicker--cell.-current- {
  background-color: transparent !important;
}

::ng-deep .self-config-wrapper .datepicker--cell.-current- span {
  background-color: #ebeef3;
}

::ng-deep .self-config-wrapper .datepicker--cell:hover:not(.-disabled-) {
  background-color: transparent;
}

::ng-deep .self-config-wrapper .datepicker--cell:hover:not(.-disabled-) span {
  color: #fff;
  background: #4C7FF0;
  border-radius: 50%;
}

::ng-deep .self-config-wrapper .datepicker--cell-day.-other-month- span,
::ng-deep .self-config-wrapper .datepicker--cell-year.-other-decade- span {
  color: #A3B0C0;
}

::ng-deep .self-config-wrapper .datepicker--cell.-selected- {
  border-radius: 50%;
  background-color: transparent;
}

::ng-deep .self-config-wrapper .datepicker--cell.-selected- span {
  color: #fff;
  background: #4C7FF0;
}

::ng-deep .self-config-wrapper .datepicker-responsive .datepicker--cell:hover:not(.-disabled-) {
  color: #fff;
  background: #4C7FF0;
  border-radius: 50%;
}

::ng-deep .self-config-wrapper .datepicker-responsive .datepicker--cell.-current- {
  background-color: #ebeef3 !important;
  border-radius: 50%;
}

::ng-deep .self-config-wrapper .datepicker-responsive .datepicker--cell.-current-:hover {
  color: #fff !important;
  background: #4C7FF0 !important;
}

::ng-deep .self-config-wrapper .datepicker-responsive .datepicker--cell.-selected- {
  background-color: #4C7FF0 !important;
  border-radius: 50%;
}

::ng-deep .self-config-wrapper .datepicker-responsive .datepicker--cell.-selected-:hover {
  color: #fff !important;
  background: #4C7FF0 !important;
}

::ng-deep .self-config-wrapper .datepicker-responsive .datepicker--cell.-in-range- {
  color: #0F2E4E !important;
  background: #EDF2FE !important;
  border-radius: 0;
}

::ng-deep .self-config-wrapper .datepicker-responsive .datepicker--cell.-in-range-:hover {
  color: #0F2E4E !important;
  background: #EDF2FE !important;
  border-radius: 0;
}

::ng-deep .svms-modal {
  background-color: #ffffff;
  width: 95%;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  z-index: 1001;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

::ng-deep .svms-modal .svms-modal-header {
  padding: 24px 16px;
}

::ng-deep .svms-modal .svms-modal-header h4 {
  color: #001A43;
}

::ng-deep .svms-modal .svms-modal-body {
  padding: 0 16px 24px 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
}

::ng-deep .svms-modal .svms-modal-body .c-search-panel-wrapper {
  height: 53px;
}

::ng-deep .svms-modal .svms-modal-body .c-search-panel-wrapper .c-search-panel {
  border-top: 1px solid #EBF0F5;
  width: auto !important;
  top: 0;
  left: -16px !important;
  right: -16px;
}

::ng-deep .svms-modal .svms-modal-footer {
  border-top: 1px solid #CFD8E1;
  padding: 16px;
}

::ng-deep .svms-modal .close-modal {
  position: absolute;
  top: 24px;
  right: 16px;
  z-index: 10;
}

::ng-deep .svms-modal .close-modal i {
  font-size: 18px;
  color: #001A43;
}

::ng-deep .svms-modal.small {
  max-width: 400px;
}

::ng-deep .svms-modal.medium {
  max-width: 800px;
}

::ng-deep .svms-modal.large {
  max-width: 1000px;
}

::ng-deep .svms-modal.x-large {
  max-width: 1500px;
}

::ng-deep .svms-modal.active {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
  max-height: 4000px;
}

::ng-deep .svms-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1F2031;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease-in-out;
}

::ng-deep .svms-modal-overlay.active {
  opacity: .85;
  visibility: visible;
}

::ng-deep .header-layout-popup {
  border: 1px solid #CFD8E1;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

::ng-deep .header-layout-popup .address-box {
  flex-basis: 40%;
}

::ng-deep .header-layout-popup .address-box p {
  line-height: 18px;
}

::ng-deep .header-layout-popup .addrss-logo {
  background-color: #EBF0F5;
  border-radius: 4px;
  overflow: hidden;
  width: 66px;
  height: 38px;
}

::ng-deep .header-layout-popup .addrss-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

::ng-deep .header-layout-popup.active {
  background-color: #EDF2FE;
}

::ng-deep .header-layout-popup.active .addrss-logo {
  background-color: #AAC8FF;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item-parent a i,
.fee-settings ::ng-deep .view-detail-page .detail-item-parent a i {
  margin-right: 6px;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item-parent a.Enabled,
.fee-settings ::ng-deep .view-detail-page .detail-item-parent a.Enabled {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item-parent a.Disabled,
.fee-settings ::ng-deep .view-detail-page .detail-item-parent a.Disabled {
  color: #A3B0C0;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item-parent .sub-item .item-label i,
.fee-settings ::ng-deep .view-detail-page .detail-item-parent .sub-item .item-label i {
  color: #00338E;
  font-size: 17px;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-label,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-label {
  width: 30%;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-value,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-value {
  width: 65%;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-value ul,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-value ul {
  list-style-type: none;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-value ul li,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-value ul li {
  display: inline-block;
  background-color: #EDF2FE;
  padding: 8px;
  color: #001A43;
  margin-right: 8px;
  margin-bottom: 8px;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-value.bordered-paragraph p,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-value.bordered-paragraph p {
  border: 1px dashed #AFB9C5;
  border-radius: 4px;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-value .description-field ol li,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-value .description-field ol li {
  display: list-item;
  list-style-position: inside;
  margin: 0 0 8px 0;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-value .description-field ul,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-value .description-field ul {
  list-style-type: disc;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .item-value .description-field ul li,
.fee-settings ::ng-deep .view-detail-page .detail-item .item-value .description-field ul li {
  display: list-item;
  background-color: transparent;
  padding: 0;
  list-style-position: inside;
  margin: 0 0 8px 0;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .description,
.fee-settings ::ng-deep .view-detail-page .detail-item .description {
  line-height: 24px;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .description span,
.fee-settings ::ng-deep .view-detail-page .detail-item .description span {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .description span.full-text,
.fee-settings ::ng-deep .view-detail-page .detail-item .description span.full-text {
  white-space: initial;
  display: inline;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .description a,
.fee-settings ::ng-deep .view-detail-page .detail-item .description a {
  color: #001A43;
  vertical-align: top;
}

.self-config-wrapper ::ng-deep .view-detail-page .detail-item .description a:hover,
.fee-settings ::ng-deep .view-detail-page .detail-item .description a:hover {
  text-decoration: underline;
}

.self-config-wrapper ::ng-deep .view-detail-page .field-icon[ng-reflect-name="check_circle"] i,
.fee-settings ::ng-deep .view-detail-page .field-icon[ng-reflect-name="check_circle"] i {
  color: #009514;
}

.self-config-wrapper ::ng-deep .view-detail-page .field-icon[ng-reflect-name="cancel"] i,
.fee-settings ::ng-deep .view-detail-page .field-icon[ng-reflect-name="cancel"] i {
  color: #AFB9C5;
}

.self-config-wrapper ::ng-deep .view-detail-page .tick-icon,
.fee-settings ::ng-deep .view-detail-page .tick-icon {
  font-size: 14px;
  color: #009514;
}

.self-config-wrapper ::ng-deep .view-detail-page .cross-icon,
.fee-settings ::ng-deep .view-detail-page .cross-icon {
  font-size: 14px;
  color: #AFB9C5;
}

.self-config-wrapper ::ng-deep .table-view,
.fee-settings ::ng-deep .table-view {
  border: 1px solid #E3E9F0;
  background-color: #FAFCFF;
  border-radius: 4px;
  overflow: hidden;
}

.self-config-wrapper ::ng-deep .table-view a,
.fee-settings ::ng-deep .table-view a {
  color: #4C7FF0;
  text-decoration: underline;
}

.self-config-wrapper ::ng-deep .table-view p.blue-color,
.fee-settings ::ng-deep .table-view p.blue-color {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .table-view table thead th,
.fee-settings ::ng-deep .table-view table thead th {
  border-top: 1px solid #E3E9F0;
  text-align: left;
  position: relative;
  text-transform: capitalize;
}

.self-config-wrapper ::ng-deep .table-view table tbody td,
.fee-settings ::ng-deep .table-view table tbody td {
  border-top: 1px solid #E3E9F0;
  background-color: #ffffff;
}

.self-config-wrapper ::ng-deep .table-view table tbody td.disabled-field,
.fee-settings ::ng-deep .table-view table tbody td.disabled-field {
  background-color: #FAFCFF;
  color: #84919F;
}

.self-config-wrapper ::ng-deep .table-view .sorting,
.fee-settings ::ng-deep .table-view .sorting {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 15px;
  height: 18px;
}

.self-config-wrapper ::ng-deep .table-view .sorting span,
.fee-settings ::ng-deep .table-view .sorting span {
  position: absolute;
  color: #AFB9C5;
  cursor: pointer;
  left: 0;
  top: 0;
  height: 10px;
  width: 15px;
  overflow: hidden;
  display: block;
}

.self-config-wrapper ::ng-deep .table-view .sorting span i,
.fee-settings ::ng-deep .table-view .sorting span i {
  position: absolute;
  top: -7px;
  left: -5px;
}

.self-config-wrapper ::ng-deep .table-view .sorting span.dec,
.fee-settings ::ng-deep .table-view .sorting span.dec {
  top: 11px;
}

.self-config-wrapper ::ng-deep .table-view .table-view-footer,
.fee-settings ::ng-deep .table-view .table-view-footer {
  position: static;
  width: auto;
}

.self-config-wrapper ::ng-deep .table-view .table-view-footer .c-pagination a,
.fee-settings ::ng-deep .table-view .table-view-footer .c-pagination a {
  text-decoration: none;
}

.self-config-wrapper ::ng-deep .table-view .table-view-footer .c-pagination a.active,
.fee-settings ::ng-deep .table-view .table-view-footer .c-pagination a.active {
  color: #ffffff;
}

.self-config-wrapper ::ng-deep .table-view .edit-icon,
.fee-settings ::ng-deep .table-view .edit-icon {
  font-size: 18px;
  color: #00338E;
}

.self-config-wrapper ::ng-deep .detail-page-table thead th,
.fee-settings ::ng-deep .detail-page-table thead th {
  text-align: left;
  border-bottom: 1px solid #EBF0F5;
  position: relative;
  padding-left: 0;
  text-transform: uppercase;
  color: #5B6874;
  font-size: 12px;
}

.self-config-wrapper ::ng-deep .detail-page-table tbody td,
.fee-settings ::ng-deep .detail-page-table tbody td {
  border-bottom: 1px solid #EBF0F5;
  padding-left: 0;
}

.self-config-wrapper ::ng-deep .detail-page-table tbody td .toggle-panel,
.fee-settings ::ng-deep .detail-page-table tbody td .toggle-panel {
  min-width: 130px;
}

.self-config-wrapper ::ng-deep .detail-page-table tbody td .add-more-link,
.fee-settings ::ng-deep .detail-page-table tbody td .add-more-link {
  margin-left: 16px;
}

.self-config-wrapper ::ng-deep .detail-page-table tbody td .add-more-link i,
.fee-settings ::ng-deep .detail-page-table tbody td .add-more-link i {
  color: #4C7FF0;
  vertical-align: middle;
}

.self-config-wrapper ::ng-deep .detail-page-table .sorting,
.fee-settings ::ng-deep .detail-page-table .sorting {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 15px;
  height: 18px;
}

.self-config-wrapper ::ng-deep .detail-page-table .sorting span,
.fee-settings ::ng-deep .detail-page-table .sorting span {
  position: absolute;
  color: #AFB9C5;
  cursor: pointer;
  left: 0;
  top: 0;
  height: 10px;
  width: 15px;
  overflow: hidden;
  display: block;
}

.self-config-wrapper ::ng-deep .detail-page-table .sorting span i,
.fee-settings ::ng-deep .detail-page-table .sorting span i {
  position: absolute;
  top: -7px;
  left: -5px;
}

.self-config-wrapper ::ng-deep .detail-page-table .sorting span.dec,
.fee-settings ::ng-deep .detail-page-table .sorting span.dec {
  top: 11px;
}

.self-config-wrapper ::ng-deep .detail-page-table.no-border thead th,
.fee-settings ::ng-deep .detail-page-table.no-border thead th {
  border-bottom: 0;
  height: auto;
}

.self-config-wrapper ::ng-deep .detail-page-table.no-border tbody td,
.fee-settings ::ng-deep .detail-page-table.no-border tbody td {
  border-bottom: 0;
  height: auto;
}

.self-config-wrapper ::ng-deep .list-view-item .item-description,
.fee-settings ::ng-deep .list-view-item .item-description {
  width: 60%;
}

.self-config-wrapper ::ng-deep .list-view-item ul,
.fee-settings ::ng-deep .list-view-item ul {
  list-style-type: none;
}

.self-config-wrapper ::ng-deep .list-view-item ul li,
.fee-settings ::ng-deep .list-view-item ul li {
  display: inline-block;
  background-color: #EDF2FE;
  line-height: 32px;
  padding: 0 8px;
  font-size: 13px;
  color: #001A43;
  margin-left: 8px;
  border-radius: 4px;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list input,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list input {
  width: 57px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list input~.ng-select .ng-select-container,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list input~.ng-select .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list .ng-select,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list .ng-select {
  width: 130px;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list .add,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list .add {
  color: #4C7FF0;
  display: none;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list .remove,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list .remove {
  color: #FF0000;
  display: block;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list.multi-field .ng-select.before .ng-select-container,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list.multi-field .ng-select.before .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list.multi-field .c-form-control,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list.multi-field .c-form-control {
  border-radius: 0;
  border-left: 0;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list:first-child .add,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list:first-child .add {
  display: block;
}

.self-config-wrapper ::ng-deep .list-view-item .field-edit-mode .field-group-list:first-child .remove,
.fee-settings ::ng-deep .list-view-item .field-edit-mode .field-group-list:first-child .remove {
  display: none;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside,
.fee-settings ::ng-deep .impacted-assignment aside {
  flex-basis: 30%;
  height: calc(100vh - 163px);
  border-right: 1px solid #EBF0F5;
  overflow: auto;
  background-color: #FAFCFF;
  /* Handle */
  /* Handle on hover */
}

.self-config-wrapper ::ng-deep .impacted-assignment aside .impacted-item,
.fee-settings ::ng-deep .impacted-assignment aside .impacted-item {
  border-bottom: 1px solid #EBF0F5;
  border-left: 3px solid transparent;
  padding: 16px 24px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside .impacted-item i,
.fee-settings ::ng-deep .impacted-assignment aside .impacted-item i {
  font-size: 14px;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside .impacted-item p.impacted-item-title,
.fee-settings ::ng-deep .impacted-assignment aside .impacted-item p.impacted-item-title {
  color: #001A43;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside .impacted-item:hover,
.fee-settings ::ng-deep .impacted-assignment aside .impacted-item:hover {
  background-color: #ffffff;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside .impacted-item:hover p.impacted-item-title,
.fee-settings ::ng-deep .impacted-assignment aside .impacted-item:hover p.impacted-item-title {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside .impacted-item.active,
.fee-settings ::ng-deep .impacted-assignment aside .impacted-item.active {
  background-color: #ffffff;
  border-left-color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside .impacted-item.active p.impacted-item-title,
.fee-settings ::ng-deep .impacted-assignment aside .impacted-item.active p.impacted-item-title {
  color: #4C7FF0;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside::-webkit-scrollbar,
.fee-settings ::ng-deep .impacted-assignment aside::-webkit-scrollbar {
  width: 4px;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside::-webkit-scrollbar-track,
.fee-settings ::ng-deep .impacted-assignment aside::-webkit-scrollbar-track {
  background: transparent;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside::-webkit-scrollbar-thumb,
.fee-settings ::ng-deep .impacted-assignment aside::-webkit-scrollbar-thumb {
  background: #AFB9C5;
}

.self-config-wrapper ::ng-deep .impacted-assignment aside::-webkit-scrollbar-thumb:hover,
.fee-settings ::ng-deep .impacted-assignment aside::-webkit-scrollbar-thumb:hover {
  background: #84919F;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item,
.fee-settings ::ng-deep .impacted-assignment .table-view-item {
  flex-basis: 75%;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .sorting,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .sorting {
  position: relative;
  width: 10px;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .sorting i,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .sorting i {
  font-size: 15px;
  color: #A1A1C4;
  cursor: pointer;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .sorting span,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .sorting span {
  position: absolute;
  left: 0;
  height: 15px;
  overflow: hidden;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .sorting .asc,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .sorting .asc {
  top: 0;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .sorting .dec,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .sorting .dec {
  top: 12px;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper {
  height: calc(100vh - 271px);
  overflow: auto;
  /* Handle */
  /* Handle on hover */
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar {
  width: 4px;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar-track,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar-thumb,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar-thumb {
  background: #AFB9C5;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar-thumb:hover,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .table-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #84919F;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item table th,
.fee-settings ::ng-deep .impacted-assignment .table-view-item table th {
  text-transform: uppercase;
  border-bottom: 1px solid #E3E9F0;
  height: 50px;
  font-size: 12px;
  color: #5B6874;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item table tbody tr td,
.fee-settings ::ng-deep .impacted-assignment .table-view-item table tbody tr td {
  border-top: 1px solid #E3E9F0;
  height: 50px;
}

.self-config-wrapper ::ng-deep .impacted-assignment .table-view-item .c-listing-footer,
.fee-settings ::ng-deep .impacted-assignment .table-view-item .c-listing-footer {
  width: auto !important;
  min-height: inherit;
}

::ng-deep .config-item {
  border-top: 1px solid #EBF0F5;
  padding: 16px 24px;
}

::ng-deep .config-item p.blue-color {
  color: #4C7FF0;
}

::ng-deep .config-item .toggle-panel {
  width: auto;
}

::ng-deep .config-item .toggle-panel .toggle-panel-inner {
  border: 0;
  background-color: transparent;
  padding: 0;
  height: 20px;
}

::ng-deep .rule-table table thead tr th {
  border-bottom: 1px solid #E3E9F0;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #5B6874;
  padding: 8px 0;
  height: auto;
}

::ng-deep .rule-table table thead tr th:last-child {
  width: 5%;
}

::ng-deep .rule-table table tbody tr td {
  padding: 8px 0;
  height: auto;
  border-bottom: 1px solid #E3E9F0;
}

::ng-deep .rule-table table tbody tr td p {
  background-color: #EDF2FE;
  color: #0F2E4E;
  font-weight: 500;
  line-height: 40px;
  padding: 0 8px;
  margin-right: 8px;
}

::ng-deep .rule-table table tbody tr td .rule-conditions input {
  max-width: 46px;
  margin-right: 8px;
}

::ng-deep .rule-table table tbody tr td .rule-conditions input.add-rule-value {
  max-width: 80px;
}

::ng-deep .rule-table table tbody tr td .rule-conditions .ng-select {
  min-width: 75px;
  margin-right: 8px;
}

::ng-deep .rule-table table tbody tr td .rule-conditions.add-rule-conditions .ng-select {
  min-width: 100px;
}

::ng-deep .rule-table table tbody tr td .rule-conditions.add-rule-conditions .ng-select:nth-child(2) {
  min-width: 180px;
}

::ng-deep .rule-table table tbody tr td .min-max-break {
  max-width: 94px;
}

::ng-deep .rule-table table tbody tr td i {
  vertical-align: middle;
  color: #FF0000;
  cursor: pointer;
}

::ng-deep .rule-table table tbody tr:first-child .delete-rule {
  opacity: 0;
  visibility: hidden;
}

::ng-deep .light-blue-box {
  background-color: #FAFCFF;
  border: 1px solid #CFD8E1;
  transition: 0.2s ease-in-out;
  border-radius: 4px;
}

::ng-deep .light-blue-box .box-text-content {
  width: calc(100% - 280px);
}

::ng-deep .light-blue-box .box-text-content .title {
  color: #001A43;
}

::ng-deep .light-blue-box .side-ng-select {
  width: 280px;
}

::ng-deep .c-form-box {
  background-color: #FAFCFF;
}

::ng-deep .c-form-box p.dark {
  color: #0F2E4E;
}

::ng-deep .c-child {
  padding-left: 40px;
}

::ng-deep .c-child::before {
  position: absolute;
  display: block;
  visibility: visible;
  content: ".";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #aac8ff;
  left: 0;
  text-indent: -9999px;
  overflow: hidden;
  top: 0;
}

::ng-deep .c-child::after {
  position: absolute;
  display: block;
  visibility: visible;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  width: 1px;
  background-color: #aac8ff;
  left: 5px;
  top: 20px;
  bottom: 20px;
}

::ng-deep .tab-inner-parent .tab-inner .tab-left-content {
  width: 100%;
}

::ng-deep .c-child-first .tab-left-content .c-text-content {
  padding-left: 40px;
}

::ng-deep .c-child-first .tab-left-content .c-text-content .c-text-content-inner::before {
  position: absolute;
  display: block;
  visibility: visible;
  content: ".";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #aac8ff;
  left: -40px;
  text-indent: -9999px;
  overflow: hidden;
  top: 14%;
}

::ng-deep .c-child-first .tab-left-content .c-text-content .c-text-content-inner::after {
  position: absolute;
  display: block;
  visibility: visible;
  content: ".";
  text-indent: -9999px;
  overflow: hidden;
  width: 1px;
  height: 300px;
  background-color: #aac8ff;
  left: -35px;
  top: 100%;
}

::ng-deep .c-child-first .tab-inner {
  padding-left: 0;
}

::ng-deep .c-child-first .tab-left-content {
  width: 100%;
}

::ng-deep .c-child-first .tab-right-content {
  width: 35%;
}

.self-config-wrapper ::ng-deep hierarchy-treeview .form-group {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.self-config-wrapper ::ng-deep hierarchy-treeview ngx-dropdown-treeview .dropdown button.dropdown-toggle {
  border-color: #CFD8E1;
}

.self-config-wrapper ::ng-deep hierarchy-treeview ngx-dropdown-treeview .dropdown .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}

.self-config-wrapper ::ng-deep hierarchy-treeview ngx-dropdown-treeview .dropdown.show button.dropdown-toggle {
  border-color: #4C7FF0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}

.self-config-wrapper ::ng-deep hierarchy-treeview ngx-dropdown-treeview .dropdown.show .dropdown-menu {
  border-color: #4C7FF0;
}

.self-config-wrapper ::ng-deep hierarchy-treeview .badge {
  background-color: #EDF2FE;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 500;
  color: #001A43;
  border-radius: 4px;
  position: relative;
  margin-top: 8px !important;
  margin-bottom: 0 !important;
}

.self-config-wrapper ::ng-deep hierarchy-treeview .badge .close {
  position: relative;
  color: #EDF2FE;
}

.self-config-wrapper ::ng-deep hierarchy-treeview .badge .close:before {
  position: absolute;
  content: "cancel";
  color: #51C6D5;
  top: 5px;
  right: 0;
  font-family: 'Material Icons';
  font-size: 12px;
  line-height: 1;
  text-transform: none;
}

@media screen and (min-width: 1100px) and (max-width: 1500px) {
  ::ng-deep .content.config-active .nav-bar {
    width: 280px;
  }

  ::ng-deep .content.config-active .self-config-wrapper .program-config-page .tab-menu {
    top: 145px;
  }

  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content {
    margin-left: 286px;
  }

  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content .c-header-panel {
    left: 280px !important;
    width: calc(100% - 304px);
  }

  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content .section-header {
    left: 286px !important;
  }

  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content .program-config-page .tab-menu {
    top: 145px;
    left: 286px !important;
  }

  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content .c-listing-header,
  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content .c-listing-footer,
  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content .c-search-panel {
    width: calc(100% - 310px);
    left: 286px;
  }

  ::ng-deep .content.config-active .self-config-wrapper.sidebar-menu-active .c-content .c-listing-table-wrapper perfect-scrollbar .ps__rail-x {
    left: 286px !important;
  }

  ::ng-deep .content.config-active .self-config-wrapper .section-header {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    height: 89px;
  }

  ::ng-deep .content.config-active .self-config-wrapper .section-body-content {
    margin-top: 89px;
    min-height: calc(100vh - 236px);
  }

  ::ng-deep .c-panel.c-panel-active .nav-bar {
    width: 280px;
  }

  ::ng-deep .c-panel.c-panel-active .self-config-wrapper.sidebar-menu-active .c-content {
    margin-left: 286px !important;
  }

  ::ng-deep .c-panel.c-panel-active .self-config-wrapper.sidebar-menu-active .c-content .c-header-panel {
    left: 280px !important;
    width: calc(100% - 304px);
  }

  ::ng-deep .c-panel.c-panel-active .self-config-wrapper.sidebar-menu-active .c-content .section-header {
    left: 286px !important;
  }

  ::ng-deep .c-panel.c-panel-active .self-config-wrapper.sidebar-menu-active .c-content .c-listing-header,
  ::ng-deep .c-panel.c-panel-active .self-config-wrapper.sidebar-menu-active .c-content .c-listing-footer,
  ::ng-deep .c-panel.c-panel-active .self-config-wrapper.sidebar-menu-active .c-content .c-search-panel {
    width: calc(100% - 310px);
    left: 286px;
  }

  ::ng-deep .c-panel.c-panel-active .self-config-wrapper.sidebar-menu-active .c-content .c-listing-table-wrapper perfect-scrollbar .ps__rail-x {
    left: 286px !important;
  }

  ::ng-deep .sticky-header {
    top: 144px;
  }
}

@media screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none !important;
  }

  .detailed-page-section .section-title {
    pointer-events: none;
  }
}

@media screen and (max-width: 767px) {

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    width: 100%;
    max-width: inherit;
    flex: inherit;
  }

  .btn {
    font-size: 13px;
  }

  body.dropdown-menu-overflow {
    padding-right: 0;
  }

  body.dropdown-menu-overflow .header-fixed {
    padding-right: 0;
  }

  body .row-direction-m {
    justify-content: space-between;
    flex-direction: row;
  }

  body .row-direction-m label,
  body .row-direction-m span {
    flex: 1;
    margin-right: 4px;
  }

  body .flex-wrap-m {
    flex-wrap: wrap;
  }

  body .float-none-m {
    float: none !important;
  }

  body .d-flex-1-m {
    flex: 1;
  }

  body .width-m-100 {
    width: 100%;
  }

  body .row-reverse-direction-m {
    flex-direction: row-reverse;
  }

  body .column-direction {
    flex-direction: column;
  }

  body .form-group-row.flex-direction-unset-m {
    flex-direction: unset;
  }

  body .full-page-width {
    width: 100% !important;
  }

  body .hide-on-mobile {
    display: none !important;
  }

  body .d-flex-m {
    display: flex !important;
  }

  body .d-block-m {
    display: block !important;
  }

  body .align-items-center-m {
    align-items: center;
  }

  body .align-items-start-m {
    align-items: start !important;
  }

  body .justify-content-center-m {
    justify-content: center;
  }

  body .justify-content-start-m {
    justify-content: flex-start !important;
  }

  body .height-auto-m {
    height: auto !important;
  }

  body .btn-height-m {
    height: 40px !important;
  }

  body .p-m-0 {
    padding: 0 !important;
  }

  body .px-m-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body .py-m-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  body .pl-m-0 {
    padding-left: 0 !important;
  }

  body .pr-m-0 {
    padding-right: 0 !important;
  }

  body .pt-m-0 {
    padding-top: 0 !important;
  }

  body .pb-m-0 {
    padding-bottom: 0 !important;
  }

  body .pl-m-24 {
    padding-left: 24px !important;
  }

  body .p-m-32 {
    padding: 32px !important;
  }

  body .py-m-12 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  body .m-m-4 {
    margin: 4px !important;
  }

  body .m-m-8 {
    margin: 8px !important;
  }

  body .ml-m-0 {
    margin-left: 0 !important;
  }

  body .mr-m-0 {
    margin-right: 0 !important;
  }

  body .mt-m-0 {
    margin-top: 0 !important;
  }

  body .mb-m-0 {
    margin-bottom: 0 !important;
  }

  body .mb-m-8 {
    margin-bottom: 8px !important;
  }

  body .mx-m-4 {
    margin: 4px 0 !important;
  }

  body .mt-m-8 {
    margin-top: 8px !important;
  }

  body .mr-m-4 {
    margin-right: 4px !important;
  }

  body .mr-m-12 {
    margin-right: 12px !important;
  }

  body .ml-m-8 {
    margin-left: 8px !important;
  }

  body .mr-m-8 {
    margin-right: 8px !important;
  }

  body .position-fixed-m {
    position: fixed !important;
  }

  body .position-relative-m {
    position: relative !important;
  }

  body .position-static-m {
    position: static !important;
  }

  body .no-float {
    float: inherit !important;
  }

  body .m-min-height-auto {
    min-height: auto !important;
  }

  body .m-mv-spacer {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  body .text-left-m {
    text-align: left;
  }

  body .border-m-0 {
    border: 0;
  }

  body .login-wrapper .left-panel {
    display: none;
  }

  body .login-wrapper .right-panel-wrapper {
    float: none;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    height: auto;
  }

  body .login-wrapper .right-panel-wrapper .right-panel {
    text-align: center;
    position: static !important;
    transform: inherit;
    padding-top: 100px;
    margin: auto;
  }

  body .login-wrapper .right-panel-wrapper .right-panel .mobile-logo {
    display: block;
    margin-bottom: 15px;
  }

  body .header-fixed .header-left {
    margin-left: 0 !important;
  }

  body .header-fixed .header-left .logo {
    display: none !important;
  }

  body .header-fixed .header-left .logo img {
    max-width: 120px !important;
  }

  body .page-container .header-fixed {
    border-bottom: 0;
    box-shadow: inherit;
  }

  body .page-container .env-name {
    display: none;
  }

  body .page-container.mini-sidebar .sidebar {
    left: -64px;
  }

  body .page-container.mini-sidebar .sidebar .sidebar-logo-mobile {
    left: 127px !important;
  }

  body .page-container.mini-sidebar .content {
    width: 100%;
  }

  body .page-container.mini-sidebar .current-program,
  body .page-container.mini-sidebar .user-settings {
    display: none;
  }

  body .page-container.mini-sidebar .sidenav.sidenav-active {
    left: 0 !important;
  }

  body .page-container.mini-sidebar .no-min-height~.dropdown-box-wrapper {
    transform: translateX(-154px) !important;
  }

  body .page-container .sidebar {
    margin-top: 0;
    height: 100vh;
    z-index: 10;
    width: 305px;
  }

  body .page-container .sidebar .sidebar-inner {
    width: 305px !important;
    height: calc(100vh - 190px) !important;
  }

  body .page-container .sidebar .logoNmenu {
    height: 51px;
  }

  body .page-container .sidebar .logoNmenu .switch-menu {
    background-color: var(--navigation-bg) !important;
    color: var(--header-bg-color) !important;
  }

  body .page-container .sidebar .logoNmenu .sidebar-logo-mobile {
    position: absolute;
    left: 63px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100px;
    height: auto;
    max-height: 40px;
  }

  body .page-container .sidebar .main-nav>Li>a icon {
    display: none;
  }

  body .page-container .current-program {
    z-index: 10;
    width: 305px;
  }

  body .page-container .user-settings {
    z-index: 10;
    width: 305px;
  }

  body .page-container .user-settings .current-user-info {
    width: 200px !important;
  }

  body .page-container .content {
    width: 100%;
  }

  body .page-container .dropdown-box-wrapper {
    transform: translateX(-154px) !important;
    width: 200px;
    transform: translateX(-154px) !important;
    width: 200px;
  }

  body .page-container .dropdown-box-wrapper:before {
    left: auto;
    right: 16px;
  }

  body .page-container .dropdown-box-wrapper.no-transform {
    transform: translateX(52px) !important;
  }

  body .value-group .value-head,
  body .value-group .value-content {
    font-size: 13px !important;
  }

  body .grid-dataspan,
  body .grid-datalabel {
    font-size: 13px !important;
  }

  body .font-m {
    font-size: 13px !important;
  }

  body .sidenav {
    transform: translateX(-50px) !important;
    left: 0 !important;
  }

  body .sidenav.sidenav-active {
    left: 0 !important;
    z-index: 100 !important;
    transform: translateX(0) !important;
    width: 305px !important;
  }

  body .max-width-set-title-m {
    max-width: 300px !important;
  }

  body .form-group-row {
    flex-direction: column;
  }

  body .create-wrapper .create-form h3.page-title-mobile {
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
  }

  body .create-wrapper .create-form .card .breadcrumb {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    background-color: #f8f9fa;
  }

  body .create-wrapper .create-form .card .breadcrumb>div {
    line-height: 50px;
    border-left: 0 !important;
    position: relative;
  }

  body .create-wrapper .create-form .card .breadcrumb>div:after {
    background-color: #d1e3f1;
    position: absolute;
    top: 50%;
    left: -48px;
    height: 1px;
    content: '.';
    text-indent: -9999px;
    width: 75px;
  }

  body .create-wrapper .create-form .card .breadcrumb.mobile-comp {
    flex-direction: row;
  }

  body .create-wrapper .create-form .card .breadcrumb.mobile-comp>div span.title {
    display: none !important;
  }

  body .create-wrapper .create-form .card .breadcrumb.mobile-comp>div span.num {
    margin-right: 0 !important;
    background-color: #d1e3f1 !important;
    position: relative;
    z-index: 2;
  }

  body .create-wrapper .create-form .card .breadcrumb.mobile-comp>div.finished span icon {
    margin-right: 0 !important;
  }

  body .create-wrapper .create-form .card .breadcrumb.mobile-comp>div.finished icon {
    position: relative;
    z-index: 2;
  }

  body .create-wrapper .create-form .card .breadcrumb.mobile-comp>div:first-child:after {
    display: none;
  }

  body .create-wrapper .create-form .card .breadcrumb.mobile-comp>div.active span.num {
    background-color: var(--color-assets-primary) !important;
  }

  body .create-wrapper .create-form .card .p-32 {
    padding: 15px !important;
  }

  body .create-wrapper .create-form .card .contact-infos .field-info-icon {
    display: none;
  }

  body .create-wrapper .create-form .card.mobile-card-view {
    box-shadow: inherit !important;
    border: 0 !important;
    margin-top: 0 !important;
  }

  body .create-wrapper .create-form .search-candidate {
    margin-bottom: 12px;
  }

  body .create-wrapper .create-form .candidate-panel .client {
    margin-bottom: 4px;
  }

  body .create-wrapper .create-form .candidate-panel .client .client-name {
    flex-direction: row !important;
    align-items: center;
  }

  body .create-wrapper .create-form .candidate-panel .client .client-name .subtitle-2 {
    margin-right: 4px;
  }

  body .create-wrapper .create-form .create-candidate-btn {
    float: none !important;
    margin: 50px auto 0 !important;
  }

  body .create-wrapper .form-footer {
    padding: 15px !important;
  }

  body .create-wrapper .add-qualifications .form-group-row {
    display: flex;
  }

  body .create-wrapper .budget-section {
    flex-direction: column;
  }

  body .create-wrapper .vendor-onbaord.vendor-onb-m {
    width: auto;
  }

  body .create-candidate .back-btn {
    margin-top: 8px;
    margin-left: 8px;
    display: inline-block;
  }

  body .create-candidate .create-form>div {
    justify-content: center !important;
  }

  body .create-candidate .profile-resume-box {
    flex-direction: column;
  }

  body .create-candidate .profile-resume-box .upload-avatar-panel {
    margin: 0 auto 24px;
  }

  body .create-candidate .profile-resume-box .resume-wrapper>div {
    flex-direction: column;
  }

  body .create-candidate .profile-resume-box .resume-wrapper>div .drop-zone {
    margin-bottom: 24px;
  }

  body .create-candidate .profile-resume-box .resume-wrapper>div .browse-zone {
    text-align: center !important;
  }

  body .create-candidate .profile-resume-box .uploaded-list-sidebar li {
    padding-left: 15px;
    padding-right: 15px;
  }

  body .create-candidate .profile-resume-box .uploaded-list-sidebar li .delete-resume {
    margin-top: 0 !important;
  }

  body .create-candidate .form-group-row.no-block {
    flex-direction: row;
  }

  body .create-candidate .no-document {
    margin-top: 12px;
  }

  body .create-candidate .item-list li .edit-item {
    top: -7px !important;
  }

  body .create-candidate .item-list li .item-description {
    padding-right: 0 !important;
    width: 70% !important;
  }

  body .create-candidate .social-app-row {
    flex-direction: row;
  }

  body .create-candidate .social-app-row .social-application {
    width: 28% !important;
  }

  body .create-candidate .social-app-row .social-application .ng-dropdown-panel-items .ng-option-label span {
    display: none !important;
  }

  body .create-candidate .has-info-icon>icon {
    display: none;
  }

  body .create-candidate .preference-row .form-group {
    margin-bottom: 16px !important;
    padding-bottom: 4px !important;
    margin-right: 0 !important;
  }

  body .create-candidate .avl-date {
    display: block !important;
  }

  body .create-candidate .colored-box .d-flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  body .create-candidate .colored-box .available-day-row {
    display: block !important;
  }

  body .create-candidate .colored-box .available-day-row .day {
    margin-top: 12px;
  }

  body .create-candidate .colored-box .available-day-row .day .holiday {
    height: 70px !important;
  }

  body .job-estimate .detailed-estimation .row {
    flex-wrap: nowrap;
  }

  body .job-estimate .detailed-estimation .row {
    flex-wrap: nowrap;
  }

  body .detail-page-wrapper .status-message-mobile {
    margin: 0 !important;
  }

  body .detail-page-wrapper .status-message-mobile .alert_message {
    margin: 0 !important;
    border-radius: 0;
  }

  body .detail-page-wrapper .description-panel {
    padding: 0 !important;
    background-color: #ffffff;
  }

  body .detail-page-wrapper .description-panel .back-btn {
    margin-left: 5px;
  }

  body .detail-page-wrapper .description-panel .back-btn icon i {
    position: relative;
    visibility: hidden;
  }

  body .detail-page-wrapper .description-panel .back-btn icon i:after {
    position: absolute;
    content: 'arrow_back';
    left: 0;
    top: 0;
    visibility: visible;
  }

  body .detail-page-wrapper .description-panel .card {
    box-shadow: inherit;
    border-radius: 0;
  }

  body .detail-page-wrapper .description-panel .detail-page-header {
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--responsive-detail-header);
  }

  body .detail-page-wrapper .description-panel .detail-page-header .page-item-id {
    color: #ffffff;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .actions {
    margin-top: 12px;
    top: 0;
    right: 16px;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .actions .pending-button {
    height: 36px;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .actions>div button {
    width: 100%;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .actions button.btn-primary {
    margin-top: 0;
    background-color: transparent !important;
    padding-left: 9px;
    padding-right: 9px;
    height: 36px;
    border: 1px solid #ffffff;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .actions button.btn-primary icon {
    display: flex;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .actions button.btn-primary icon i {
    color: #ffffff;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .actions .action-list {
    top: 52px;
  }

  body .detail-page-wrapper .description-panel .detail-page-header h3 {
    padding-right: 130px;
    color: var(--responsive-detail-header-color);
  }

  body .detail-page-wrapper .description-panel .detail-page-header h3>span {
    margin-left: 0 !important;
    margin-top: 16px;
  }

  body .detail-page-wrapper .description-panel .detail-page-header .job-id {
    color: var(--responsive-detail-header-color);
  }

  body .detail-page-wrapper .description-panel .detail-page-header .job-id icon i {
    vertical-align: middle;
  }

  body .detail-page-wrapper .description-panel .description-card {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  body .detail-page-wrapper .description-panel .description-card.history-padd {
    padding: 24px !important;
  }

  body .detail-page-wrapper .description-panel .description-card.detail-page-responsive-list {
    padding-top: 0 !important;
  }

  body .detail-page-wrapper .description-panel .description-card.detail-page-responsive-list .vms-header-panel {
    padding: 20px 16px !important;
  }

  body .detail-page-wrapper .description-panel .description-card.detail-page-responsive-list .vms-header-panel .listing-filter li {
    margin-top: 0 !important;
  }

  body .detail-page-wrapper .description-panel .description-card.detail-page-responsive-list .vms-header-panel .listing-filter li.search-field .mobile-search-head {
    top: 20px;
  }

  body .detail-page-wrapper .description-panel .description-card.detail-page-responsive-list .no-preview-mobile {
    display: none;
  }

  body .detail-page-wrapper .description-panel .description-card .content-section {
    overflow: visible;
  }

  body .detail-page-wrapper .description-panel .description-card .content-section.mobile-re-order {
    display: flex;
    flex-direction: column-reverse;
  }

  body .detail-page-wrapper .description-panel .description-card .content-section.mobile-re-order .detail-sidebar {
    margin-top: 0;
    margin-bottom: 24px;
  }

  body .detail-page-wrapper .description-panel .description-card .jobdetails-section {
    width: auto;
    float: none;
    margin-top: 0;
  }

  body .detail-page-wrapper .description-panel .description-card .detail-sidebar {
    float: none;
    width: auto;
    margin-top: 24px;
    padding-bottom: 0 !important;
  }

  body .detail-page-wrapper .description-panel .description-card .detail-sidebar .info-item {
    padding-right: 12px !important;
  }

  body .detail-page-wrapper .description-panel .description-card.detail-page-responsive-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body .detail-page-wrapper .description-panel .panel-wrapper {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .left-panel-view {
    float: none;
    width: auto;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-info>div.address,
  body .detail-page-wrapper .description-panel .left-panel-view .profile-info>div.phone,
  body .detail-page-wrapper .description-panel .left-panel-view .profile-info>div.email {
    flex-direction: row;
    flex-wrap: wrap;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-info>div figure {
    margin: auto !important;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-info .profile-text>div {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-info .profile-text>div h4 {
    text-align: center;
    margin-top: 12px;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-info .profile-text p {
    text-align: center;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-info .profile-text .candidate-name-title {
    text-align: left;
    margin-left: 12px;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-info .profile-text .candidte-data-title {
    margin-left: 12px;
    text-align: left;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .info-cards {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .info-cards>div {
    margin-bottom: 16px;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-page-buttons {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .profile-page-buttons button {
    margin-right: auto !important;
    margin-bottom: 12px;
    margin-left: auto;
    max-width: 250px;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .nav-wrapper {
    width: 100%;
    overflow: auto;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .nav-wrapper nav {
    white-space: nowrap;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .job-view-content-header h4 {
    float: none;
    margin-right: 0;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .job-view-content-header .status-ui {
    float: none;
    text-align: center;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .job-view-content-header .offer-id icon i {
    vertical-align: middle;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .job-view-content-header>div {
    float: none !important;
    justify-content: center;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .job-view-content-header>div .dd-button {
    position: absolute;
    top: 24px;
    right: 16px;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .job-view-content-header .sub-head-info label {
    float: none;
    display: inline-block;
    margin-right: 0;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .job-view-content-header .approval-nofification {
    margin-right: 0 !important;
    padding: 3px 8px;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .interview-info .interview-info {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .left-panel-view .tabular-data {
    width: 100%;
    overflow: auto;
  }

  body .detail-page-wrapper .description-panel .right-panel-view {
    float: none;
    width: auto;
    padding-left: 0;
    margin-right: 0;
  }

  body .detail-page-wrapper .description-panel .content-section.d-flex {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .content-section.d-flex .job-sidebar {
    width: auto;
    padding-left: 0;
    margin-top: 16px;
    padding-bottom: 0 !important;
  }

  body .detail-page-wrapper .description-panel .content-section.d-flex .job-sidebar .section-title {
    padding: 16px;
    pointer-events: auto;
    border-top: 1px solid var(--color-border-secondary);
    border-bottom: 1px solid transparent;
  }

  body .detail-page-wrapper .description-panel .content-section.d-flex .job-sidebar .section-title i {
    background-color: #EDF2FE;
    border-radius: 4px;
  }

  body .detail-page-wrapper .description-panel .content-section.d-flex .job-sidebar .section-title.active {
    background-color: #FAFCFF;
    border-bottom-color: var(--color-border-secondary);
  }

  body .detail-page-wrapper .description-panel .content-section.d-flex .job-sidebar div.inactive {
    display: none;
  }

  body .detail-page-wrapper .description-panel .content-section.d-flex .job-sidebar div.active {
    display: block;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper {
    width: 100%;
    overflow: auto;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper table {
    width: 100%;
    border: 0;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper table thead {
    display: none;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper table tbody tr {
    border: 0;
    border-top: 1px solid var(--color-border-secondary);
    border-bottom: 1px solid var(--color-border-secondary);
    margin: 0 0 8px;
    display: block;
    padding: 4px;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper table td {
    display: block;
    width: 100%;
    height: auto;
    border: 0;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper table td td {
    position: relative;
    padding: 4px 0 4px 48%;
    min-height: 25px;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper table td td:before {
    position: absolute;
    top: 4px;
    left: 5px;
    content: attr(data-value);
    visibility: visible;
    overflow: visible;
  }

  body .detail-page-wrapper .description-panel .content-section .rate-table-wrapper table td span {
    float: none !important;
  }

  body .detail-page-wrapper .description-panel .content-section .fees-table {
    width: 100%;
    overflow: auto;
  }

  body .detail-page-wrapper .description-panel .content-section .fees-table table {
    width: 100%;
    border: 0;
  }

  body .detail-page-wrapper .description-panel .content-section .fees-table table thead {
    display: none;
  }

  body .detail-page-wrapper .description-panel .content-section .fees-table table tbody tr {
    border: 1px solid var(--color-border-secondary) !important;
    margin: 0 0 8px;
    display: block;
    padding: 4px;
  }

  body .detail-page-wrapper .description-panel .content-section .fees-table table td {
    display: block;
    width: 100%;
    height: auto;
    border: 0;
    position: relative;
    padding: 4px 0 4px 48%;
  }

  body .detail-page-wrapper .description-panel .content-section .fees-table table td:before {
    position: absolute;
    top: 4px;
    left: 5px;
    content: attr(data-value);
    visibility: visible;
    overflow: visible;
  }

  body .detail-page-wrapper .description-panel .card.no-mobile-margin {
    margin-bottom: 0 !important;
    padding: 0 !important;
  }

  body .detail-page-wrapper .description-panel .budget-item {
    margin-bottom: 16px;
    text-align: center;
  }

  body .detail-page-wrapper .description-panel .budget-item .card-content {
    height: auto;
    margin-bottom: 12px;
  }

  body .detail-page-wrapper .description-panel .budget-item .budget-detail {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .budget-item .budget-detail button {
    margin: 12px 0;
  }

  body .detail-page-wrapper .description-panel .budget-item .budget-detail .budget-detail-head {
    line-height: 50px;
  }

  body .detail-page-wrapper .description-panel .budget-item .details-chart {
    position: relative !important;
    top: 15px;
    right: auto;
    left: 8%;
  }

  body .detail-page-wrapper .description-panel .additional-budget-table {
    width: 100%;
    overflow: auto;
  }

  body .detail-page-wrapper .description-panel .additional-budget-table table {
    width: auto;
  }

  body .detail-page-wrapper .description-panel .additional-budget-table table td,
  body .detail-page-wrapper .description-panel .additional-budget-table table th {
    white-space: nowrap;
  }

  body .detail-page-wrapper .description-panel .history-event-info {
    flex-direction: column;
  }

  body .detail-page-wrapper .description-panel .history-event-info button {
    margin-top: 12px;
  }

  body .detail-page-wrapper .description-panel .update-reason {
    margin-top: 16px;
  }

  body .detail-page-wrapper .description-panel .update-reason>div {
    margin-right: 0 !important;
    margin-bottom: 12px;
  }

  body .detail-page-wrapper .description-panel .approvers-item {
    padding: 8px !important;
  }

  body .detail-page-wrapper .description-panel .approvers-item .approved-panel {
    min-width: 100%;
  }

  body .detail-page-wrapper .description-panel .approvers-item .no-approved-panel {
    min-width: 100%;
  }

  body .detail-page-wrapper .description-panel .no-approved-panel {
    width: auto;
    min-width: 100%;
  }

  body .detail-page-wrapper .description-panel .view-address-box {
    padding-bottom: 20px;
  }

  body .detail-page-wrapper .description-panel .view-address-box .detail-button {
    position: absolute;
    bottom: 15px;
    right: 0;
    width: 53%;
  }

  body .detail-page-wrapper .description-panel .view-address-box .detail-button .tooltip-enabled .item-tooltip {
    left: auto;
    right: 0;
    bottom: 24px;
  }

  body .detail-page-wrapper .description-panel .alert_message {
    margin: 0 !important;
    border-radius: 0 !important;
  }

  body .worker-update-history,
  body .total-budget {
    align-items: flex-start !important;
  }

  body .worker-update-history>div,
  body .total-budget>div {
    margin-bottom: 8px;
  }

  body .history .code-history-value {
    word-break: break-all;
  }

  body .history .code-history-value p {
    text-align: left !important;
  }

  body .job-reports .job-reports__content {
    width: 100%;
  }

  body .job-reports .job-reports__content .reports {
    position: static;
    padding: 15px 15px 0;
  }

  body .job-reports .job-reports__content .reports .search-reports>div {
    flex-grow: 1;
  }

  body .job-reports .job-reports__content .reports .search-reports>div>div {
    flex-basis: 100%;
  }

  body .job-reports .job-reports__content .reports .search-reports>div>div input {
    width: 100% !important;
  }

  body .job-reports .job-reports__content .reports .search-reports button {
    margin-bottom: 8px;
  }

  body .job-reports .job-reports__content .content-reports {
    height: auto;
  }

  body .job-reports .job-reports__right-sidebar {
    position: static;
  }

  body .job-reports .no-content-reports {
    height: auto;
    margin-top: 12px !important;
  }

  body .job-reports .no-reports {
    position: static;
    margin: auto;
  }

  body .reports-bg {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-color: #ffffff !important;
    padding-bottom: 0 !important;
  }

  body .reports-bg .header {
    padding-left: 16px;
    padding-right: 16px;
  }

  body .reports-bg .chart-filter {
    margin-top: 8px;
  }

  body .content-reports {
    padding: 16px !important;
  }

  body .favourite-reports {
    display: none !important;
  }

  body .report-details-top-actions button {
    padding: 6px !important;
    border: 0 !important;
  }

  body .report-details-top-actions button .btn-label {
    display: none;
  }

  body .report-details-top-actions button i {
    margin-right: 0 !important;
  }

  body app-settings .favourite {
    position: static !important;
    width: auto !important;
  }

  body app-settings .float-content .content-box {
    padding: 15px !important;
  }

  body app-settings .float-content .content-box .content-list {
    column-count: 1 !important;
  }

  body .scroll-wrapper {
    width: 100%;
    overflow: auto;
  }

  body .scroll-wrapper table {
    width: auto;
  }

  body .scroll-wrapper table th,
  body .scroll-wrapper table td {
    white-space: nowrap;
  }

  body .scroll-wrapper.rate-table-wrapper table {
    width: 100%;
    border: 0;
  }

  body .scroll-wrapper.rate-table-wrapper table thead {
    display: none;
  }

  body .scroll-wrapper.rate-table-wrapper table tbody tr {
    border: 1px solid var(--color-border-secondary) !important;
    margin: 0 0 8px;
    display: block;
    padding: 4px;
  }

  body .scroll-wrapper.rate-table-wrapper table tbody tr.child-head {
    display: none;
  }

  body .scroll-wrapper.rate-table-wrapper table td {
    display: block;
    width: 100%;
    height: auto;
    border: 0 !important;
    position: relative;
    padding: 4px 0 4px 48%;
  }

  body .scroll-wrapper.rate-table-wrapper table td:before {
    position: absolute;
    top: 4px;
    left: 5px;
    content: attr(data-value);
    visibility: visible;
    overflow: visible;
  }

  body .svms-sidebar {
    max-width: 90% !important;
  }

  body .svms-sidebar .uploaded-list li {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  body .table-bg {
    padding: 0 !important;
  }

  body .table-bg .table-bg-inner {
    background: transparent !important;
  }

  body .table-bg .table-bg-inner .content-area {
    background-color: transparent !important;
    margin-top: 8px !important;
  }

  body .table-bg .vms-header-panel {
    position: relative;
    padding: 20px 16px !important;
  }

  body .table-bg .vms-header-panel .vms-header-left {
    min-width: 0;
  }

  body .table-bg .vms-header-panel .breadcrumb-list {
    min-width: 0;
  }

  body .table-bg .vms-header-panel .breadcrumb-list li {
    min-width: 0;
    margin-bottom: 8px;
  }

  body .table-bg .vms-header-panel .breadcrumb-list li:first-child {
    margin-bottom: 0;
  }

  body .table-bg .vms-header-panel .breadcrumb-list li:first-child .title {
    font-size: 18px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  body .table-bg .vms-header-panel .breadcrumb-list li .add-btn {
    display: none;
  }

  body .table-bg .vms-header-panel .breadcrumb-list li .add-btn-mobile {
    position: fixed;
    bottom: 30px;
    right: 24px;
    z-index: 10;
    width: 47px;
    height: 47px;
    display: block !important;
  }

  body .table-bg .vms-header-panel .listing-filter {
    align-items: center;
  }

  body .table-bg .vms-header-panel .listing-filter li {
    margin-top: 8px;
  }

  body .table-bg .vms-header-panel .listing-filter li .checkout-new-btn {
    z-index: 2;
  }

  body .table-bg .vms-header-panel .listing-filter li.breadcrumb-listItem button.small {
    font-size: 12px;
    white-space: nowrap;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field {
    min-width: 43px;
    min-height: 33px;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field .mobile-search-head {
    display: block;
    min-height: 33px;
    position: absolute;
    top: 29px;
    right: 24px;
    left: 24px;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field .mobile-search-head a {
    position: absolute;
    top: 0;
    right: 0;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field .mobile-search-head a.hide {
    display: none !important;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field .mobile-search-head .search-trigger {
    position: absolute;
    top: 8px;
    right: 13px;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field .mobile-search-head input {
    padding-right: 35px;
    height: 33px;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field .mobile-search-head.seearch-opened {
    z-index: 3;
  }

  body .table-bg .vms-header-panel .listing-filter li.search-field.filter-enabled .mobile-search-head {
    right: 79px;
  }

  body .table-bg .vms-header-panel .listing-filter li .search-head {
    display: none;
  }

  body .table-bg .vms-header-panel .listing-filter li.button-list-lg {
    display: none;
  }

  body .table-bg .mobile-header-buttons {
    display: flex;
    background-color: #ffffff;
    justify-content: center;
  }

  body .table-bg .mobile-header-buttons button {
    margin: 0 4px 8px;
  }

  body .table-bg .tab-nav-outer {
    margin-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-bottom: 1px solid #e9ecef;
  }

  body .table-bg .content-area-inner {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body .table-bg .table-listing.cloned {
    display: none;
  }

  body .table-bg .table-listing .frequency .frequency__wrapper {
    display: block !important;
  }

  body .table-bg .table-listing .frequency .frequency__wrapper .frequency__block {
    display: inline-block;
    margin-top: 4px;
  }

  body .table-bg .pagination-border {
    align-items: center;
    padding-left: 16px !important;
    padding-right: 16px;
    background-color: #ffffff;
  }

  body .table-bg .pagination-border .pagination {
    margin-top: 12px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  body .table-bg .pagination-border .pagination a {
    padding: 5px 5px !important;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow thead {
    display: none;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr {
    display: block;
    border: 0 !important;
    margin-bottom: 12px;
    padding: 8px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000014;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row {
    display: block !important;
    position: relative;
    padding-left: 46%;
    line-height: 20px;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row:before,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row:before {
    content: attr(title);
    position: absolute;
    top: 10px;
    left: 8px;
    width: 44%;
    white-space: nowrap;
    color: #6c757d;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td {
    display: block;
    background-color: #ffffff !important;
    height: auto;
    white-space: normal;
    overflow: visible;
    font-weight: 400;
    color: #343a40;
    text-align: right;
    padding-right: 8px !important;
    word-break: break-word;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td .actions,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td .actions {
    display: block !important;
    right: 0 !important;
    top: 20px !important;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td .actions table-icon i,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td .actions table-icon i {
    color: var(--color-assets-primary);
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td .actions table-icon[name='info'],
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td .actions table-icon[name='info'] {
    position: relative;
    top: -45px;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td .status-ui,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td .status-ui {
    white-space: normal;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td .unique-id-icon,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td .unique-id-icon {
    top: auto;
    right: -5px !important;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td p,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td p {
    white-space: normal;
    font-weight: 500;
    color: #000000;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td p>icon,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td p>icon {
    position: static !important;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td span.initials,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td span.initials {
    display: none;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td a,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td a {
    font-weight: 500;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td:first-child,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td:first-child {
    max-width: inherit;
    min-width: inherit;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row td .manager-images-list .manager-avatar-list,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row td .manager-images-list .manager-avatar-list {
    position: static;
    margin-right: 0;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row:first-child,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row:first-child {
    padding-left: 0;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row:first-child:before,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row:first-child:before {
    display: none;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row:first-child td,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row:first-child td {
    padding-left: 8px !important;
    padding-right: 8px !important;
    text-align: left;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row:first-child td p,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row:first-child td p {
    font-size: 15px;
    font-weight: 600;
    white-space: normal !important;
    width: 94%;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row:first-child td p a,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row:first-child td p a {
    width: auto;
    font-weight: 600;
    white-space: normal;
    text-overflow: inherit;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr vms-row:first-child td>.mobile-justcontent-end,
  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr app-simple-row:first-child td>.mobile-justcontent-end {
    justify-content: flex-start;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr.active vms-row td {
    background-color: #ffffff !important;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr:hover .actions>span:hover {
    border: transparent;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tbody>tr .checkbox-td.not-scrollable {
    padding-left: 0 !important;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tfoot {
    display: block;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow tfoot tr td:first-child {
    display: none;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow .ps-content {
    padding-bottom: 0 !important;
  }

  body .table-bg .listing-wrap .table-outer .table-overflow .checkbox-td .checkbox-wrapper {
    margin-left: 8px;
  }

  body .uploaded-list-sidebar li {
    padding-left: 8px;
    padding-right: 8px;
  }

  body .filter-panel {
    max-width: 90%;
  }

  body .filter-panel .filter-close {
    left: -33px !important;
  }

  body .upload-panel-body {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  body .create-program .back-button {
    top: 0;
    left: 0;
    margin-top: 0;
  }

  body .create-program .program-toggle-panel {
    width: 100%;
    flex-direction: column;
  }

  body .create-program .program-toggle-panel .toggle-panel {
    width: 100% !important;
    margin-left: 0 !important;
  }

  body .create-program .program-manager-tab {
    margin-bottom: 12px;
  }

  body .create-program .program-manager-tab .choose-role {
    width: 100%;
    margin-bottom: 12px;
  }

  body .create-program .module-panel .checkbox-panel .toggle-panel {
    width: 100%;
  }

  body .create-program .client-create-btn {
    margin-bottom: 16px;
  }

  body .create-program .add-user {
    border-left: 1px solid var(--color-border-secondary);
  }

  body .success-modal {
    max-width: 90%;
  }

  body .success-modal .btn-submit-mobile {
    float: left !important;
    margin-top: 12px;
  }

  body .sidebar-ng-mobile {
    height: 100vh;
    overflow: auto;
  }

  body .sidebar-ng-mobile .close-trigger {
    left: auto;
    z-index: 10;
    top: 18px;
    right: 18px;
  }

  body .sidebar-ng-mobile .close-trigger i {
    color: #000000;
    font-size: 20px !important;
  }

  body .sidebar-ng-mobile .profile-bar {
    float: none;
    width: auto;
    position: static;
    height: auto;
  }

  body .sidebar-ng-mobile .profile-bar .profile-bar-nav {
    margin-right: 0;
  }

  body .sidebar-ng-mobile .profile-bar .profile-bar-nav li.active {
    border-radius: 27px;
    overflow: hidden;
  }

  body .sidebar-ng-mobile .search-invoice-wrapper {
    width: 100%;
  }

  body .sidebar-ng-mobile .search-invoice-wrapper .search-invoice-wrapper {
    width: 100%;
  }

  body .sidebar-ng-mobile .search-invoice-wrapper .search-invoice-wrapper ng-select {
    right: 20px;
  }

  body .tab-nav-wrap {
    margin: 0 24px;
    overflow: auto;
  }

  body .tab-nav-wrap nav {
    padding: 0 !important;
  }

  body .tab-nav-wrap nav li span {
    white-space: nowrap;
  }

  body .c-invoice-header {
    padding: 16px !important;
  }

  body .c-invoice-header h3 {
    text-align: center;
  }

  body .inv-overflow {
    width: 100%;
    overflow: auto;
  }

  body .inv-overflow .inv-calculation {
    min-width: 500px;
  }

  body .panel-center {
    text-align: center;
  }

  body .invoice-detail-list .title {
    margin-bottom: 16px;
    padding-bottom: 0;
  }

  body .invoice-detail-list .tab-active .listing-filter {
    position: relative;
    top: -54px;
  }

  body .invoice-detail-list .tab-active .listing-filter li.search-field {
    display: none;
  }

  body .timesheet-page.fixed-btn-plceholder {
    padding-bottom: 75px;
  }

  body .timesheet-page .alert_message {
    margin: 0 !important;
    border-radius: 0;
  }

  body .timesheet-page .detail-page-wrapper .description-panel .create-wrapper .mobile-header {
    background-color: var(--responsive-detail-header) !important;
  }

  body .timesheet-page .breadcrumb-left {
    float: none !important;
    text-align: left;
  }

  body .timesheet-page .breadcrumb-left h4 {
    color: var(--responsive-detail-header-color);
  }

  body .timesheet-page .breadcrumb-left p {
    color: var(--responsive-detail-header-color);
  }

  body .timesheet-page .breadcrumb-left icon[name="update"] i {
    color: var(--responsive-detail-header-color) !important;
  }

  body .timesheet-page .breadcrumb-right {
    position: absolute;
    top: 0;
    right: 24px;
    float: none !important;
    text-align: left;
  }

  body .timesheet-page .breadcrumb-right button {
    float: none !important;
    margin: 0 !important;
  }

  body .timesheet-page .breadcrumb-right .breadcrumb-dropdown-trigger {
    background-color: transparent;
    border-color: #ffffff;
  }

  body .timesheet-page .breadcrumb-right .ts-breadcrumb-dropdown {
    position: absolute;
    top: 43px;
    right: 0;
    width: 250px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 16px 16px 4px 16px;
    z-index: 10;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
  }

  body .timesheet-page .breadcrumb-right .ts-breadcrumb-dropdown button {
    width: 100%;
    margin-bottom: 12px !important;
  }

  body .timesheet-page .breadcrumb-right .ts-breadcrumb-dropdown.active {
    visibility: visible;
    opacity: 1;
  }

  body .timesheet-page .mobile-view-buttons {
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
  }

  body .timesheet-page .mobile-view-buttons button {
    margin: 0 8px !important;
    width: 41%;
  }

  body .timesheet-page .timesheet-header {
    width: 100%;
    overflow: auto;
    background-color: transparent;
  }

  body .timesheet-page .timesheet-header .header-mobile-wrapper {
    display: flex;
  }

  body .timesheet-page .timesheet-header .header-mobile-wrapper .grid-group-data {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 12px;
    border-radius: 4px;
    margin-right: 12px;
    display: flex;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.06);
    width: auto !important;
    float: none !important;
  }

  body .timesheet-page .timesheet-header .header-mobile-wrapper .grid-group-data .header-item-info {
    margin-left: 8px;
    width: auto;
    float: none;
  }

  body .timesheet-page .timesheet-header .header-mobile-wrapper .grid-group-data .header-item-info span,
  body .timesheet-page .timesheet-header .header-mobile-wrapper .grid-group-data .header-item-info label,
  body .timesheet-page .timesheet-header .header-mobile-wrapper .grid-group-data .header-item-info a,
  body .timesheet-page .timesheet-header .header-mobile-wrapper .grid-group-data .header-item-info i {
    color: var(--responsive-detail-header-color) !important;
  }

  body .timesheet-page .timesheet-header .header-mobile-wrapper .worker-info {
    width: auto;
    float: none;
    padding-right: 0;
  }

  body .timesheet-page .timesheet-header .header-mobile-wrapper .worker-info .grid-group-data {
    width: auto !important;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  body .timesheet-page .timesheet-header .header-mobile-wrapper .worker-additional-details {
    display: flex;
    width: auto;
    float: none;
    padding-right: 12px;
  }

  body .timesheet-page .tito-mobile-entry {
    padding-top: 16px;
  }

  body .timesheet-page .tito-mobile-entry h4 {
    font-size: 13px !important;
  }

  body .timesheet-page .action-buttons {
    justify-content: center;
    margin-bottom: 12px;
  }

  body .timesheet-page .tito-mobile-record .headings {
    display: none;
  }

  body .timesheet-page .tito-mobile-record .total-col .col-item.break {
    min-height: inherit !important;
  }

  body .timesheet-page .tito-mobile-record .day-col .col-item {
    padding-left: 40%;
    opacity: 1;
    text-align: right;
  }

  body .timesheet-page .tito-mobile-record .day-col .col-item:before {
    position: absolute;
    top: 11px;
    left: 5%;
    content: attr(data-title);
    visibility: visible;
  }

  body .timesheet-page .tito-mobile-record .day-col .col-item[data-title="Daily Notes"] {
    padding-left: 20px;
    padding-top: 25px;
  }

  body .timesheet-page .tito-mobile-record .day-col .col-item.break {
    min-height: auto !important;
  }

  body .timesheet-page .day-col .error-message-box {
    left: auto !important;
    right: 100%;
  }

  body .timesheet-page .add-note-box {
    width: 97%;
  }

  body .timesheet-page .day-col-mobile {
    display: none;
  }

  body .timesheet-page .day-col-mobile.active {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 0 46px 1000px rgba(43, 45, 66, 0.8);
    max-height: 85%;
    overflow: auto;
  }

  body .timesheet-page .day-col-mobile.active:hover {
    box-shadow: 0px 0 46px 1000px rgba(43, 45, 66, 0.8);
    z-index: 100;
  }

  body .timesheet-page .day-col-mobile.active .add-note {
    display: none !important;
  }

  body .timesheet-page .day-col-mobile.active .add-note-box {
    display: block;
    position: static;
    width: 100%;
    margin-top: 12px;
  }

  body .timesheet-page .day-col-mobile .col-item {
    display: block !important;
  }

  body .timesheet-page .day-col-mobile .delete-btn {
    background-color: transparent;
    border: 0;
    width: 22px;
    height: 22px;
  }

  body .timesheet-page .day-col-mobile .min-width {
    min-width: 0;
  }

  body .timesheet-page .hide-day-col-mobile {
    display: block;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }

  body .timesheet-page .project-headings-mobile {
    display: none;
  }

  body .timesheet-page .total-col-mobile {
    display: none;
  }

  body .timesheet-page .project-mobile-view {
    display: block;
    margin-bottom: 16px;
  }

  body .timesheet-page .add-project-mobile {
    display: flex;
    justify-content: center;
  }

  body .timesheet-page .billing-details-widget {
    width: 100%;
    overflow: auto;
  }

  body .timesheet-page .billing-details-widget perfect-scrollbar {
    overflow: inherit !important;
  }

  body .timesheet-page .billing-details-widget perfect-scrollbar .ps {
    overflow: inherit !important;
  }

  body .billing-details-widget h4 {
    padding-right: 8px !important;
  }

  body .billing-details-widget .value-breakup {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  body .responsive-timesheet-view {
    display: block;
  }

  body .responsive-timesheet-view .timesheet-view-item {
    border-top: 1px solid var(--color-border-secondary);
    padding: 16px;
    position: relative;
  }

  body .responsive-timesheet-view .timesheet-view-item .day {
    color: #001A43;
    line-height: 20px;
  }

  body .responsive-timesheet-view .timesheet-view-item .day span {
    display: block;
  }

  body .responsive-timesheet-view .timesheet-view-item .add-time {
    font-size: 18px;
    color: #4C7FF0;
    margin-right: 0 !important;
  }

  body .responsive-timesheet-view .timesheet-view-item .holiday-mark {
    color: #C50000;
  }

  body .responsive-timesheet-view .timesheet-view-item .holiday-mark i {
    color: #FF0000;
    font-size: 18px;
  }

  body .responsive-timesheet-view .timesheet-view-item .day-note {
    font-size: 18px;
    color: #000000;
    margin-right: 8px;
  }

  body .responsive-timesheet-view .timesheet-view-item:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: '.';
    text-indent: -9999px;
    overflow: hidden;
    display: block;
    visibility: visible;
    background-color: #ffffff;
    width: 5px;
  }

  body .responsive-timesheet-view .timesheet-view-item.holiday {
    background-color: #FAFCFF;
  }

  body .responsive-timesheet-view .timesheet-view-item.holiday:before {
    background-color: #AFB9C5;
  }

  body .responsive-timesheet-view .timesheet-view-item.weekend {
    background-color: rgba(255, 197, 197, 0.1);
  }

  body .responsive-timesheet-view .timesheet-view-item.weekend:before {
    background-color: #FF7575;
  }

  body .responsive-timesheet-view .timesheet-view-item .projects-count {
    background-color: #EDF2FE;
    padding: 4px 12px;
    color: #5B6874;
    font-size: 12px;
    line-height: 21px;
    margin-top: 4px;
    display: inline-block;
  }

  body .responsive-timesheet-view .timesheet-view-item .break-details {
    margin-top: 4px;
  }

  body .responsive-timesheet-view .timesheet-view-item .break-details p {
    background-color: #EDF2FE;
    padding: 4px 12px;
    color: #5B6874;
    font-size: 12px;
    line-height: 21px;
  }

  body app-timesheet-billing-details .billable .fixed-table {
    display: none;
  }

  body .alert {
    max-width: 90% !important;
  }

  body .alert.alert-left-bottom {
    left: 20px !important;
  }

  body .account-code-setup .account-form-wrapper {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  body .billing-entry-wrapper .billing-entry {
    width: 100% !important;
  }

  body .tab-mobile-view {
    margin-top: 0 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  body .tab-mobile-view .tabs.primary li a icon.mr-8 {
    display: none;
  }

  body .tab-mobile-view .tabs.primary li.more ul {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: auto !important;
    top: auto !important;
    box-shadow: 0px 0 46px 1000px rgba(43, 45, 66, 0.8) !important;
    max-height: 80%;
    overflow: auto;
    padding: 12px 12px 0 12px;
    background-color: #ffffff;
  }

  body .tab-mobile-view .tabs.primary li.more ul li {
    padding-top: 16px;
    padding-bottom: 16px !important;
    margin-right: 0;
    border-bottom: 1px solid #f9f9f9;
  }

  body .tab-mobile-view .tabs.primary li.more ul li.nav-close-btn {
    display: block !important;
    padding-top: 0;
    padding-bottom: 0 !important;
    margin-right: 0 !important;
    border-bottom: 0;
  }

  body .tab-mobile-view .tabs.primary li.more ul li a {
    line-height: 17px;
  }

  body .tab-mobile-view .tabs.primary li.more ul li>div {
    display: block !important;
  }

  body .tab-mobile-view .tabs.primary li.more ul li>div span.count {
    float: right;
  }

  body .expense-date .datepicker-main-wrapper {
    width: 100%;
    overflow: auto;
    background-color: #ffffff;
  }

  body .expense-date .datepicker-main-wrapper .daterangepicker-wrapper {
    width: 646px;
  }

  body .confirmation-dialog {
    max-width: 90%;
  }

  body .confirmation-dialog .btn.large {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
  }

  body .automatic-header {
    padding-top: 16px;
  }

  body .automatic-header h4 {
    padding-left: 24px;
    font-size: 13px !important;
  }

  body .automatic-header h4 label,
  body .automatic-header h4 span {
    font-size: 13px !important;
  }

  body .automatic-header .copy-btn {
    border: 0;
    padding: 0;
  }

  body .automatic-header .copy-btn icon {
    margin-right: 0;
  }

  body .automatic-header .copy-btn icon i {
    font-size: 18px !important;
  }

  body .manual-header {
    padding-top: 16px;
  }

  body .manual-header h4 {
    font-size: 13px !important;
  }

  body .manual-header h4 label,
  body .manual-header h4 span {
    font-size: 13px !important;
  }

  body .manual-header .copy-btn {
    border: 0;
    padding: 0;
  }

  body .manual-header .copy-btn icon {
    margin-right: 0;
  }

  body .manual-header .copy-btn icon i {
    font-size: 18px !important;
  }

  body .manual-header .more-btn-icon {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  body .preferred-availability .item-row .left-col {
    width: 47% !important;
  }

  body .preferred-availability .item-row .left-col .status-text {
    font-size: 12px;
  }

  body .mobile-spacing {
    padding-left: 24px;
    padding-right: 24px;
  }

  body .alert_message {
    margin-left: 16px;
    margin-right: 16px;
  }

  body .schedule-report-form>.right-sidebar {
    padding: 16px !important;
  }

  body .schedule-report-form>.right-sidebar .filter-panel {
    max-width: 100%;
  }

  body .reports-saved-bg .header-top {
    padding-left: 16px;
    padding-right: 16px;
    background-color: #ffffff;
  }

  body .download-report {
    width: 90% !important;
  }

  body .saved-report-table .mobile-search-head {
    top: 22px !important;
  }

  body .saved-report-table .listing-filter li {
    margin-top: 0 !important;
  }

  body .history-tl-container ul.tl li {
    margin-left: 0 !important;
  }

  body .history-tl-container ul.tl li .timestamp {
    position: static !important;
  }

  body .history-tl-container ul.tl li .history {
    background: transparent;
    padding: 0 !important;
    align-items: flex-start !important;
  }

  body .history-tl-container ul.tl li .history>div>div {
    margin-left: 0 !important;
  }

  body .history-listing.job-history-listing .history-item {
    position: relative;
    padding: 0 0 16px 24px;
  }

  body .history-listing.job-history-listing .history-item .history-detail {
    background-color: transparent !important;
    padding: 0 !important;
  }

  body .history-listing.job-history-listing .history-item .history-detail .primary-outline {
    width: 80px;
  }

  body .history-listing.job-history-listing .history-item .divider {
    height: 100%;
    position: absolute;
    left: -14px;
  }

  body .history-listing.history-padd .divider.history-divider {
    left: -150px !important;
  }

  body .history-listing.history-padd .history-detail {
    background-color: transparent !important;
    padding: 8px 0 !important;
  }

  body .history-listing.history-padd .history-detail .primary-outline {
    width: 80px;
    margin-top: 10px !important;
  }

  body .expense-wrapper {
    background-color: #ffffff !important;
  }

  body .expense-wrapper .expense-header {
    box-shadow: inherit;
    position: relative;
    background-color: var(--responsive-detail-header);
    border-radius: 0;
  }

  body .expense-wrapper .expense-header .expense-btn-fixed-m {
    position: fixed;
    bottom: 40px;
    transform: translate(50%, 50%);
  }

  body .expense-wrapper .expense-header .expense-status-m p {
    color: var(--responsive-detail-header-color) !important;
  }

  body .expense-wrapper .expense-header .expense-status-m icon ::ng-deep i {
    color: var(--responsive-detail-header-color) !important;
  }

  body .expense-wrapper .expense-header .header-bottom {
    background-color: unset !important;
    width: 100%;
    overflow: auto !important;
    padding-top: 0 !important;
  }

  body .expense-wrapper .expense-header .header-bottom .expense-grid-group-data {
    width: auto !important;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 12px;
    border-radius: 4px;
    margin-right: 12px;
    display: -ms-flexbox;
    display: flex;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.06);
    float: none !important;
  }

  body .expense-wrapper .expense-header .header-bottom .expense-grid-group-data .expense-info-text p {
    color: var(--responsive-detail-header-color) !important;
    white-space: nowrap;
  }

  body .expense-wrapper .expense-header .header-bottom .expense-grid-group-data .expense-info-text p i {
    color: var(--responsive-detail-header-color) !important;
  }

  body .expense-wrapper .expense-header .header-bottom .expense-grid-group-data .expense-info-text p a {
    color: var(--responsive-detail-header-color) !important;
  }

  body .expense-wrapper .expense-header h3 {
    color: var(--responsive-detail-header-color);
  }

  body .expense-wrapper .expense-header>div {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  body .expense-wrapper .expense-header .status-ui {
    margin-left: 0 !important;
    margin-top: 8px;
  }

  body .expense-wrapper .expense-header .exp-action {
    margin-top: 12px;
  }

  body .expense-wrapper .expense-header .exp-action .btn-primary {
    background-color: rgba(0, 0, 0, 0.1);
  }

  body .expense-wrapper .expense-header .expense-code {
    color: var(--responsive-detail-header-color);
  }

  body .expense-wrapper .expense-header .exp-option-btn i {
    color: #ffffff;
  }

  body .expense-wrapper .expense-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body .expense-wrapper .expense-content tfoot tr {
    display: block;
  }

  body .expense-wrapper .expense-content tfoot tr td {
    display: block;
    width: 100%;
    padding-right: 16px !important;
  }

  body .expense-wrapper perfect-scrollbar {
    min-height: inherit !important;
  }

  body .expense-wrapper perfect-scrollbar>.ps {
    min-height: inherit !important;
  }

  body .expense-wrapper .back-mobile {
    padding: 9px 10px 10px 3px;
  }

  body .expense-wrapper .back-mobile icon i {
    position: relative;
    visibility: hidden;
  }

  body .expense-wrapper .back-mobile icon i:after {
    position: absolute;
    content: "arrow_back";
    left: 0;
    top: 0;
    visibility: visible;
  }

  body .expense-wrapper .status-message-mobile {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  body .expense-wrapper .status-message-mobile>div {
    margin: 0 !important;
  }

  body .expense-detail-main-header {
    font-size: 24px !important;
  }

  body .interview-list .no-interview-alert {
    margin: 17px 17px 0 17px !important;
  }

  body .submit-candidate-page .back-link {
    margin-left: 16px;
  }

  body .submit-candidate-page .create-form {
    padding-top: 8px !important;
  }

  body .submit-candidate-page .create-form .candidate-short-info>div {
    flex-direction: column;
  }

  body .submit-candidate-page .create-form .candidate-short-info>div>div:first-child {
    margin-bottom: 24px;
  }

  body .task-details-m .top-section {
    overflow: auto;
  }

  body .other-document-uploader .resume-wrapper .drop-zone {
    margin-right: 12px;
  }

  body .onboarding-list-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  body .onboarding-list-wrapper>h4 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  body .onboarding-list-wrapper .list-header {
    display: none;
  }

  body .onboarding-list-wrapper .onboarding-grid>div {
    position: relative;
    padding-left: 34% !important;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  body .onboarding-list-wrapper .onboarding-grid>div:before {
    position: absolute;
    top: 0;
    left: 3%;
    content: attr(data-value);
    visibility: visible;
  }

  body .today-interview {
    padding: 12px;
  }

  body .breadcrumb-dropdown {
    min-width: 300px;
  }

  body .breadcrumb-dropdown-mobile-only {
    top: auto;
    right: auto;
    bottom: 50px;
    left: 0;
  }

  body .mobile-buttons-approve-reject-wrapper {
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 10;
    padding: 12px 16px;
  }

  .program-details-mobile .pl-24 {
    padding: 0 !important;
  }

  .program-details-mobile .px-24 {
    padding: 0 !important;
  }

  .program-details-mobile .tab-menu ul {
    width: 100%;
    overflow-x: auto;
  }

  .program-details-mobile .tab-menu ul li {
    white-space: nowrap;
  }

  .program-details-mobile .toggle-panel {
    width: 100% !important;
  }

  .program-details-mobile .panel-head h5 {
    padding-right: 20px;
  }

  .configuration .rate-model.d-flex {
    display: unset !important;
  }

  .configuration .rate-model.d-flex .d-flex {
    display: unset !important;
  }

  .configuration .rate-model div .radio {
    margin-inline: 0 !important;
  }

  .configuration .rate-model div .radio input {
    margin: 2px 10px 0 0 !important;
  }

  .individual-invoice icon .mr-8 {
    margin-right: 0 !important;
  }

  .individual-invoice .btn-primary.btn-download-data {
    top: 114px !important;
    right: 15px !important;
  }

  .individual-invoice .btn-primary.btn-download-data span {
    display: none;
  }

  .mobile-justcontent-end {
    justify-content: flex-end;
  }

  .candidate-listing tbody tr vms-row:first-child td span.initials,
  .timesheet-listing tbody tr vms-row:first-child td span.initials {
    display: inline-block !important;
  }

  gridster.mobile gridster-item {
    height: auto !important;
  }

  gridster .list-widget .widget-header {
    position: static !important;
  }

  gridster .widget-content-info .widget-header {
    position: static !important;
  }

  gridster .widget-content-info .widget-body {
    margin-top: 0 !important;
  }

  gridster .full-calendar-wrapper {
    width: 100%;
    overflow: auto;
  }

  gridster .full-calendar {
    position: static !important;
    width: 700px !important;
  }

  app-dashboard-grid {
    height: calc(100vh - 50px) !important;
  }

  .dashboard-header-mobile {
    display: none;
  }

  .dashbard-edit-mobile {
    position: absolute;
    top: 0;
    right: 0;
  }

  .assignment-detail-sidebar .svms-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .int-list .pop-over,
  .additionalattend .pop-over {
    left: auto !important;
    right: 0;
    transform: translateX(0) !important;
  }

  .payment-wrapper {
    height: auto !important;
  }

  .payment-authorisation {
    float: none !important;
    width: auto !important;
    position: relative;
  }

  .payment-authorisation .header {
    width: auto !important;
    flex-wrap: wrap;
  }

  .payment-authorisation .header>div {
    flex-wrap: wrap;
  }

  .payment-authorisation .header>div .vendor-select {
    margin-left: 0 !important;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    width: 100% !important;
  }

  .payment-authorisation .no-data-wrapper {
    margin-top: 0 !important;
    height: auto !important;
  }

  .payment-summary {
    float: none !important;
    width: auto !important;
  }

  .payment-summary .header {
    position: static !important;
    width: auto !important;
  }

  .payment-summary .tab-menu ul li a {
    padding: 16px !important;
  }

  .payment-summary .payment-sidebar {
    height: auto !important;
  }

  .notification-page-wrapper .card .p_10 {
    padding: 12px 16px !important;
  }

  .notification-page-wrapper .card .inputs-section {
    margin: 16px 16px 16px 0;
  }

  .notification-page-wrapper .card .list_left {
    padding-right: 16px;
  }

  .notification-page-wrapper .notification-settings-head i {
    top: 13px !important;
  }

  .notification-page-wrapper .settings-filter {
    flex-wrap: wrap;
    border-top: 1px solid #E8F0F7;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .email-designer-panel .design-editor {
    flex-flow: column-reverse;
  }

  .email-designer-panel .content-tool .tool-item {
    width: 94px !important;
  }

  .email-designer-panel .content-tool .tool-item icon i {
    font-size: 30px !important;
  }

  .email-designer-panel .content-tool .tool-item span {
    font-size: 12px !important;
  }

  .notification-editor-wrapper .preview-zone .preview-body {
    height: auto !important;
  }

  .notification-editor-wrapper .editor-tools .editor-part {
    height: auto !important;
  }

  .back-mobile {
    position: fixed;
    margin-left: 0 !important;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: var(--header-bg-color);
    width: 64px !important;
    height: 50px;
    padding-left: 12px !important;
    border-radius: 0 !important;
  }

  .back-mobile span {
    display: none;
  }

  .back-mobile i {
    color: var(--header-icon-color) !important;
    font-size: 30px !important;
  }

  .back-mobile:hover {
    background-color: var(--header-bg-color) !important;
  }

  .basic-info.candidate-basic-info .search-candidate .ng-option-label {
    flex-wrap: wrap;
  }

  .basic-info.candidate-basic-info .search-candidate .ng-option-label .subtitle-2 {
    margin-right: 8px;
  }

  .basic-info.candidate-basic-info .search-candidate .ng-option-label .do-not-rehire {
    margin-right: 16px;
  }

  .basic-info.candidate-basic-info .search-candidate .ng-option-label .status-ui {
    margin-top: 8px;
  }

  .full-page-width.rate-info-m .info {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .level-listing li .listing-item .level-status {
    width: 100px !important;
  }

  .sub-level-listing.hierarchy-sub-list li .listing-item .level-status {
    width: 100px !important;
  }

  .detailed-page-section .section-title {
    pointer-events: auto;
    border-top: 1px solid var(--color-border-secondary);
    border-bottom: 1px solid transparent;
    padding: 16px;
    width: 100%;
  }

  .detailed-page-section .section-title i {
    background-color: #EDF2FE;
    border-radius: 4px;
  }

  .detailed-page-section .section-title.active {
    background-color: #FAFCFF;
    border-bottom: 1px solid var(--color-border-secondary);
  }

  .detailed-page-section .inactive {
    display: none;
  }

  .detailed-page-section div.active {
    display: block;
    padding: 16px !important;
  }

  .detailed-page-section.bg-gray {
    background-color: transparent !important;
  }

  .value-group .value-head {
    font-size: 14px;
    flex: 1;
  }

  .value-group .value-content {
    font-size: 14px;
    flex: 1;
  }

  body.collapse .page-container .sidebar .switch-menu {
    background-color: var(--header-bg-color) !important;
    color: var(--header-icon-color) !important;
  }

  .switch-new-ui-modal {
    max-width: 95%;
  }

  .switch-new-ui-modal table {
    transform: scale(0.75);
    transform-origin: left center;
  }

  .c-listing-table thead {
    display: none;
    border-top: 1px solid var(--color-border-secondary);
  }

  .c-listing-table thead.mobile-filter-visible {
    display: block;
    position: fixed;
    top: 143px;
    right: 0;
    height: calc(100vh - 143px);
    overflow: auto;
    z-index: 6;
    width: 100%;
  }

  .c-listing-table thead.mobile-filter-visible tr {
    display: block;
  }

  .c-listing-table thead.mobile-filter-visible tr th {
    display: block;
    height: auto;
    padding-right: 24px;
  }

  .c-listing-table thead.mobile-filter-visible tr th .multi-select-filter {
    min-width: inherit;
  }

  .c-listing-table tbody tr {
    display: block;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000014;
    margin-bottom: 12px;
    padding: 8px 8px 0 8px;
  }

  .c-listing-table tbody tr td {
    display: block;
    max-width: inherit !important;
    padding-left: 45% !important;
    padding-right: 0 !important;
    position: relative !important;
    text-align: right;
    border-bottom: 0 !important;
    height: auto !important;
    padding: 8px !important;
    line-height: 24px !important;
  }

  .c-listing-table tbody tr td:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    content: attr(title);
    display: block;
    visibility: visible;
  }

  .c-listing-table tbody tr td:first-child {
    border-right: 0 !important;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
  }

  .c-listing-table tbody tr td:first-child a {
    font-weight: 600;
  }

  .c-listing-table tbody tr td:first-child:before {
    display: none;
  }

  .c-listing-footer .c-pagination {
    margin-left: 0 !important;
    margin-top: 12px !important;
  }

  .c-listing-header,
  .c-listing-footer {
    left: 0 !important;
    width: 100% !important;
  }

  .c-filter-button {
    display: none;
  }

  .c-filter-button.show-filter {
    display: block;
  }

  .clear-all-filter i {
    font-size: 24px !important;
  }

  .job-estimate .work-duration-box .number-dropdown,
  .job-estimate .work-duration-box .work-duration {
    width: auto !important;
  }

  .workflow-page .workflow-accordion-title {
    padding: 8px;
  }

  .workflow-page .workflow-accordion-title .item-label {
    padding-right: 8px;
  }

  .workflow-page .workflow-accordion-content table tr {
    display: block;
  }

  .workflow-page .workflow-accordion-content table tr td {
    display: block;
    height: auto;
    padding: 0;
  }

  .workflow-page .workflow-accordion-content table tr td .users,
  .workflow-page .workflow-accordion-content table tr td .approval-ui {
    padding: 8px !important;
  }

  .workflow-page .workflow-accordion-content table tr td:first-child {
    border-bottom: 0;
  }

  .workflow-page .workflow-accordion-content table tr td:nth-child(2) {
    border-top: 0;
  }

  .workflow-page .workflow-accordion-content table tr td .manager-images-list-multi-approvl {
    min-height: 22px;
  }
}

.ant-table-wrapper .ant-table-container
{
  margin: 0px 24px !important;
}

.ant-btn-circle
{
  height: 28px !important;
  width: 28px !important;
  min-width: 28px !important;
}
.ant-layout-header .ant-btn-circle {
  border: 0;
  box-shadow: inherit;
}

.form-page-wrapper {
  border-top: 1px solid #f0f0f0;
}

.form-page-wrapper form {
  max-width: 800px !important;
  width: 92% !important;
}

.form-page-wrapper form .field {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.form-page-wrapper form input:not([type=checkbox], [type=radio]),
.form-page-wrapper form textarea,
.form-page-wrapper form .ant-select .ant-select-selector {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-page-wrapper form .ant-select .ant-select-selector {
  height: 44px !important;
}

.form-page-wrapper form .ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 24px !important;
}

.form-page-wrapper form .ant-picker {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media screen and (max-width: 1600px) {

  .analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li,
  .analytics-wrapper ul.report-template-list li {
    max-width: 281px;
    min-width: 281px;
  }

  .analytics-wrapper .folder-list ul li {
    width: 166px;
  }
}

@media screen and (max-width: 1400px) {

  .analytics-wrapper .main-wrapper .analytics-section-content .analytics-template-list .dashboard-template-list li,
  .analytics-wrapper ul.report-template-list li {

  }

  .analytics-wrapper .folder-list ul li {
    width: 166px;
  }
}



/*FORM CHANGES*/

.cform form > div,  .cform form > .field, .cform form > div > .field, .cform form > div > label, .cform form > .field > label, .cform form > div > .field > label
{
  margin: 0px !important;
}
.cform form > div, .cform form > .field, .cform form > div > .field
{
  margin-bottom: 14px !important;
}
.cform form > div > label, .cform form > .field > label, .cform form > div > .field > label
{
  font-size: 13px !important;
}
.cform form > div > span, .cform form > .field > span, .cform form > div > .field > span
{
  margin-left: 0px !important;
  font-size: 13px;
  font-weight: 500;
}

.ant-input
{
  padding: 8px 11px !important;
}
.ant-picker
{
  padding: 11px 11px 7px !important;
}
.ant-select-multiple .ant-select-selector
{
  padding: 6px 4px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
{
  height: 42px;
    padding: 5px 11px;
}

.report-template-list .list-item
{
  cursor: pointer !important;
  position: relative;
}
.report-template-list .card-selected .list-content
{
  border-color: #4c7ff1 !important;
  box-shadow: 0px 0px 0px 3px rgb(76 127 241 / 34%);
}
.report-template-list .card-selected::after
{
  font-family: "Material Icons";
  content: "check_circle";
  position: absolute;
  z-index: 999;
  top: -7px;
  right: 15px;
  background: #fff;
  font-size: 26px;
  color: #4c7ff1;
  border-radius: 50%;
  line-height: 21px;
  width: 21px;
  height: 21px;
}
.analytics-wrapper .landing-page-wrapper .landing-page .landing-page-item figure svg
{
  display: none;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item
{
  float: left;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item figure img
{
  display: none;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item figure svg
{
  display: block;
  margin: auto;
  width: 70px;
}
.analytics-wrapper .landing-page-wrapper li
{
  width: 50%;
  float: left;
}
.analytics-wrapper .landing-page-wrapper li:last-child
{
  float: right;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item figure
{
  margin-right: 0px !important;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item
{
  width: 95%;
  display: block !important;
  text-align: center;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item .landing-list-content
{
  display: block !important;
}

.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item .landing-list-caption
{
  min-height: 160px;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item .landing-list-caption p
{
  color: #3c3c3c;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item a
{
  color: #4c7ff1;
  font-weight: 600;
  width: 100px;
  display: block;
  margin: auto;
}
.analytics-wrapper .landing-page-wrapper .landing-page.theme2 .landing-page-item a i
{
  margin: 0px 0px 0px 0px !important;
  float: right;
}

.ant-table-wrapper .ant-table-container
{
  margin: 0px 24px !important;
}


.c-btn-primary, .c-btn-outline, .ant-btn.ant-dropdown-trigger.c-btn-outline, .ant-btn.ant-dropdown-trigger.c-btn-primary
{
  border-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  font-size: 14px !important;
  height: 38px !important;
}
.ant-btn.ant-dropdown-trigger.c-btn-outline
{
  border-color: var(--color-assets-primary) !important;
  color: var(--color-assets-primary) !important;

}
.ant-btn.ant-dropdown-trigger.c-btn-outline span
{
  color: var(--color-assets-primary) !important;
}
.ant-btn.ant-dropdown-trigger.c-btn-outline:hover, .ant-btn.ant-dropdown-trigger.c-btn-outline span
{
  border-color: var(--color-assets-primary) !important;
  color: var(--color-assets-primary) !important;

}
.c-btn-primary:hover
{
  background-color: var(--color-assets-primary-shade);
  color: #fff !important;
  border-color: var(--color-assets-primary-shade) !important;
}

.ant-dropdown .ant-dropdown-menu 
{
  min-width: 200px !important;
}
.ant-dropdown .ant-dropdown-menu li
{
  padding: 10px 12px !important;
}
.ant-dropdown .ant-dropdown-menu li.ant-dropdown-menu-item-divider
{
  padding: 0px !important;
}
.search-head {
  .form-control{
    height: 33px;
    width:270px
  }
  .search-icon{
    position: absolute;
    top: 4px;
    right: 5px;
    background: #5eea65;
    color: #fff;
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 17px;
  }
}
.cloneForm{
  display: flex;
  flex-direction: column;
  gap:1rem;
}