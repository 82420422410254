@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

* {
    font-family: 'Inter', sans-serif;
}
h3 {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600; 
}

.ant-layout {
    max-width: 100%;
}

.ant-layout button {
    font-family: 'Inter', sans-serif !important;
}

.ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0 !important;
}

.ant-layout .ant-table-body {
    max-height: calc(100vh - 280px) !important;
    overflow: auto;
}

.listing-page .ant-tabs-content-holder {
    overflow: inherit !important;
}

.ant-tabs-nav-wrap .ant-tabs-tab {
 padding-top: 0 !important;
}

.ant-tabs-nav-wrap .ant-tabs-tab-btn {
 font-family: 'Inter', sans-serif;
 font-size: 13px;
 color: #0F2E4E;
 font-weight: 400;
}

.ant-tabs-top >.ant-tabs-nav::before {
    bottom: -1px !important;
}

.ant-tabs .ant-tabs-nav {
    padding: 0px !important;
    margin: 0px 24px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    color: #1C60D0 !important;
}

.ant-table-wrapper .ant-table-container table {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
}

.ant-table-wrapper .ant-table-container table > thead > tr th {
    background-color: #FAFCFF;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    height: 48px;
    font-family: 'Inter', sans-serif;
}

.ant-table-wrapper .ant-table-container table > thead > tr th:first-child {
    padding-left: 24px !important;
    border-radius: 0 !important;
}

.ant-table-wrapper .ant-table-container table > thead > tr td {
    height: 48px;
}

.ant-table-wrapper .ant-table-container table tbody tr td {
    height: 48px;
    font-size: 13px;
    border-bottom: 1px solid rgba(235, 240, 245, 0.44);
    font-family: 'Inter', sans-serif;
}

.ant-table-wrapper .ant-table-container table tbody tr td  a {
    font-weight: 500;
    color: #0044C5;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
}

.ant-table-wrapper .ant-table-container table tbody tr.ant-table-row:hover > td{
 background-color: #fafcff;
}

.ant-table-tbody > tr > td.ant-table-cell:first-child {
    padding-left: 24px !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
    border-top: 1px solid #E3E9F0;
    font-size: 13px;
    padding: 16px !important;
    margin: 0;
}

.ant-pagination .ant-pagination-item {
    margin: 0 4px !important;
}

.ant-pagination .ant-pagination-item a {
    font-size: 14px;
    color: #0F2E4E;
}

.ant-pagination .ant-pagination-item-active {
    background-color: #1C60D0;
    border-color: #1C60D0;
}
.ant-pagination .ant-pagination-item-active a {
    color: #ffffff;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #f2f5f7;
}

.ant-btn.ant-dropdown-trigger {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}